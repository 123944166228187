import React, { FC, memo, useState, useRef, useEffect, useCallback, SyntheticEvent } from 'react';
import { makeStyles } from 'tss-react/mui';
import classNames from 'classnames';

import Modal from '@mui/material/Modal';
import Popper from '@mui/material/Popper';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ArrowIcon from '@mui/icons-material/KeyboardArrowRight';

import QTypography from 'components/MUIWrappers/QTypography';

import { styles } from './styles';

interface MenuOption {
  id?: string;
  label: string;
  value?: string;
  subtext?: string;
  divider?: boolean;
  menuDepth: number;
  menuIndex: number;
  isSubheader?: boolean;
  onSelect?: () => void;
  subMenu?: MenuOption[];
  backgroundColor?: string;
  style?: React.CSSProperties;
  startIcon?: React.ReactNode;
}

interface NestedQMenuProps {
  open: boolean;
  width?: number;
  onClose?: () => void;
  options: MenuOption[];
  header?: React.ReactNode;
  selectedOption?: MenuOption;
  anchorEl?: HTMLElement | null;
  onChange?: (value: string) => void;
  currentHover?: (option: MenuOption | null) => void;
}

const useStyles = makeStyles()(styles as Record<string, any>);

const NestedQMenu: FC<NestedQMenuProps> = ({
  open,
  width,
  header,
  options,
  onClose,
  anchorEl,
  onChange,
  currentHover,
  selectedOption,
}) => {

  const { classes, theme } = useStyles();

  const [path, setPath] = useState<MenuOption[]>([]);
  const [anchors, setAnchors] = useState<(HTMLElement | null)[]>([]);
  const [subNodes, setSubNodes] = useState<MenuOption[][]>([]);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [headerHeight, setHeaderHeight] = useState(0);

  const scrollRefInternal = useRef<(HTMLDivElement | null)[]>([null]);
  const trackedOptions = useRef<MenuOption[][]>([]);

  const clearAllTracking = () => {
    if (path.length || anchors.length || subNodes.length) {
      setPath([]);
      setAnchors([]);
      setSubNodes([]);
    }
  };

  const closeMenus = (event: SyntheticEvent | undefined = undefined) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (onClose) {
      onClose();
    }
    clearAllTracking();
    if (currentHover) {
      currentHover(null);
    }
  };

  const findOption = (optionsPassed, option, pathPassed: MenuOption[] = []) => {
    let pathFound;
    optionsPassed.forEach((optionItem) => {
      if (optionItem.value === option.value) {
        pathFound = [...pathPassed, optionItem];
      } else if (optionItem.subMenu && optionItem.subMenu.length) {
        pathFound = findOption(optionItem.subMenu, option, [...pathPassed, optionItem]);
      }
    });
    return pathFound;
  };

  const scrollRef = () => scrollRefInternal.current[path.length];

  const initMenu = (firstOrLast, offset = 0) => {
    if (!open) return;
    let pathGenerated;
    if (selectedOption) {
      pathGenerated = findOption(trackedOptions.current[0], selectedOption);
    } else if (firstOrLast === 'first') {
      const first = trackedOptions.current[0][0 + offset];
      if (first) {
        pathGenerated = [first];
        setTimeout(() => {
          const scroll = scrollRef();
          if (scroll && scroll.scrollTop) {
            scroll.scrollTop = 0;
          }
        }, 0);
      }
    } else if (firstOrLast === 'last') {
      const last = trackedOptions.current[0][trackedOptions.current[0].length - 1 + offset];
      pathGenerated = last ? [last] : undefined;
    }
    if (!pathGenerated || !pathGenerated.length) return;
    const option = pathGenerated[pathGenerated.length - 1];
    setPath(pathGenerated);
    setSubNodes(option && option.subMenu ? [option.subMenu] : []);
    setAnchors(option && option.subMenu ? [document.getElementById(`${option.menuIndex}${option.label}${option.menuDepth}`)] : []);
  };

  const pathContainsOption = (option) => path.some((crumb) => crumb.label === option.label
    && option.menuDepth === crumb.menuDepth && crumb.menuIndex === option.menuIndex);

  const handleOptionHover = (event, option) => {
    setLastScrollTop(scrollRef()?.scrollTop || 0);
    const { menuDepth } = option;
    let clonedPath = path;
    let clonedAnchors = anchors;
    let clonedSubNodes = subNodes;
    if (menuDepth <= clonedPath.length) { // we need to backtrack
      clonedPath = clonedPath.slice(0, menuDepth).concat([option]);  // always make the path end at the current hovered on
      clonedAnchors = clonedAnchors.slice(0, menuDepth);
      clonedSubNodes = subNodes.slice(0, menuDepth);
      if (option.subMenu) {
        clonedAnchors = clonedAnchors.concat([event.currentTarget]);
        clonedSubNodes = clonedSubNodes.concat([option.subMenu]);
      }
    } else if (option.subMenu) {  // we need to add a new level
      clonedPath = clonedPath.concat([option]);
      clonedAnchors = option.subMenu ? clonedAnchors.concat([event.currentTarget]) : clonedAnchors;
      clonedSubNodes = option.subMenu ? clonedSubNodes.concat([option.subMenu]) : clonedSubNodes;
    }
    setPath(clonedPath);
    setAnchors(clonedAnchors);
    setSubNodes(clonedSubNodes);
  };

  const handleOptionClick = (_event, option) => {
    if (onChange && option.value) {
      onChange(option.value);
    }
    if (currentHover) {
      currentHover(null);
    }
  };

  const composeItems = (optionsPassed, depth = 0) => {
    if (!optionsPassed) return [];
    if (trackedOptions.current.length < depth + 1) trackedOptions.current.push([]);
    else trackedOptions.current[depth] = [];
    const items: any = [];
    optionsPassed.forEach((option, index) => {
      if (!option) {
        return;
      }
      if (option.isSubheader) {
        items.push(
          <ListItem
            key={`subtitle-${option.label}`}
            style={{ backgroundColor: option.backgroundColor || '' }}
            className={classes.subheader}
          >
            {
              option.startIcon &&
                <ListItemIcon className={classes.startIcon}>
                  {option.startIcon}
                </ListItemIcon>
            }
            <QTypography variant="caption">
              {option.label}
            </QTypography>
          </ListItem>,
        );
      } else if (option.isGroupList) {
        // TODO: add group lists into the menu
      } else if (!option.nop) {  // regular items and ones with submenus
        const trackedOption = { ...option, menuDepth: depth, menuIndex: trackedOptions.current[depth].length };
        trackedOptions.current[depth].push(trackedOption);
        items.push(
          <ListItem
            id={option.id || `${trackedOptions.current[depth].length - 1}${option.label}${depth}`}
            // eslint-disable-next-line react/no-array-index-key
            key={`qnested-menuitem-${index}${option.label}${depth}`}
            onMouseEnter={(event) => handleOptionHover(event, trackedOption)}
            onClick={option.onSelect ? option.onSelect : (event) => handleOptionClick(event, trackedOption)}
            className={classes.item}
            style={{
              // @ts-expect-error: Property 'level1' does not exist on type 'Color'.
              backgroundColor: !pathContainsOption(trackedOption) ? option.backgroundColor || '' : theme.palette.grey.level1,
              ...(option.style ? option.style : {}),
            }}
          >
            {
              option.customRender ?
                option.customRender
                :
                <div className={classes.flexRow}>
                  {
                    option.startIcon &&
                      <ListItemIcon classes={{ root: classes.startIcon }}>
                        {option.startIcon}
                      </ListItemIcon>
                  }
                  <div>
                    <QTypography>
                      {option.label}
                    </QTypography>
                    {
                      option.subtext &&
                        <QTypography variant="caption">
                          {option.subtext}
                        </QTypography>
                    }
                  </div>
                </div>
            }
            {
              option.subMenu &&
                <IconButton
                  // eslint-disable-next-line @typescript-eslint/no-use-before-define
                  onClick={handleArrowClick}
                  className={classes.rightArrowButton}
                  size="large"
                >
                  <ArrowIcon className={classes.rightArrowStyles} />
                </IconButton>
            }
          </ListItem>,
        );
      }
      if (option.divider) {
        items.push(
          <Divider
            // eslint-disable-next-line react/no-array-index-key
            key={`${index}divider${depth}`}
            className={classes.divider}
          />,
        );
      }
    });
    return items;
  };

  useEffect(() => {
    initMenu('first');
    composeItems(options, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  const getActiveDepth = () => path.length - 1;

  const handlePrimaryMenuScroll = () => {
    if (path.length > 0 && Math.abs(lastScrollTop - (scrollRef()?.scrollTop || 0)) > 50) {
      clearAllTracking();
      setLastScrollTop(scrollRef()?.scrollTop || 0);
    }
  };

  const getNextOption = (currentOption) => {
    if (currentOption.menuIndex >= trackedOptions.current[currentOption.menuDepth].length - 1) {
      if (scrollRef()) {
        scrollRef()!.scrollTop = 0;
      }
      return trackedOptions.current[currentOption.menuDepth][0]; // wraps back around to the beginning
    }
    return trackedOptions.current[currentOption.menuDepth][currentOption.menuIndex + 1];
  };

  const getPrevOption = (currentOption) => {
    if (currentOption.menuIndex <= 0) {
      if (scrollRef()) {
        scrollRef()!.scrollTop = scrollRef()!.scrollHeight;
      }
      return trackedOptions.current[currentOption.menuDepth][trackedOptions.current[currentOption.menuDepth].length - 1]; // wrap around to end
    }
    return trackedOptions.current[currentOption.menuDepth][currentOption.menuIndex - 1];
  };

  const handleKeyPress = useCallback((event) => {
    if (options.length === 0 || !open) {
      return;
    }
    // ################################## -- Enter -- ################################## //
    if (event.key === 'Enter') {
      const currentBoi = path[path.length - 1];
      if (!currentBoi) {
        return;
      }
      event.stopPropagation();
      event.preventDefault();
      if (currentBoi.onSelect) {
        currentBoi.onSelect();
      } else {
        handleOptionClick(event, path[path.length - 1]);
      }
    } else if (event.key === 'Tab' || event.key === 'Escape') {
    // ################################## -- Tab -- ################################## //
      closeMenus();
    } else if (['ArrowUp', 'ArrowDown', 'ArrowRight', 'ArrowLeft'].includes(event.key)) {
      if (path.length === 0) {
        if (event.key === 'ArrowDown') {
          initMenu('first');
        } else if (event.key === 'ArrowUp') {
          if (scrollRef()) {
            scrollRef()!.scrollTop = scrollRef()!.scrollHeight;
          }
          initMenu('last');
        }
        event.stopPropagation();
        event.preventDefault();
        return;
      }
      if ((event.key === 'ArrowRight' && path[path.length - 1].subMenu)
        || (event.key === 'ArrowLeft' && path.length > 1)) { // if it's moving the menu don't move the cursor
      }

      let clonedPath = path;
      let clonedAnchors = anchors;
      let clonedSubNodes = subNodes;

      const currentOption = clonedPath[clonedPath.length - 1];
      // ################################## -- ArrowRight -- ################################## //
      if (event.key === 'ArrowRight' && clonedPath[clonedPath.length - 1].subMenu) {
        const trackedOptionsClone = trackedOptions.current[getActiveDepth() + 1]?.[0];
        if (trackedOptionsClone) {
          clonedPath = clonedPath.concat([trackedOptionsClone]); // add the first of the subMenu to the path
        }
        if (clonedPath && clonedPath[clonedPath.length - 1].subMenu) {  // if moving right leads to another subMenu
          const { menuIndex, menuDepth, label } = clonedPath[clonedPath.length - 1];
          clonedAnchors = clonedAnchors.concat([document.getElementById(`${menuIndex}${label}${menuDepth}`)]);
          // @ts-expect-error: No overload matches this call.
          clonedSubNodes = clonedSubNodes.concat([clonedPath[clonedPath.length - 1].subMenu]);
        }
      } else if (event.key === 'ArrowDown') {
        // ################################## -- ArrowDown -- ################################## //
        const { menuIndex, menuDepth, label } = clonedPath[clonedPath.length - 1];
        const child = document.getElementById(`${menuIndex}${label}${menuDepth}`);
        const scroller = scrollRef();
        if (scroller && child && child.offsetTop + (2 * child.offsetHeight) >= scroller.offsetHeight + scroller.scrollTop) {
          scroller.scrollTop += child.offsetHeight;
        }
        handlePrimaryMenuScroll();
        const nextOption: MenuOption = getNextOption(clonedPath[clonedPath.length - 1]);
        clonedPath = clonedPath.slice(0, clonedPath.length - 1).concat([nextOption]);
        if (currentOption.subMenu) {
          clonedAnchors = clonedAnchors.slice(0, clonedAnchors.length - 1);
          clonedSubNodes = clonedSubNodes.slice(0, clonedSubNodes.length - 1);
        }
        if (nextOption.subMenu) {
          const nextId = `${nextOption.menuIndex}${nextOption.label}${nextOption.menuDepth}`;
          clonedAnchors = clonedAnchors.concat([document.getElementById(nextId)]);
          clonedSubNodes = clonedSubNodes.concat([nextOption.subMenu]);
        }
        event.preventDefault();
        event.stopPropagation();
      } else if (event.key === 'ArrowUp') {
        // ################################## -- ArrowUp -- ################################## //
        const { menuDepth, menuIndex, label } = clonedPath[clonedPath.length - 1];
        const child = document.getElementById(`${menuIndex}${label}${menuDepth}`);
        if (scrollRef() && child && child.offsetTop - child.offsetHeight <= scrollRef()!.scrollTop) {
          scrollRef()!.scrollTop -= child.offsetHeight;
        }
        handlePrimaryMenuScroll();
        const prevOption: MenuOption = getPrevOption(clonedPath[clonedPath.length - 1]);
        clonedPath = clonedPath.slice(0, clonedPath.length - 1).concat([prevOption]);

        if (currentOption.subMenu) {
          clonedAnchors = clonedAnchors.slice(0, clonedAnchors.length - 1);
          clonedSubNodes = clonedSubNodes.slice(0, clonedSubNodes.length - 1);
        }
        if (prevOption && prevOption.subMenu) {
          const prevId = `${prevOption.menuIndex}${prevOption.label}${prevOption.menuDepth}`;
          clonedAnchors = clonedAnchors.concat([document.getElementById(prevId)]);
          clonedSubNodes = clonedSubNodes.concat([prevOption.subMenu]);
        }
        event.preventDefault();
        event.stopPropagation();
      } else if (event.key === 'ArrowLeft') {
        // ################################## -- ArrowLeft -- ################################## //
        clonedPath = clonedPath.length > 1 ? clonedPath.slice(0, clonedPath.length - 1) : clonedPath;
        if (clonedPath.length < clonedAnchors.length) {
          clonedAnchors = clonedAnchors.slice(0, clonedAnchors.length - 1);
          clonedSubNodes = clonedSubNodes.slice(0, clonedSubNodes.length - 1);
        }
      }
      setPath(clonedPath);
      setAnchors(clonedAnchors);
      setSubNodes(clonedSubNodes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path, open, options, anchors, subNodes]);

  const handleArrowClick = (event) => {
    handleKeyPress({
      key: 'ArrowRight',
      stopPropagation: () => 0,
      preventDefault: () => 0,
    });
    event.stopPropagation();
  };

  useEffect(() => {
    if (!open) {
      document.removeEventListener('keydown', handleKeyPress, { capture: true });
      clearAllTracking();
    } else {
      document.addEventListener('keydown', handleKeyPress, { capture: true });
    }
    return () => {
      document.removeEventListener('keydown', handleKeyPress, { capture: true });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, handleKeyPress]);

  const getAnchor = () => anchorEl || null;

  if (!anchorEl || !options) return <div />;

  const spaceBelow = window.innerHeight - anchorEl.getBoundingClientRect().top - 80;
  const spaceAbove = anchorEl.getBoundingClientRect().top - 80;
  const rootPlacement = spaceAbove > spaceBelow ? 'top' : 'bottom';
  const rootHeight = (spaceAbove > spaceBelow ? spaceAbove : spaceBelow) - headerHeight;  // 80 for spacing

  const menuItems = composeItems(options, 0);

  // callback to let parent know if the user is navigating through the menu
  if (currentHover) {
    if (path.length > 0 && open) {
      currentHover(path[path.length - 1]);
    } else {
      currentHover(null);
    }
  }

  const filteredAnchors = anchors.filter((anchorItem) => anchorItem);

  const setHeaderRef = (ref) => {
    if (ref) {
      const headerHeightGenerated = ref.getBoundingClientRect().bottom - ref.getBoundingClientRect().top;
      setHeaderHeight(headerHeightGenerated);
    }
  };

  const setScrollRef = (node) => {
    const depth = path.length;
    scrollRefInternal.current[depth] = node;
  };

  return (
    <Modal
      className={classes.root}
      open={open}
      // @ts-expect-error: 'onBackdropClick' is deprecated.
      onBackdropClick={closeMenus}
      BackdropProps={{ 
        style: { 
          backgroundColor: 'transparent',
        },
      }}
    >
      <>
        <Popper
          id="picker-boi"
          key="picker-boi"
          open
          anchorEl={getAnchor()}
          placement={rootPlacement}
          className={menuItems.length > 0 ? classNames(classes.popper, classes.rootPopper) : ''}
          style={{
            width: width || anchorEl.offsetWidth,
          }}
          modifiers={[{
            name: 'flip',
            options: {
              fallbackPlacements: ['right', 'left', 'bottom', 'top'],
            },
          }]}
        >
          <div
            key="headerDiv=="
            ref={setHeaderRef}
          >
            { header }
          </div>
          <div
            id="top-wrapper-qnested-menu"
            className={classes.primaryMenu}
            style={{ 
              maxHeight: rootHeight - 40,
            }}
            ref={(node) => {
              if (path.length <= 1) {
                setScrollRef(node);
              }
            }}
          >
            {menuItems.map((menuItem) => menuItem)}
          </div>
        </Popper>
        {open && filteredAnchors.length > 0 && filteredAnchors.map((anchor, index) => {
          const label = path[index]?.label;
          const popperId = `qnested-popper-menu-item-${label}`;
          const id = `qnested-menu-item-${label}`;
          return (
            <Popper
              id={popperId}
              key={popperId}
              open
              anchorEl={anchor}
              className={classes.popper}
              placement="right"
            >
              <div
                id={id}
                className={classes.secondaryMenu}
                ref={(node) => {
                  if (path.length > 1 && index === anchors.length - 1) {
                    setScrollRef(node);
                  }
                }}
              >
                {composeItems(subNodes[index], index + 1).map((nodeItem) => nodeItem)}
              </div>
            </Popper>
          );
        })}
      </>
    </Modal>
  );
};

export default memo(NestedQMenu);
