import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';

import Skeleton from '@mui/material/Skeleton';
import { useTheme } from '@emotion/react';

import { accountsSelectors } from 'companion-app-components/flux/accounts';
import { tracker } from 'companion-app-components/utils/core';
import RootState from 'companion-app-components/utils/redux-store/rootState';

import zeroStateIcon from 'assets/zero-state-images/pie-chart.svg';
import ZeroStateView from 'components/ZeroStateView';
import MonthlyBarChart from 'containers/Reports/components/monthlyBarChart';
import { getSpendingForPeriods, getIncomeForPeriods } from 'data/otReports/selectors';
import { getPredefinedRange } from 'utils/date/utils';
import { computeViewState } from '../helpers';
import { styles } from './style';

const useStyles = makeStyles()(styles as Record<string, any>);

interface MonthlyDataProps {
  overrideMonthlyData?: Record<string, any>;
  toolTipLabel: string;
  onBarGraphClick: (index: number) => void;
  isIncome: boolean;
  excludeCategoryInTooltip?: boolean;
  isDashboard?: boolean;
}

const MonthlyData: React.FC<MonthlyDataProps> = ({
  overrideMonthlyData,
  toolTipLabel,
  onBarGraphClick,
  isIncome,
  excludeCategoryInTooltip,
  isDashboard,
}) => {
  /// State and Hooks
  const { classes } = useStyles();
  const theme: Record<string, any> = useTheme();
  const navigate = useNavigate();
  const [selectedMonthIndex, setSelectedMonthIndex] = useState<number | null>(null);
  const dateInterval = useMemo(() => getPredefinedRange({ value: '6_MONTHS' }), []);

  // Selectors
  const hasReportableAccounts = useSelector((state: RootState) => accountsSelectors.getHasReportableAccounts(state));
  const monthlyData = useSelector((state: RootState) =>
    isIncome ? getIncomeForPeriods(state, { dateInterval }) : getSpendingForPeriods(state, { dateInterval }));

  const colors = useMemo(
    () => {
      const componentTheme = isIncome ? theme?.components?.income : theme?.components?.spendingOverTime;
      return {
        positiveDefault: componentTheme?.positiveDefault,
        positiveDimmed: componentTheme?.positiveDimmed,
        negativeDefault: componentTheme?.negativeDefault,
        negativeDimmed: componentTheme?.negativeDimmed,
        tooltipBullet: componentTheme?.positiveDefault,
      };
    },
    [isIncome, theme],
  );

  const navigateToReportsWithMonth = useCallback(
    (index: number) => {
      navigate(`/reports/${isIncome ? 'income' : 'spending'}/by-none/over-time${index != null ? `?selectMonth=${index}` : ''}`);
    },
    [navigate, isIncome],
  );

  const onMonthlyBarGraphClick = (indexOfClickedMonth) => {
    if (onBarGraphClick) {
      return onBarGraphClick(indexOfClickedMonth);
    }
    setSelectedMonthIndex(indexOfClickedMonth);
    if (indexOfClickedMonth === 0 || indexOfClickedMonth) {
      tracker.track(tracker.events.barSelected, { chart: isIncome ? 'income' : 'spending' });
      setTimeout(() => {
        navigateToReportsWithMonth(indexOfClickedMonth);
      }, 1000);
    }
    return false;
  };

  const navigateToReports = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      if (!onBarGraphClick) {
        navigate(`/reports/${isIncome ? 'income' : 'spending'}/by-none/over-time`);
      }
    },
    [onBarGraphClick, isIncome, navigate],
  );

  const dataIsNull = () => (overrideMonthlyData || monthlyData) === null;
  const dataIsEmpty = () => {
    const localmonthlyData = overrideMonthlyData || monthlyData;
    return !dataIsNull() && localmonthlyData.every((data) => data.txns.size === 0);
  };

  const view = computeViewState(dataIsNull, dataIsEmpty, hasReportableAccounts);

  return (
    <div className={classes.root} role="presentation">
      {view === 'loading' && (
        <div className={classes.skeletonBarWrapper}>
          <Skeleton variant="rectangular" width={50} height="40%" className={classes.skeletonBarOne} />
          <Skeleton variant="rectangular" width={50} height="50%" className={classes.skeletonBar} />
          <Skeleton variant="rectangular" width={50} height="40%" className={classes.skeletonBar} />
          <Skeleton variant="rectangular" width={50} height="40%" className={classes.skeletonBar} />
          <Skeleton variant="rectangular" width={50} height="50%" className={classes.skeletonBar} />
          <Skeleton variant="rectangular" width={50} height="60%" className={classes.skeletonBar} />
        </div>
      )}
      {view === 'zero' && (
        <ZeroStateView
          icon={zeroStateIcon}
          onClick={navigateToReports}
          secondary={[
            'Sync your financial institutions to',
            <br key="br" />,
            `see your ${isIncome ? 'income' : 'spending'} over time.`,
          ]}
        />
      )}
      {view === 'empty' && (
        <ZeroStateView
          icon={zeroStateIcon}
          onClick={navigateToReports}
          primary={`No ${isIncome ? 'Income' : 'Spending'}`}
          secondary={`It looks like you haven't ${isIncome ? 'received' : 'spent'} any money in the last six months.`}
        />
      )}
      {view === 'main' && (
        <MonthlyBarChart
          idPrefix={isIncome ? 'income' : 'spending'}
          negator={isIncome ? 1 : -1}
          selectedMonthIndex={selectedMonthIndex}
          monthlyData={overrideMonthlyData || monthlyData}
          sequencedInterpolation
          excludeCategoryInTooltip={excludeCategoryInTooltip}
          onBarGraphClick={onMonthlyBarGraphClick}
          navigateToReports={navigateToReports}
          tooltipLabel={toolTipLabel || (isIncome ? 'Income' : 'Spending')}
          getBarAmount={(amount) => (isIncome ? amount : -amount)}
          colors={colors}
          defaultStateTransitionDuration={600}
          hiddenStateTransitionDuration={400}
          isDashboard={isDashboard}
        />
      )}
    </div>
  );
};

export default MonthlyData;
