import React, { FC, useState, useEffect, useRef } from 'react';
import { withStyles } from 'tss-react/mui';
import classNames from 'classnames';

import SearchIcon from '@mui/icons-material/Search';
import CancelIcon from '@mui/icons-material/Cancel';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Input from '@mui/material/Input';
import styles from './style';


interface SearchBoxProps {
  classes: Record<string, any>;
  onSearch?: (text: string | null) => void;
  autoSearch?: boolean;
  initialValue?: string;
  collapsable?: boolean;
  placeholder?: string;
  onHelp?: (isHelp: boolean) => void;
  showUnderline?: boolean;
  autoFocus?: boolean;
}

const SearchBox: FC<SearchBoxProps> = ({
  classes,
  onSearch,
  autoSearch = false,
  initialValue = '',
  collapsable = false,
  placeholder = 'Search available transactions',
  onHelp,
  showUnderline = false,
  autoFocus = false,
}) => {

  const [isClearSearch, setIsClearSearch] = useState<boolean>(false);
  const [searchState, setSearchState] = useState({
    smartSearchText: initialValue,
    activeSearch: false,
    searchOpen: Boolean(initialValue) || !collapsable,
  });

  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (isClearSearch) { return; }
    if (initialValue && !searchState.smartSearchText) {
      setSearchState((prevState) => ({
        ...prevState,
        smartSearchText: '',
      }));
    }
    if (initialValue && initialValue !== searchState.smartSearchText) {
      const newText = initialValue || '';
      setSearchState((prevState) => ({
        ...prevState,
        smartSearchText: newText,
        searchOpen: newText !== '',
        activeSearch: newText !== '',
      }));
    }
  }, [initialValue, searchState.smartSearchText, isClearSearch]);

  const resetFocus = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const clearSearch = () => {
    setIsClearSearch(true);
    setSearchState({
      smartSearchText: '',
      searchOpen: !collapsable,
      activeSearch: false,
    });
    if (onSearch) {
      onSearch('');
    }
    resetFocus();
    if (onHelp) {
      onHelp(false);
    }
  };

  const doSearch = (searchString: string | null = null) => {
    const filter = searchString || searchState.smartSearchText?.trim();
    if (filter?.length > 0) {
      if (onSearch) {
        onSearch(filter);
      }
      resetFocus();
      setSearchState((prevState) => ({
        ...prevState,
        activeSearch: true,
      }));
    } else {
      clearSearch();
    }
  };

  const iconDoSearch = () => {
    if (searchState.searchOpen) {
      doSearch();
    } else {
      setSearchState((prevState) => ({
        ...prevState,
        searchOpen: true,
      }));
      setTimeout(resetFocus, 100);
    }
  };

  const keyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      doSearch();
      resetFocus();
    }
    if (e.key === 'Escape') {
      clearSearch();
      resetFocus();
      setSearchState((prevState) => ({
        ...prevState,
        searchOpen: !collapsable,
      }));
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setSearchState((prevState) => ({
      ...prevState,
      smartSearchText: newValue,
    }));

    if (autoSearch) {
      if (newValue.length <= 0) {
        clearSearch();
      } else if (newValue.length >= 2 || searchState.smartSearchText?.length > 0) {
        doSearch(newValue.trim());
      } else if (onSearch) {
        onSearch(null);
      }
    } else if (e.target.value.trim().length === 0 && searchState.activeSearch) {
      clearSearch();
    }
  };

  const showCancel = searchState.smartSearchText && searchState.smartSearchText?.length > 0;

  let cancelAdorn: any = null;
  if (showCancel) {
    cancelAdorn =
      <div className={classes.iconHolder}>
        <CancelIcon
          aria-label="Cancel Search"
          id="cancel-search"
          className={classNames(classes.buttonFontSize, classes.cancelIcon, collapsable ? classes.searchIconButton : classes.searchIcon)}
          onClick={clearSearch}
          tabIndex={0}
        />
      </div>;
  } else if (onHelp && searchState.searchOpen) {
    cancelAdorn =
      <div
        className={classes.iconHolder}
      >
        <HelpOutlineIcon
          aria-label="Cancel Search"
          id="help-search-adorn"
          className={classNames(classes.buttonFontSize, collapsable ? classes.searchIconButton : classes.searchIcon)}
          tabIndex={0}
          onClick={() => onHelp(true)}
        />
      </div>;
  }

  return (
    <Input
      autoFocus={autoFocus}
      autoComplete="off"
      inputProps={{ 'aria-label': 'Search Transactions' }}
      label="Search Transactions"
      id="search"
      sharedcomponentid={'SEARCH_BOX'}
      inputRef={inputRef}
      classes={{
        root: classNames(classes.smartSearch, searchState.searchOpen || !collapsable ? 'open' : 'show', classes.root),
        input: classNames(classes.smartSearchInput, classes.input, searchState.searchOpen || !collapsable ? 'show' : 'hide'),
      }}
      margin="dense"
      placeholder={placeholder}
      value={searchState.smartSearchText}
      disableUnderline={!showUnderline}
      onKeyDown={keyDown}
      onChange={handleChange}
      startAdornment={
        <div className={classes.iconHolder}>
          <SearchIcon
            aria-label="Search"
            id="register-search-icon"
            className={collapsable ? classes.searchIconButton : classes.searchIcon}
            onClick={() => iconDoSearch()}
            tabIndex={0}
          />
        </div>
      }
      endAdornment={cancelAdorn}
    />
  );
};

export default withStyles(SearchBox, styles);
