// @flow
import React from 'react';

import { Map as ImmutableMap } from 'immutable';

import {
  doAccountRediscovery, doMigrateFi,
  doProvideCredentialsAndRefresh, doReAuthenticate, doUpdateCredentials,
  doUpdateFi,
} from 'components/Dialogs/AccountDiscovery/actions';

import { refreshAccounts } from 'data/institutionLogins/actions';
import type { InstitutionLogin } from 'data/institutionLogins/types';

import isAcme from 'isAcme';

import { createErrorDialog } from './actions';

// --- User facing error messaging ---
// title = Short text displayed to the user.
//    On account settings page it is shown next to error icon.
//    In dialogs, it is the title.
// text = Long text displayed to the user.
//    On account settings page it is shown if they click dropdown arrow.
//    In dialogs, it is subheading (below title).
// additionalInfo - Additonal text that can be displayed to the user.
// code = Error code returned from server.
// actionLabel = Button label.
// action = Event that happens when user clicks button.

const cpAggActionForConfirmation = (aggAction, excludeAction = false) => ({
  type: aggAction.type,
  error: aggAction.error,
  title: aggAction.title,
  text: aggAction.text,
  additionalInfo: aggAction.additionalInfo,
  status: aggAction.status,
  statusCode: aggAction.statusCode,
  code: aggAction.statusCode,
  action: !excludeAction ? aggAction.action : undefined,
  actionLabel: aggAction.actionLabel,
  institutionLogin: aggAction?.institutionLogin,
});

const fixAccountsNotAllowedError = {
  title: 'Account needs attention',
  text: 'To reconnect to your account, ask the owner of this dataset to do a one step update in their Quicken desktop and follow any instructions to restore connectivity',
  actionLabel: 'OK',
  get actionWithConfirmation() {
    return createErrorDialog(cpAggActionForConfirmation(this));
  },
};

const QcsAggregationErrorMap = {
  INVALID_CREDENTIALS: (institutionLogin, aggStatus, aggStatusCode, _featureFlags, _error, _isWebFirstDataset) => {
    if (institutionLogin.ewcLive) {
      return ({
        type: 'ERROR',
        id: 'INVALID_CREDENTIALS',
        title: 'Update your credentials',
        text: `To connect to ${institutionLogin.name}, you must re-enter your credentials. `
          + `If that does not work, try entering them on the website at ${institutionLogin.name} to ensure they are correct.`,
        status: aggStatus,
        statusCode: aggStatusCode,
        code: 'ccscrape.103',
        action: doProvideCredentialsAndRefresh(institutionLogin),
        get actionWithConfirmation() {
          return createErrorDialog(cpAggActionForConfirmation(this));
        },
        actionLabel: 'Enter Credentials',
        institutionLogin,
      });
    }
    return ({
      type: 'ERROR',
      id: 'INVALID_CREDENTIALS',
      title: 'Account needs attention',
      text: `To connect to ${institutionLogin.name}, you must re-enter your credentials. `
        + `If that does not work, try entering them on the website at ${institutionLogin.name} to ensure they are correct.`,
      status: aggStatus,
      statusCode: aggStatusCode,
      code: 'ccscrape.103',
      action: doUpdateCredentials(institutionLogin),
      get actionWithConfirmation() {
        return createErrorDialog(cpAggActionForConfirmation(this));
      },
      actionLabel: 'Enter Credentials',
      institutionLogin,
    });
  },
  ACCOUNT_NOT_FOUND: (institutionLogin, aggStatus, aggStatusCode, featureFlags, _error, _isWebFirstDataset) => {
    if (featureFlags.get('aggErrorHandleDeadAccounts')) {
      return ({
        type: 'ERROR',
        id: 'ACCOUNT_NOT_FOUND',
        title: 'Account needs attention',
        text: 'This usually happens if an account nickname or number has changed at your bank. '
          + 'To fix you must reconnect your account.',
        status: aggStatus,
        statusCode: aggStatusCode,
        code: 'ccscrape.324',
        actionLabel: 'Reconnect Account',
        action: doAccountRediscovery(institutionLogin),
        get actionWithConfirmation() {
          return createErrorDialog(cpAggActionForConfirmation(this));
        },
        institutionLogin,
      });
    }
    const content = (
      <>
        This usually happens if an account nickname or number changed at your bank.
        To fix you must deactivate and reactivate your account on your desktop. &nbsp;
        <a
          href="https://www.quicken.com/support/troubleshooting-online-banking-issues-quicken-mobile-and-quicken-web"
          target="_blank"
        >
          Click here for instructions
        </a>
        .
      </>
    );
    return {
      type: 'ERROR',
      id: 'ACCOUNT_NOT_FOUND',
      title: 'Account needs attention',
      text: content,
      status: aggStatus,
      statusCode: aggStatusCode,
      code: 'ccscrape.324',
      actionLabel: 'Try Again',
      action: refreshAccounts({ institutionLoginId: institutionLogin.id }),
      get actionWithConfirmation() {
        return createErrorDialog(cpAggActionForConfirmation(this));
      },
      institutionLogin,
    };
  },
  ACCOUNT_MISMATCH: (institutionLogin, aggStatus, aggStatusCode, featureFlags, _error, _isWebFirstDataset) => {
    if (aggStatusCode === 'ccscrape.169') { // probably just a QA case
      return ({
        type: 'ERROR',
        id: 'ACCOUNT_MISMATCH',
        title: 'This financial institution needs your attention',
        text: 'We detected two identical accounts when tried to add your financial data. This is a very unique case. '
          + 'Check to see if your financial accounts have identical names/data and reach out to our support team.',
        status: aggStatus,
        statusCode: aggStatusCode,
        code: 'ccscrape.169',
        actionLabel: 'Reconnect Account',
        action: doAccountRediscovery(institutionLogin),
        get actionWithConfirmation() {
          return createErrorDialog(cpAggActionForConfirmation(this));
        },
        institutionLogin,
      });
    }
    if (featureFlags.get('aggErrorHandleDeadAccounts')) {
      return ({
        type: 'ERROR',
        id: 'ACCOUNT_MISMATCH',
        title: 'Account needs attention',
        text: 'This usually happens if an account nickname or number has changed at your bank. '
          + 'To fix you must reconnect your account.',
        status: aggStatus,
        statusCode: aggStatusCode,
        code: 'ccscrape.106',
        actionLabel: 'Reconnect Account',
        action: doAccountRediscovery(institutionLogin),
        get actionWithConfirmation() {
          return createErrorDialog(cpAggActionForConfirmation(this));
        },
        institutionLogin,
      });
    }
    const content = (
      <>
        This usually happens if an account name or number has changed at your bank.
        To fix you must deactivate and reactivate your account in your desktop. Click &nbsp;
        <a href="https://www.quicken.com/support/troubleshooting-online-banking-issues-quicken-mobile-and-quicken-web" target="_blank">
          here
        </a>
        &nbsp; for instructions.
      </>
    );
    return ({
      type: 'ERROR',
      id: 'ACCOUNT_MISMATCH',
      title: 'Account needs attention',
      text: content,
      status: aggStatus,
      statusCode: aggStatusCode,
      code: 'ccscrape.106',
      actionLabel: 'Try Again',
      action: refreshAccounts({ institutionLoginId: institutionLogin.id }),
      get actionWithConfirmation() {
        return createErrorDialog(cpAggActionForConfirmation(this));
      },
      institutionLogin,
    });
  },
  FI_WEBSITE_UNAVAILABLE: (institutionLogin, aggStatus, aggStatusCode, _featureFlags, _error, _isWebFirstDataset) => ({
    type: 'ERROR',
    id: 'FI_WEBSITE_UNAVAILABLE',
    title: 'Account needs attention',
    text: `We are unable to connect to ${institutionLogin.name} at this time. Please try again later.`,
    status: aggStatus,
    statusCode: aggStatusCode,
    code: 'ccscrape.105',
    actionLabel: 'Try Again',
    action: refreshAccounts({ institutionLoginId: institutionLogin.id }),
    get actionWithConfirmation() {
      return createErrorDialog(cpAggActionForConfirmation(this));
    },
    institutionLogin,
  }),
  FI_TIMEOUT: (institutionLogin, aggStatus, aggStatusCode, _featureFlags, _error, _isWebFirstDataset) => ({
    type: 'ERROR',
    id: 'FI_TIMEOUT',
    title: 'Account needs attention',
    text: `We are unable to connect to ${institutionLogin.name} at this time. Please try again later.`,
    status: aggStatus,
    statusCode: aggStatusCode,
    code: 'ccscrape.102',
    actionLabel: 'Try Again',
    action: refreshAccounts({ institutionLoginId: institutionLogin.id }),
    get actionWithConfirmation() {
      return createErrorDialog(cpAggActionForConfirmation(this));
    },
    institutionLogin,
  }),
  // CP_SCRIPT_ERROR (ccscrape.181) - Unspecified OFX Error
  END_USER_ACTION_REQUIRED_AT_FI: (institutionLogin, aggStatus, aggStatusCode, _featureFlags, error, _isWebFirstDataset) => ({
    type: 'ERROR',
    id: 'END_USER_ACTION_REQUIRED_AT_FI',
    title: 'Account needs attention',
    text: error?.message || `To connect to your account, you must complete an action at the website for ${institutionLogin.name}.`,
    status: aggStatus,
    statusCode: aggStatusCode,
    code: 'ccscrape.108',
    actionLabel: 'Try Again',
    action: refreshAccounts({ institutionLoginId: institutionLogin.id }),
    get actionWithConfirmation() {
      return createErrorDialog(cpAggActionForConfirmation(this));
    },
    institutionLogin,
  }),
  // CP_SCRIPT_ERROR (ccscrape.180) - Invalid OFX File
  // CP_SCRIPT_ERROR (ccscrape.118) - Missing required symbol in symbol table
  // CP_SCRIPT_ERROR (ccscrape.101) - General Script Error
  // CP_SCRIPT_ERROR (ccwebbrwser.519) - Session Failed
  // CP_SCRIPT_ERROR (ccscrape.163) - Account keys have changed
  PASSWORD_CHANGED_REQUIRED_AT_FI: (institutionLogin, aggStatus, aggStatusCode, _featureFlags, _error, _isWebFirstDataset) => ({
    type: 'ERROR',
    id: 'PASSWORD_CHANGED_REQUIRED_AT_FI',
    title: 'Account needs attention',
    text: `To connect to your account, you must change your password at the website for ${institutionLogin.name}.`,
    status: aggStatus,
    statusCode: aggStatusCode,
    code: 'ccscrape.109',
    actionLabel: 'Try Again',
    action: refreshAccounts({ institutionLoginId: institutionLogin.id }),
    get actionWithConfirmation() {
      return createErrorDialog(cpAggActionForConfirmation(this));
    },
    institutionLogin,
  }),
  // CP_SCRIPT_ERROR (cccapture.307) - Communication error between components
  UNSUPPORTED_MFA_PROTOCOL: (institutionLogin, aggStatus, aggStatusCode, _featureFlags, _error, _isWebFirstDataset) => ({
    type: 'ERROR',
    id: 'UNSUPPORTED_MFA_PROTOCOL',
    title: 'Account needs attention',
    text: `The current multi-factor authentication method at ${institutionLogin.name} is not supported. `
      + `To connect to your account, you must change the multi-factor authentication method at ${institutionLogin.name}.`,
    status: aggStatus,
    statusCode: aggStatusCode,
    code: 'ccscrape.192',
    actionLabel: 'Try Again',
    action: refreshAccounts({ institutionLoginId: institutionLogin.id }),
    get actionWithConfirmation() {
      return createErrorDialog(cpAggActionForConfirmation(this));
    },
    institutionLogin,
  }),
  // QCS_SERVER_ERROR (authorization.domain.tp_account_id)
  // QCS_SERVER_ERROR (client.connect) - Mostly Gateway Timeout
  // QCS_SERVER_ERROR (authentication.user.credentials)
  // CP_SCRIPT_ERROR (ccwebbrowser.530) - Unknown (handshake failure?)
  MFA_FAILED: (institutionLogin, aggStatus, aggStatusCode, _featureFlags, _error, _isWebFirstDataset) => ({
    type: 'ERROR',
    id: 'MFA_FAILED',
    title: 'Account needs attention',
    text: `A multi-factor authentication is required to connect to your account at ${institutionLogin.name}.`,
    status: aggStatus,
    statusCode: aggStatusCode,
    code: 'ccscrape.187',
    actionLabel: 'Try Again',
    action: refreshAccounts({ institutionLoginId: institutionLogin.id }),
    get actionWithConfirmation() {
      return createErrorDialog(cpAggActionForConfirmation(this));
    },
    institutionLogin,
  }),
  MFA_REQUIRED_ACTION: (institutionLogin, aggStatus, aggStatusCode, _featureFlags, _error, _isWebFirstDataset) => ({
    type: 'ERROR',
    id: 'MFA_REQUIRED_ACTION',
    title: 'Account needs attention',
    text: `A multi-factor authentication is required to connect to your account at ${institutionLogin.name}.`,
    status: aggStatus,
    statusCode: aggStatusCode,
    code: 'ccscrape.185',
    actionLabel: 'Try Again',
    action: refreshAccounts({ institutionLoginId: institutionLogin.id }),
    get actionWithConfirmation() {
      return createErrorDialog(cpAggActionForConfirmation(this));
    },
    institutionLogin,
  }),
  CP_SESSION_TIMEOUT: (institutionLogin, aggStatus, aggStatusCode, _featureFlags, _error, _isWebFirstDataset) => ({
    type: 'ERROR',
    id: 'CP_SESSION_TIMEOUT',
    title: 'Account needs attention',
    text: `We are unable to connect to ${institutionLogin.name} at this time.`,
    status: aggStatus,
    statusCode: aggStatusCode,
    code: 'cccapture.331',
    actionLabel: 'Try Again',
    action: refreshAccounts({ institutionLoginId: institutionLogin.id }),
    get actionWithConfirmation() {
      return createErrorDialog(cpAggActionForConfirmation(this));
    },
    institutionLogin,
  }),
  // CP_SERVER_ERROR (ccclient.203) - CustomerCentral Client failed to receive a response
  FI_BLOCKING: (institutionLogin, aggStatus, aggStatusCode, _featureFlags, _error, _isWebFirstDataset) => ({
    type: 'ERROR',
    id: 'CP_SERVER_ERROR',
    title: 'Account needs attention',
    text: `We are unable to connect to ${institutionLogin.name} at this time.`,
    status: aggStatus,
    statusCode: aggStatusCode,
    code: 'ccscrape.155',
    actionLabel: 'Try Again',
    action: refreshAccounts({ institutionLoginId: institutionLogin.id }),
    get actionWithConfirmation() {
      return createErrorDialog(cpAggActionForConfirmation(this));
    },
    institutionLogin,
  }),
  // QCS_SERVER_EROR (client.response.xml.validation) - Stack trace from FDS Server
  // CP_SCRIPT_ERROR (ccscrape.100) -
  // CP_SCRIPT_ERROR (ccscrape.124) - Invalid percent value
  // QCS_SERVER_ERROR (client.request.xml.validation)
  // CP_SERVER_ERROR (ccscrape.199) - Unknown
  // CP_SCRIPT_ERROR (ccscrape.175) - Missing required return data
  // CP_SCRIPT_ERROR (ccscrape.141) - Form not found
  // CP_SCRIPT_ERROR (ccscrape.176) - A bill was not found at the site
  // CP_SERVER_ERROR (discover.no.account.found) -
  // QCS_SERVER_ERROR (ccscrape.107) - Incorrect FI selected to add a Particular type of account.
  CREDENTIALS_REQUIRED_ACTION: (institutionLogin, aggStatus, aggStatusCode, _featureFlags, _error, _isWebFirstDataset) => ({
    type: 'ERROR',
    id: 'CREDENTIALS_REQUIRED_ACTION',
    title: 'Account needs attention',
    text: `To connect to ${institutionLogin.name}, you must enter your credentials.`,
    status: aggStatus,
    statusCode: aggStatusCode,
    code: 'api.database.accountkey.notfound',
    actionLabel: 'Enter Credentials',
    action: doProvideCredentialsAndRefresh(institutionLogin),
    institutionLogin,
  }),
  CUSTOMER_ALREADY_LOGGED_IN_AT_FI: (institutionLogin, aggStatus, aggStatusCode, _featureFlags, _error, _isWebFirstDataset) => ({
    type: 'ERROR',
    id: 'CUSTOMER_ALREADY_LOGGED_IN_AT_FI',
    title: 'Account needs attention',
    text: `You must log out of your account at ${institutionLogin.name} to connect to your account in ${isAcme ? 'Simplifi' : 'Quicken'}.`,
    status: aggStatus,
    statusCode: aggStatusCode,
    code: 'ccscrape.179',
    actionLabel: 'Try Again',
    action: refreshAccounts({ institutionLoginId: institutionLogin.id }),
    get actionWithConfirmation() {
      return createErrorDialog(cpAggActionForConfirmation(this));
    },
    institutionLogin,
  }),
  // CP_SCRIPT_ERROR (ccwebbrowser.580) - Connection Failed
  CP_ACCOUNT_NOT_FOUND: (institutionLogin, aggStatus, aggStatusCode, featureFlags, _error, _isWebFirstDataset) => {
    if (featureFlags.get('aggErrorHandleDeadAccounts')) {
      return ({
        type: 'ERROR',
        id: 'CP_ACCOUNT_NOT_FOUND',
        title: 'Account needs attention',
        text: 'To restore connectivity to your account, you must reconnect to your bank.',
        status: aggStatus,
        statusCode: aggStatusCode,
        code: 'api.database.accountkey.notfound',
        actionLabel: 'Reconnect Account',
        action: doAccountRediscovery(institutionLogin),
        get actionWithConfirmation() {
          return createErrorDialog(cpAggActionForConfirmation(this));
        },
        institutionLogin,
      });
    }
    const content = (
      <div>
        To restore connectivity to your account, deactivate and reactivate your account in your Quicken desktop. Click &nbsp;
        <a href="https://www.quicken.com/support/troubleshooting-online-banking-issues-quicken-mobile-and-quicken-web" target="_blank">
          here
        </a>
        &nbsp; for instructions. <br />
        <br />
        If you see this error only on mobile and web but not on desktop, try disabling sync for this account in your Quicken desktop
        and then immediately re-enabling. Click &nbsp;
        <a href="https://www.quicken.com/support/troubleshooting-online-banking-issues-quicken-mobile-and-quicken-web" target="_blank">
          here
        </a>
        &nbsp; for instructions.
        <br />
      </div>
    );
    return ({
      type: 'ERROR',
      id: 'CP_ACCOUNT_NOT_FOUND',
      title: 'Account needs attention',
      text: content,
      status: aggStatus,
      statusCode: aggStatusCode,
      code: 'api.database.accountkey.notfound',
      actionLabel: 'Try Again',
      action: refreshAccounts({ institutionLoginId: institutionLogin.id }),
      get actionWithConfirmation() {
        return createErrorDialog(cpAggActionForConfirmation(this));
      },
      institutionLogin,
    });
  },
  // CP_SCRIPT_ERROR (ccwebbrowser.594) - Request Error
  // CP_SCRIPT_ERROR (ccscrape.189) - Script not written yet
  // CP_SCRIPT_ERROR (ccscrape.104) - Parse Error
  // CP_SCRIPT_ERROR (ccscrape.138) - No current table to use
  // CP_SERVER_ERROR (cccapture.302) - A required value is missing from the DB
  // CP_SERVER_ERROR (cccapture.334) - Unknown
  // CP_SCRIPT_ERROR (ccscrape.183) - No question available to add answer choices to.
  // CP_SCRIPT_ERROR (ccwebbrowser.503) - Invalid Request
  // CP_SCRIPT_ERROR (ccwebbrowser.520) - URL Invalid
  // CP_SCRIPT_ERROR (ccwebbrowser.531) - SSL Certificate Exception
  // CP_SCRIPT_ERROR (cccapture.195) - Web service changed
  // CP_SCRIPT_ERROR (ccscrape.119) - Invalid TekPortal data
  // QCS_SERVER_ERROR (api.request.value) -
  // CP_SCRIPT_ERROR (ccscrape.151) - No more previous page
  // CP_SERVER_ERROR (cccapture.332) - Unknown
  // CP_SERVER_ERROR (cccapture.333) - Unknown
  // CP_SCRIPT_ERROR (cccapture.193) - Call stack error
  // CP_SCRIPT_ERROR (ccscrape.156) - Form input field not found
  // CP_SCRIPT_ERROR (ccscrale.123) - Invalid dollar amount
  // CP_SCRIPT_ERROR (ccscrale.127) - String not found
  ACCOUNT_MOVED: (institutionLogin, aggStatus, aggStatusCode, _featureFlags, _error, _isWebFirstDataset) => ({
    type: 'ERROR',
    id: 'ACCOUNT_MOVED',
    title: 'Account needs attention',
    text: 'TBD',
    status: aggStatus,
    statusCode: aggStatusCode,
    code: 'ccscrape.162',
    actionLabel: 'Try Again',
    // todo: What do we do here? Deactivate & Reactivate to New FI
    action: null,
    institutionLogin,
  }),
  PAUTH_FLOW_REQUIRED: (institutionLogin, aggStatus, aggStatusCode, _featureFlags, _error, _isWebFirstDataset) => ({
    type: 'ERROR',
    id: 'PAUTH_FLOW_REQUIRED',
    title: 'Account needs attention',
    text: 'To reconnect to your account, do a one step update in your Quicken desktop and follow any instructions to restore connectivity.',
    status: aggStatus,
    statusCode: aggStatusCode,
    code: 'cccapture.351',
    actionLabel: 'Try Again',
    action: refreshAccounts({ institutionLoginId: institutionLogin.id }),
    get actionWithConfirmation() {
      return createErrorDialog(cpAggActionForConfirmation(this));
    },
    institutionLogin,
  }),
  PAUTH_INVALID_TOKEN: (institutionLogin, aggStatus, aggStatusCode, _featureFlags, _error, _isWebFirstDataset) => ({
    type: 'ERROR',
    id: 'PAUTH_INVALID_TOKEN',
    title: 'Account needs attention',
    text: `${institutionLogin.name} requires you to re-authenticate`,
    status: aggStatus,
    statusCode: aggStatusCode,
    code: 'cccapture.350',
    actionLabel: 'Re-Authenticate',
    action: doReAuthenticate(institutionLogin),
    institutionLogin,
  }),
  PAUTH_SLT_ERROR: (institutionLogin, aggStatus, aggStatusCode, _featureFlags, _error, _isWebFirstDataset) => ({
    type: 'ERROR',
    id: 'PAUTH_SLT_ERROR',
    title: 'Account needs attention',
    text: `${institutionLogin.name} requires you to re-authenticate`,
    status: aggStatus,
    statusCode: aggStatusCode,
    code: 'api.partnerauth.error',
    actionLabel: 'Re-Authenticate',
    action: doReAuthenticate(institutionLogin),
    institutionLogin,
  }),
  INCORRECT_FI: (institutionLogin, aggStatus, aggStatusCode, _featureFlags, _error, _isWebFirstDataset) => ({
    type: 'ERROR',
    id: 'INCORRECT_FI',
    title: 'Account needs attention',
    text: `We are unable to setup your accounts at '${institutionLogin.name}'. Please try selecting a different institution.`,
    status: aggStatus,
    statusCode: aggStatusCode,
    code: 'ccscrape.107',
    actionLabel: 'Try Again',
    action: doUpdateFi({ institutionLogin, clearInstitution: true }),
    institutionLogin,
  }),
  REAUTHENTICATION_REQUIRED: (institutionLogin, aggStatus, aggStatusCode, _featureFlags, _error, _isWebFirstDataset) => ({
    type: 'ERROR',
    id: 'REAUTHENTICATION_REQUIRED',
    title: 'Account needs attention',
    text: `${institutionLogin.name} requires you to re-authenticate`,
    status: aggStatus,
    statusCode: aggStatusCode,
    code: 're-authenticate',
    actionLabel: 'Re-Authenticate',
    action: doReAuthenticate(institutionLogin),
    institutionLogin,
  }),
  // CP_SCRIPT_ERROR (cccapture.196) - Web service response error
  CP_SCRIPT_ERROR: (institutionLogin, aggStatus, aggStatusCode, _featureFlags, error, _isWebFirstDataset) => {
    if (aggStatusCode === 'ccscrape.181') {
      if (institutionLogin.brandingId === '10898') {
        return ({
          type: 'ERROR',
          id: 'END_USER_ACTION_REQUIRED_AT_FI',
          title: 'Account needs attention',
          text: 'After signing in to the Chase website, click on the menu button in the upper left corner of the website. '
            + 'Choose Secure Messages. Click most recent message with subject of "Action Required for Quicken" and follow the steps. Once you confirm the access, then return here and click the Try Again button.',
          status: aggStatus,
          statusCode: aggStatusCode,
          code: 'ccscrape.181',
          actionLabel: 'Try Again',
          action: refreshAccounts({ institutionLoginId: institutionLogin.id }),
          get actionWithConfirmation() {
            return createErrorDialog(cpAggActionForConfirmation(this));
          },
          institutionLogin,
        });
      }
    }
    return ({
      type: 'ERROR',
      id: 'CP_SCRIPT_ERROR',
      title: 'Account needs attention',
      text: error?.message || `We are unable to connect to ${institutionLogin.name} at this time.`,
      status: aggStatus,
      statusCode: aggStatusCode,
      code: 'cp-error',
      actionLabel: 'Try Again',
      action: refreshAccounts({ institutionLoginId: institutionLogin.id }),
      get actionWithConfirmation() {
        return createErrorDialog(cpAggActionForConfirmation(this));
      },
      institutionLogin,
    });
  },
  CP_SERVER_ERROR: (institutionLogin, aggStatus, aggStatusCode, _featureFlags, _error, _isWebFirstDataset) => ({
    type: 'ERROR',
    id: 'CP_SERVER_ERROR',
    title: 'Account needs attention',
    text: `We are unable to connect to ${institutionLogin.name} at this time.`,
    status: aggStatus,
    statusCode: aggStatusCode,
    code: 'cp-error',
    actionLabel: 'Try Again',
    action: refreshAccounts({ institutionLoginId: institutionLogin.id }),
    get actionWithConfirmation() {
      return createErrorDialog(cpAggActionForConfirmation(this));
    },
    institutionLogin,
  }),
  409: (institutionLogin, aggStatus, aggStatusCode, _featureFlags, _error, _isWebFirstDataset) => ({
    type: 'ERROR',
    id: '409',
    title: 'Account needs attention',
    text: `We are unable to update ${institutionLogin.name} at this time due to your another conflicting login.`,
    status: aggStatus,
    statusCode: aggStatusCode,
    code: null,
    actionLabel: 'Try Again',
    action: refreshAccounts({ institutionLoginId: institutionLogin.id }),
    get actionWithConfirmation() {
      return createErrorDialog(cpAggActionForConfirmation(this));
    },
    institutionLogin,
  }),
  429: (institutionLogin, aggStatus, aggStatusCode, featureFlags, error, isWebFirstDataset) => {
    switch (aggStatusCode) {
      case 'QCS-0429-2':
        return ({
          type: 'ERROR',
          id: '429',
          title: 'Account needs attention',
          text: `We are unable to update ${institutionLogin.name} at this time. Please try again later. `
            + `If you continue to experience issues, please contact ${isAcme ? 'Simplifi' : 'Quicken'} support. `,
          status: aggStatus,
          statusCode: aggStatusCode,
          code: 'sr.100',    // sr = security review
          actionLabel: 'Try Again',
          action: refreshAccounts({ institutionLoginId: institutionLogin.id }),
          get actionWithConfirmation() {
            return createErrorDialog(cpAggActionForConfirmation(this));
          },
          institutionLogin,
        });
      default:
        return QcsAggregationErrorMap.GENERIC(institutionLogin, aggStatus, aggStatusCode, featureFlags, error, isWebFirstDataset);
    }
  },
  AD_UNABLE_TO_ENCRYPT_CREDENTIALS: (institutionLogin, aggStatus, aggStatusCode, _featureFlags, _error, _isWebFirstDataset) => ({
    type: 'ERROR',
    id: 'AD_UNABLE_TO_ENCRYPT_CREDENTIALS',
    text: `We are unable to connect to ${institutionLogin?.name || 'your institution'} at this time.`,
    status: aggStatus,
    statusCode: aggStatusCode,
    code: null,
    institutionLogin,
  }),
  AD_TIMEOUT: (institutionLogin, aggStatus, aggStatusCode, _featureFlags, _error, _isWebFirstDataset) => ({
    type: 'ERROR',
    id: 'AD_TIMEOUT',
    text: `We are unable to connect to ${institutionLogin?.name || 'your institution'} at this time. Please try again later.`,
    status: aggStatus,
    statusCode: aggStatusCode,
    code: null,
    institutionLogin,
  }),
  CHANNEL_RESET_REQUIRED: (institutionLogin, aggStatus, aggStatusCode, _featureFlags, _error, isWebFirstDataset, isAllowedToFixAccount) => {
    if (!isAllowedToFixAccount) {
      return {
        ...fixAccountsNotAllowedError,
        type: 'ERROR',
        id: 'CHANNEL_RESET_REQUIRED',
        action: refreshAccounts({ institutionLoginId: institutionLogin.id }),
        institutionLogin,
      };
    } else {
      return {
        type: 'ERROR',
        id: 'CHANNEL_RESET_REQUIRED',
        title: isAcme ? 'Fix account connection' : 'Account needs attention',
        /* eslint-disable no-nested-ternary */
        text: isAcme ? 'Your account connection no longer works, but we have a new & improved way to connect to your financial institution' : isWebFirstDataset ? `For enhanced security, ${institutionLogin?.name || 'your institution'} requires you to sign in using their secure authentication system to connect to Quicken. To continue downloading your transactions in Quicken, select Reconnect.` : 'To reconnect to your account, do a one step update in your Quicken desktop and follow any instructions to restore connectivity.',
        additionalInfo: `We will take you to your bank site to enter your credentials & grant ${isAcme ? 'Simplifi' : 'Quicken'} access to your data. `
          + `When prompted, please select all your accounts. You will be able to ignore any accounts you don't want in ${isAcme ? 'Simplifi' : 'Quicken'}.`,
        status: aggStatus,
        statusCode: aggStatusCode,
        code: 'FDP-390',
        get action() {
          return this.actionWithConfirmation;
        },
        get actionWithConfirmation() {
          return isWebFirstDataset || isAcme ? doMigrateFi(institutionLogin, cpAggActionForConfirmation(this, true)) : refreshAccounts({ institutionLoginId: institutionLogin.id });
        },
        actionLabel: isWebFirstDataset || isAcme ? 'Reconnect' : 'Try Again',
        institutionLogin,
      };
    }
  },
  GENERIC: (institutionLogin, aggStatus, aggStatusCode, _featureFlags, error, _isWebFirstDataset) => ({
    // handles generic / unknown errors
    type: 'ERROR',
    id: 'GENERIC',
    title: 'Account needs attention',
    text: error?.message || `We are unable to connect to ${institutionLogin?.name || 'your institution'} at this time.`,
    status: aggStatus,
    statusCode: aggStatusCode,
    code: null,
    actionLabel: 'Try Again',
    action: institutionLogin && refreshAccounts({ institutionLoginId: institutionLogin.id }),
    get actionWithConfirmation() {
      return createErrorDialog(cpAggActionForConfirmation(this));
    },
    institutionLogin,
  }),
};

const QcsOptionalActionMap = {
  FI_MIGRATION: (institutionLogin, _featureFlags, isWebFirstDataset, isAllowedToFixAccount) => {
    if (!isAllowedToFixAccount) {
      return {
        ...fixAccountsNotAllowedError,
        type: 'PROACTIVE_ACTION',
        id: 'FI_MIGRATION',
        action: refreshAccounts({ institutionLoginId: institutionLogin.id }),
        institutionLogin,
      };
    } else {
      return {
        type: 'PROACTIVE_ACTION',
        id: 'FI_MIGRATION',
        title: isWebFirstDataset || isAcme ? 'Update account connection' : 'Account needs attention',
        text: isAcme ? 'We have a new and improved way to connect to your financial institution' : isWebFirstDataset ? `For enhanced security, ${institutionLogin?.name || 'your institution'} requires you to sign in using their secure authentication system to connect to Quicken. To continue downloading your transactions in Quicken, select Reconnect.` : 'To reconnect to your account, do a one step update in your Quicken desktop and follow any instructions to restore connectivity.',
        additionalInfo: `We will take you to your bank site to enter your credentials & grant ${isAcme ? 'Simplifi' : 'Quicken'} access to your data. `
          + `When prompted, please select all your accounts. You will be able to ignore any accounts you don't want in ${isAcme ? 'Simplifi' : 'Quicken'}.`,
        code: null,
        actionLabel: isWebFirstDataset || isAcme ? 'Reconnect' : 'Try Again',
        get action() {
          return this.actionWithConfirmation;
        },
        get actionWithConfirmation() {
          return isWebFirstDataset || isAcme ? doMigrateFi(institutionLogin, cpAggActionForConfirmation(this, true)) : refreshAccounts({ institutionLoginId: institutionLogin.id });
        },
        institutionLogin,
      };
    }
  },
};

// CACHE_TIMEOUT(1350, "Operation took too long, the QCS cache has timed-out.", true),
// CP_USER_CREDENTIALS_ARE_INVALID(1475, "The user credentials are invalid for content provider.", true),
// FI_WEBSITE_IN_SCHEDULED_MAINTANANCE(1900, "Website unavailable on a particular time period.", true),
// MFA_REQUIRED_ACTION(2200, "User action is required to bypass multi-factor authentication.", true),
// NO_ACCOUNTS_FOUND(2250, "No accounts found.", true),
// NO_ACCOUNTS_REFRESHED(2275, "No accounts refreshed.", true),
// STATUS_TERMINATION_TIMEOUT(2350, "Status took too long to change to a terminated status", true),
// UNSUPPORTED_MFA_PROTOCOL(2400, "Unsupported multi-factor authentication protocol", true),
//
// CP_SERVER_ERROR(3000, "Content-Provider Server Error", true),
// CP_SCRIPT_ERROR(3001, "Content-Provider Script Error", true),
//
// QCS_SERVER_ERROR(5000, "QCS Server Error", true),

export const getQcsAggregationAction = (
  institutionLogin: InstitutionLogin,
  aggStatus: string,
  aggStatusCode: ?string,
  featureFlags: ImmutableMap<string, any>,
  error: ?any,
  isWebFirstDataset: ?boolean,
  isAllowedToFixAccount: ?boolean,
) => {
  // should QCS do the following?
  if (aggStatus === 'QUEUED_MFA_CHALLENGE' || aggStatus === 'MFA_CHALLENGE_READY_FOR_USER'
  ) {
    aggStatus = 'MFA_REQUIRED_ACTION';  // eslint-disable-line no-param-reassign
  }

  if (aggStatus && aggStatus !== 'OK') {
    const errorLambda = QcsAggregationErrorMap[aggStatus] || QcsAggregationErrorMap.GENERIC;
    return errorLambda(institutionLogin, aggStatus, aggStatusCode, featureFlags, error, isWebFirstDataset, isAllowedToFixAccount);
  }
  return isWebFirstDataset && institutionLogin?.optionalAction && QcsOptionalActionMap[institutionLogin.optionalAction](institutionLogin, featureFlags, isWebFirstDataset, isAllowedToFixAccount);
};

