/* eslint react/prop-types: 0 */

import React from 'react';
import { DateTime } from 'luxon';
import numeral from 'numeral';

import { getLogger, tracker } from 'companion-app-components/utils/core';
import { transactionsTypes } from 'companion-app-components/flux/transactions';
import { preProcessDelete, preProcessUpdate, removeTxnWithInvalidAccountIds } from 'companion-app-components/flux/transactions-processing/preProcess';

// DATA
import store from 'store';

import Typography from '@mui/material/Typography';
import MatchIcon from '@mui/icons-material/CompareArrows';

import { getBalanceForAccountAtDate } from 'data/accountBalances/retrievers';
import { findTransaction, getTxnDifferences, isExistingTxn, replaceInArray } from 'data/transactions/utils';

import { isAcme } from 'isAcme';
import MiniTxnCard from './MiniTxnCard/index';

const log = getLogger('components/QData/index.js');

const locationsMap = new Map([
  ['registerWrapper_TxPage', 'transactions'],
  ['Reg_Budgets', 'budgets'],
  ['Reg_SpendingGraph', 'spending'],
]);


const getFieldsChanged = (txn, props) => { // only used for mixpanel tracking

  let txnPrev;
  if (!isExistingTxn(txn)) { // Create
    txnPrev = new transactionsTypes.CashFlowTransaction({ accountId: txn.accountId });
  } else {
    txnPrev = findTransaction(props.txnsByAccountId, txn.id, txn.accountId);
    if (!txnPrev) {
      log.error('Error, could not find previous transaction', props.txnsByAccountId.toJS(), txn);
    }
  }
  return getTxnDifferences(txn, txnPrev);
};


export const performTransactionAction = (action, props) => {
  log.log('Performing Transaction Action ', action);
  props.performTransactionAction(action, { context: props.wrapperId });
};

export const unMatchTransaction = (txn, props) => {
  log.log('UNMATCH TRANSACTION', txn);

  props.performTransactionAction(
    { action: 'unmatch', id: txn.id },
    { context: props.wrapperId },
  );
};

export const matchTransactions = (txns, props) => { // eslint-disable-line
  log.log('MATCH TRANSACTIONS', txns);
  if (txns.length !== 2) {
    return false;
  }

  props.performTransactionAction(
    { action: 'match', id: txns[0].id, otherId: txns[1].id },
    { context: props.wrapperId },
  );

  tracker.track(tracker.events.txnManualMatch, {
    state: 'complete',
  });
};

export const getMatch = (candidates, txn) =>
  candidates.find((x) => x.guid === txn.guid);

export const addMatches = (txnPack) => {
  const { allMatches, allTxnsToUpdate, allTxnsToCreate } = txnPack;

  // Go through all matches and replace the transaction to create or update with the matching txn
  const success = allMatches.every((match) => {
    const { possibleMatch, baseTxn } = match;
    if (possibleMatch) {
      if (isExistingTxn(possibleMatch)) {
        allTxnsToUpdate.push(possibleMatch);
        const existingIndex = allTxnsToCreate.findIndex((txn) => txn?.transfer?.clientId === baseTxn?.clientId);
        if (existingIndex > -1) {
          allTxnsToCreate.splice(existingIndex, 1);
        }
      } else { // for some reason, the possible match is currently being created
        // hopefully this shouldn't happen
        const existingIndex = allTxnsToCreate.findIndex((txn) => txn.clientId === possibleMatch?.clientId);
        if (existingIndex > -1) {
          allTxnsToCreate.splice(existingIndex, 1, possibleMatch);
        }
      }
      // after adding new match, update the other side with the new connected transfer coa
      return replaceInArray(allTxnsToUpdate, baseTxn) || replaceInArray(allTxnsToCreate, baseTxn);
      // if cannot find base txn to match with, returns false
      // SHOULD NOT HAPPEN - false will display warning dialog
      // TODO: alternatively, we could just update/create if we can't already find baseTxn already staged
      //  not sure if that's what we want to do, but an option if users do see "Error: Matching operation failed"
    }

    return true;
  });

  return success;
};

export const txnTrackType = (txn) => {
  if (!isExistingTxn(txn)) {
    return 'new manual';
  }
  return txn.cpData ? 'edit downloaded' : 'edit manual';
};

// Send info to MixPanel for this action
export const sendTrackingInfo = (txnPack, props) => {
  const { allTxnsToDelete, allTxnsToUpdate, allTxnsToCreate } = txnPack;
  const location = locationsMap.get(props.wrapperId);

  // Send tracking data for deleted transactions
  allTxnsToDelete.forEach((x) => {
    tracker.track(tracker.events.txnDelete, {
      state: 'complete',
      type: txnTrackType(x),
      location,
    });
  });
  // Send tracking data for updated transactions
  allTxnsToUpdate.forEach((x) => {
    if (x.isDeleted) {
      tracker.track(tracker.events.txnDelete, {
        state: 'complete',
        type: txnTrackType(x),
        location,
      });
    } else {
      const trackObj = {
        state: 'complete',
        type: txnTrackType(x),
        fields: getFieldsChanged(x, props),
        location,
      };
      tracker.track(tracker.events.txnSave, trackObj);
      // log.log('TRACKING OBJ = ', trackObj);
    }
  });
  allTxnsToCreate.forEach((x) => {
    const trackObj = {
      state: 'complete',
      type: txnTrackType(x),
      fields: getFieldsChanged(x, props),
      location,
    };
    tracker.track(tracker.events.txnSave, trackObj);
    // log.log('TRACKING OBJ = ', trackObj);
  });
};

export const sendTransactionPack = (txnPack, props) => {

  // send mixPanel events
  sendTrackingInfo(txnPack, props);

  removeTxnWithInvalidAccountIds(txnPack, 'sendTransactionPack');

  log.log('TRANSACTION PACK TO SEND TO ACTIONS/SAGAS ', txnPack);
  const { allTxnsToDelete, allTxnsToUpdate, allTxnsToCreate, extraActions } = txnPack;

  if (allTxnsToDelete?.length === 1) { // better error handling for non-batch request
    props.deleteTransaction(allTxnsToDelete[0], { context: props.wrapperId, undo: { userMessage: 'Transaction deleted.' } });
  } else if (allTxnsToDelete?.length > 1) {
    props.batchTransactions(allTxnsToDelete, { context: props.wrapperId, undo: { userMessage: 'Transactions deleted.' } });
  }

  if (allTxnsToUpdate?.length === 1) {
    props.updateTransaction(allTxnsToUpdate[0], { context: props.wrapperId, undo: { userMessage: 'Transaction updated.' } });
  } else if (allTxnsToUpdate?.length > 1) {
    props.batchTransactions(allTxnsToUpdate, { context: props.wrapperId, undo: { userMessage: 'Transactions updated.' } });
  }

  if (allTxnsToCreate?.length === 1) {
    props.createTransaction(allTxnsToCreate[0], { context: props.wrapperId, undo: !props.robotMode && { userMessage: 'Transaction created.' } });
  } else if (allTxnsToCreate?.length > 1) {
    props.batchTransactions(allTxnsToCreate, { context: props.wrapperId, undo: !props.robotMode && { userMessage: 'Transactions created.' } });
  }
  
  // extra actions are objects { action, delay }
  if (extraActions) {
    extraActions.forEach((item) => setTimeout(() => props.genericDispatch(item.action), item.delay));
  }
};

export const processTransactionPack = (txnPack, props) => {

  const { allMatches } = txnPack;

  // User already went through any prior warnings, but we check now to see if there are any matched txns
  // in 'allMatches' which we will ask the user if they want to use them instead of the transaction in
  // create package (use matched instead of new transfer txn)
  //
  // This is an all or nothing (all matches accepted, or none)
  // TODO create a match dialog that lets you select each one, but do this when we have some sort of
  // TODO autosave that lets you create multiple matching transfers at once
  //
  if (allMatches && allMatches.length > 0) {

    const message = [];
    let forceWarn = false;
    allMatches.forEach((x) => {

      forceWarn = forceWarn || x.forceWarn;  // if one has it, they all have it
      const msg1 = (
        <Typography
          style={{ marginBottom: 15, fontSize: '16px', color: '#555' }}
        >
          We found similar activity for the following. Do you want to connect these transactions?
        </Typography>
      );

      const msg2 = (
        <div style={{ display: 'flex', padding: '7px 0 7px 0' }}>
          <MiniTxnCard style={{ flex: 2, maxWidth: 250 }} txn={x.possibleMatch} />
          <div style={{ flex: 0, minWidth: 25, width: 25, margin: 'auto', textAlign: 'center' }}>
            <MatchIcon />
          </div>
          <MiniTxnCard style={{ flex: 2, maxWidth: 250 }} txn={x.baseTxn} />
        </div>
      );
      message.push(<div style={{ padding: '0px 7px 0px 7px' }} key={`matchDlg:${x.possibleMatch.payee}`}> {msg1} {msg2} </div>);
    });

    // No dialog/prompts for Acme, unless forced
    if (!isAcme || forceWarn) {
      props.dialogAlert(
        isAcme ? 'Link transfers' : 'Connect transfers',
        message,
        (btnObj) => {
          if (btnObj.btnPressed === 'Match' || btnObj.btnPressed === 'Link') {

            if (addMatches(txnPack)) {
              sendTransactionPack(txnPack, props);
            } else {
              props.dialogAlert(
                'Error: Matching operation failed',
                'You will need to create the match manually',
                null,
                ['Got It'],
              );
            }
          } else if (btnObj.btnPressed === "Don't Match" || btnObj.btnPressed === "Don't Link") {
            sendTransactionPack(txnPack, props);
          }
        },
        ['Cancel', isAcme ? 'Link' : 'Match'],
        'none',
        false,
        false,
        isAcme ? "Don't Link" : "Don't Match",
      );
    } else {
      addMatches(txnPack);
      sendTransactionPack(txnPack, props);
    }


  } else {
    sendTransactionPack(txnPack, props);
  }
};

export const errorsAndWarningsConfirm = (warnings, errors, cb, props) => {

  // Show Errors
  if (errors && errors.length > 0) {
    const message = [<p key="titleerr+=+"> </p>];
    errors.forEach((x, index) => {
      const val = (
        <Typography
          variant="subtitle1"
          key={x}
        >
          {`(${index + 1}) ${x}`} <br />
        </Typography>
      );
      message.push(val);
    });

    props.dialogAlert(
      errors.length > 1 ? 'Some errors occurred' : 'An error occurred',
      message,
      () => { cb(false); },
      ['Got it'],
      'error',
    );
    return null;
  }

  // Show warnings
  /* eslint-disable react/no-array-index-key */
  if (warnings && warnings.length > 0) {
    const message = [];
    message.push(
      <Typography variant="subtitle1" key={`uniquekeygoeshere${warnings.length}`}> Before you continue... <br /> </Typography>,
    );
    warnings.forEach((x, index) => {
      message.push(<React.Fragment key={`da_msg_p${x}${index}`}> <Typography> {` - ${x} `} </Typography> </React.Fragment>);
    });
    props.dialogAlert(
      warnings.length > 1 ? 'Some warnings before you continue...' : 'A warning before you continue...',
      message,
      (btnObj) => {
        if (btnObj.btnPressed === 'Continue') {
          cb(true);
        }
        cb(false);
      },
      ['Cancel', 'Continue'],
      'warning',
    );
    return null;
  }

  return cb(true);
};

export const deleteTransactions = (txns, props) => {
  const { allTxnsToDelete, allTxnsToUpdate, warnings, errors } =
    preProcessDelete(
      { txns,
        txnsByAccountId: props.txnsByAccountId,
        isUpdate: false,
        featureFlags: props.featureFlags,
        state: store?.getState(),
      },
    );

  errorsAndWarningsConfirm(warnings, errors, ((confirm) => {

    if (confirm) {
      // fire off action to update the transactions that need to be updated
      //
      if (allTxnsToUpdate?.length === 1) {
        props.updateTransaction(allTxnsToUpdate[0], { context: props.wrapperId, undo: { userMessage: 'Transaction updated.' } });
      } else if (allTxnsToUpdate?.length > 1) {
        props.batchTransactions(allTxnsToUpdate, { context: props.wrapperId, undo: { userMessage: 'Transactions updated.' } });
      }

      const commonProps = { context: props.wrapperId };
      const getDeleteProps = (isMultipleDelete = false) => {
        if (props?.isBulkDelete) { return commonProps; }
        const userMessage = `Transaction${isMultipleDelete ? 's' : ''} deleted.`;
        return { ...commonProps, undo: { userMessage } };
      };

      if (allTxnsToDelete?.length === 1) {
        props.deleteTransaction(allTxnsToDelete[0], getDeleteProps());
      } else if (allTxnsToDelete?.length > 1) {
        props.batchTransactions(allTxnsToDelete, getDeleteProps(true));
      }
    }
  }), props);
};

export const saveTransactions = (txns, cb, props) => {
  log.log('QDATA Save Transactions', props, '\ntxns:', txns);
  const { warnings, errors, ...txnPack } =
    preProcessUpdate({
      txns,
      txnsByAccountId: props.txnsByAccountId,
      featureFlags: props.featureFlags,
    });
  log.log('QDATA', '\nWarnings: ', warnings, '\nErrors: ', errors, '\ntxnPack: ', txnPack);

  //
  // in some cases during a multi-transaction update, a transfer transaction might be getting updated
  // along with one of it's children.  This can result in a new child transaction being created, and the
  // previous transaction being deleted, but also the previous transaction may attempt to update itself
  // effectively undeleting itself.  We check for any updates conflicting with deletes, and we remove
  // the updates if there is a conflict (delete wins)
  //
  // const newTxnPack = removeConflicts(txnPack);
  const newTxnPack = txnPack;
  // robotMode used to hide the modals displaying errors / warnings
  if (props.robotMode) {
    // if errors present & callback, call callback w/ false & don't update
    if (errors && errors.length > 0 && cb) {
      cb(false);
    }
    // otherwise, update and call callback w/ true
    processTransactionPack(newTxnPack, props);
    if (cb) { cb(true); }
    return null;
  }
  // only call errorsAndWarnings if no robotMode
  errorsAndWarningsConfirm(warnings, errors, ((confirm) => {
    if (confirm) {
      processTransactionPack(newTxnPack, props);
      if (cb) {
        cb(true);
      }
      return null;
    }
    if (cb) {
      cb(false);
    }
    return null;
  }), props);

  return null;
};

export const setBalanceAdjust = (accountId, date, amount, props) => {

  // We need a way to get the balanceAsOf the given date, that would be, the ending balance after all the
  // transactions for the given date have been considered.  We then add a balance adjustment on the same
  // date to make the balance be the amount given
  // we do this by starting with the account's ending balance, and count down until we see the first txn
  // of the given date

  const balanceAtDate = getBalanceForAccountAtDate({ accountId, date });
  if (balanceAtDate !== null) {
    const balAdjustAmount = amount - balanceAtDate;
    const dateForAdjust = DateTime.fromISO(date);

    //
    // create a new transaction and save it
    //
    const balAdjustTxn = new transactionsTypes.CashFlowTransaction(
      {
        accountId,
        amount: balAdjustAmount,
        isReviewed: true,
        state: 'RECONCILED',
        payee: 'User Requested Balance Adjustment',
        memo: `Requested ending balance on ${dateForAdjust.toFormat('MM/dd/yyyy')} to be ${numeral(amount).format('0,00.00')}`,
        postedOn: dateForAdjust,
        coa: {
          type: 'BALANCE_ADJUSTMENT',
          id: '0',
        },
      },
    );
    saveTransactions([balAdjustTxn], props);
  }
};

