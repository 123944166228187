import React, { useEffect, FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { List } from 'immutable';

import type RootState from 'companion-app-components/utils/redux-store/rootState';
import { accountsActions, accountsSelectors, accountsTypes } from 'companion-app-components/flux/accounts';

import DownshiftField from 'components/QuickenControls/DownshiftField';
import ReadOnlyField from 'components/Transactions/ReadOnlyField';

interface AccountFieldProps {
  accounts?: List<accountsTypes.Account>;
  initialAccountId?: string;
  onSelected?: (account: accountsTypes.Account | null) => void;
  readOnly?: boolean;
  className?: string;
  doNotIncludeClosed?: boolean;
}

const AccountField: FC<AccountFieldProps> = ({
  accounts: propAccounts,
  initialAccountId,
  onSelected,
  readOnly,
  className,
  doNotIncludeClosed,
  ...otherProps
}) => {

  const dispatch = useDispatch();
  
  const accountsFromStore = useSelector((state: RootState) => accountsSelectors.getTransactionAccounts(state));
  const accountsLoadPending = useSelector((state: RootState) => accountsSelectors.getLoadPending(state));

  const accounts = propAccounts || accountsFromStore;
  const initialAccount = accounts?.get(initialAccountId || '');

  useEffect(() => {
    if (accountsLoadPending) {
      dispatch(accountsActions.getAccounts());
    }
  }, [accountsLoadPending, dispatch]);

  let sortedAccounts = accounts
    ? Array.from(accounts.values()).sort((accountOne, accountTwo) =>
      accountOne.name.localeCompare(accountTwo.name, undefined, { numeric: true, sensitivity: 'base' }))
    : [];

  if (doNotIncludeClosed) {
    sortedAccounts = sortedAccounts.filter((account) => !account.isClosed);
  }

  if (readOnly) {
    return (
      <ReadOnlyField
        {...otherProps}
        label="Account"
        value={initialAccount ? initialAccount.name : 'Unknown'}
        className={className}
        sharedcomponentid="ACCOUNT_FIELD"
      />
    );
  }

  return (
    <DownshiftField
      dataLoading={accountsLoadPending}
      items={sortedAccounts}
      initialItemSelected={initialAccount}
      onSelected={onSelected}
      textFieldProps={otherProps}
      itemToString={(account) => (account ? account.name : '')}
      itemKey={(account) => (account ? account.id : '')}
      indexId
      className={className}
    />
  );
};

export default AccountField;
