import React, { useState, useCallback } from 'react';
import { makeStyles } from 'tss-react/mui';
import classNames from 'classnames';
import { List } from 'immutable';

import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CheckIcon from '@mui/icons-material/Check';

import { Dataset } from 'companion-app-components/flux/datasets/datasetsTypes';

import { Subscription } from 'data/subscriptions/subscriptionsTypes';
import ProfileAvatar from 'containers/App/Header/ProfileMenu/ProfileAvatar';
import SubscriptionAlert from 'containers/ProfilePage/SubscriptionAlert';
import WarningImage from 'assets/warning.svg';

import { styles } from './styles';

interface MiniDatasetPickerProps {
  dataset: Dataset | null;
  ownDatasets: List<Dataset>;
  sharedDatasets: List<Dataset>;
  entitledDatasets: List<Dataset>;
  subscription: Subscription | null;
  label?: string;
  labelStyle?: Record<string, any>;
  onChange: (value: Dataset) => void;
  id?: string;
}

const useStyle = makeStyles()(styles);

const MiniDatasetPicker: React.FC<MiniDatasetPickerProps> = ({
  dataset,
  ownDatasets,
  sharedDatasets,
  entitledDatasets,
  subscription,
  label = 'CHANGE',
  labelStyle,
  onChange,
  id,
}) => {

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { classes } = useStyle();

  const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleMenuItemClick = useCallback((selectedDataset: Dataset) => {
    setAnchorEl(null);
    if (onChange && selectedDataset) {
      onChange(selectedDataset);
    }
  }, [onChange]);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const renderDatasets = useCallback((theDataset: Dataset, index: number, selectedDataset: Dataset | null, ownDataset = false) => {
    const hasValidEntitlement = entitledDatasets.get(theDataset.id!);
    const noDatasetOrEntitlement = !ownDataset && !hasValidEntitlement;
    const isDisabled = noDatasetOrEntitlement || (ownDataset && !subscription?.active);

    return (
      <MenuItem
        key={theDataset.id}
        selected={selectedDataset === theDataset}
        onClick={() => handleMenuItemClick(theDataset)}
        disabled={isDisabled}
        sx={{
          '&.Mui-disabled': { 
            opacity: 1,
          },
        }}
      >
        <ListItemIcon
          sx={{
            visibility: selectedDataset === theDataset || noDatasetOrEntitlement ? 'visible' : 'hidden',
            marginRight: 0,
          }}
        >
          {noDatasetOrEntitlement ? (
            <img src={WarningImage} alt="alert" height={24} width={24} />
          ) : (
            <CheckIcon />
          )}
        </ListItemIcon>
        <ListItemText
          sx={{ 
            opacity: isDisabled ? 0.5 : 1,
          }}
          primary={
            <div className={classNames(classes.textEllipsis, classes.datasetNameWrapper)}>
              {theDataset.name}
            </div>
          }
          secondary={
            ownDataset
              ? null
              : noDatasetOrEntitlement && (
                <Typography variant="caption" className={classes.ownerName}>
                  (Owner subscription expired)
                </Typography>
              )
          }
          id={index === 0 ? 'my-account-1st-data-set' : ''}
        />
        {ownDataset && <ProfileAvatar datasetId={theDataset.id!} />}
      </MenuItem>
    );
  }, [entitledDatasets, subscription, handleMenuItemClick, classes]);

  return (
    <>
      <Button
        classes={labelStyle || { label: classes.labelStyle }}
        aria-owns={anchorEl ? 'dataset-menu' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        sx={{ 
          height: '100%', 
          textTransform: 'none', 
          fontSize: 16,
        }}
        id={id}
      >
        {label}
      </Button>
      <Menu
        id="dataset-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{ 
          zIndex: 1302,
        }}
      >
        {ownDatasets.size > 0 && (
          <Typography color="inherit" variant="subtitle1" className={classes.datasetTitle}>
            My Cloud Accounts
          </Typography>
        )}

        {subscription !== null && !subscription.active && (
          <SubscriptionAlert
            alertClass={classes.alert}
            label="Subscription expired. Shared Cloud Accounts are still available."
          />
        )}

        {Array.from(ownDatasets.values()).map((theDataset, index) => renderDatasets(theDataset, index, dataset, true))}

        {sharedDatasets.size > 0 && (
          <>
            <Typography color="inherit" variant="subtitle1" className={classes.datasetTitle}>
              Shared with me
            </Typography>
            {Array.from(sharedDatasets.values()).map((theDataset, index) => renderDatasets(theDataset, index, dataset, false))}
          </>
        )}
      </Menu>
    </>
  );
};

export default MiniDatasetPicker;
