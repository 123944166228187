
// BASE
import React, { useMemo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useToggle } from 'react-use';

import { makeStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';

import { showFlags } from 'companion-app-components/flux/preferences/selectors';

// DATA
import { useSelector } from 'react-redux';

// CUSTOM
import SPayeeField from 'components/SPayeeField';
import TagsField from 'components/QuickenControls/TagsField';
import CategoryField from 'components/QuickenControls/CategoryField';
import BoxField from 'components/Transactions/BoxField';
import FlagField from 'components/Transactions/FlagField';
import QButton from 'components/QButton';


const useStyles = makeStyles()((theme) => ({
  content: {
    width: 'min-content',
    height: 426,
    display: 'flex',
    borderBottom: `solid 1px ${theme.palette.divider}`,
  },
  footer: {
    height: 60,
    display: 'flex',
    padding: 24,
    alignItems: 'center',
    justifyContent: 'flex-end',
  },

  panelLeft: {
    flex: 0,
    minWidth: 296,
    padding: 24,
    paddingRight: 16,
    zIndex: 2,
    borderRight: `solid 1px ${theme.palette.divider}`,
    display: 'flex',
    flexDirection: 'column',
  },
  panelRight: {
    overflowY: 'auto',
    minWidth: 432,
    padding: 24,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },

  subHeader: {
    paddingTop: 8,
    paddingBottom: 22,
  },

  noOffset: {
    marginLeft: 0,
  },

  bottomPadding: {
    paddingBottom: 8,
  },
  tagsField: {
    width: 260,
    flexGrow: 0,
  },
}));
const buttonMargin = { marginLeft: 8 };

const BulkEditPopover = (props) => {

  const { classes } = useStyles();

  const { closeFn, saveFn } = props;

  const showFlag = useSelector(showFlags);

  const [editPayee, tPayee] = useToggle(false);
  const [editCategory, tCategory] = useToggle(false);
  const [editTags, tTags] = useToggle(false);
  const [editNote, tNote] = useToggle(false);
  const [editFlag, tFlag] = useToggle(false);

  const [payee, setPayee] = useState(null);
  const [category, setCategory] = useState(null);
  const [tags, setTags] = useState(null);
  const [note, setNote] = useState('');
  const [flag, setFlag] = useState(null);

  // seems unnecessary, but need to do this in order to avoid complaint from toggleFunction because
  //  formControl label calls it's onchange with an event object as an argument
  const togglePayee = useCallback(() => tPayee(), [tPayee]);
  const toggleCategory = useCallback(() => tCategory(), [tCategory]);
  const toggleTags = useCallback(() => tTags(), [tTags]);
  const toggleNote = useCallback(() => tNote(), [tNote]);
  const toggleFlag = useCallback(() => tFlag(), [tFlag]);

  const doSave = () => {
    const retObj = {};
    if (editPayee && payee && payee.trim().length > 0) {
      retObj.payee = payee;
    }
    if (editNote && note && (note === ' ' || note.trim().length > 0)) {
      retObj.memo = note === ' ' ? '' : note;
    }
    if (editCategory && category) {
      retObj.category = category;
    }
    // if (this.state.state && this.state.state.trim().length > 0) {
    //   retObj.state = this.state.state;
    // }
    if (editFlag) {
      retObj.userFlag = flag;
    }
    const newTags = tags && tags.filter((x) => x.id !== '0');
    if (editTags && newTags && newTags.size > 0) {
      retObj.tags = newTags;
    }
    // this.state.clearFields.forEach((item) => {
    //   switch (item) {
    //     case 'category':
    //       retObj[item] = { id: 0, type: 'UNCATEGORIZED' };
    //       break;
    //     case 'tags':
    //       retObj[item] = [];
    //       break;
    //     default:
    //       retObj[item] = ' ';
    //   }
    // });
    if (retObj.category) {
      retObj.coa = retObj.category;
    }
    // todo: look into why two connected transfers dont update payee and flags correctly

    saveFn(retObj);
    closeFn();
  };

  const changePayee = useCallback((_e, val) => {
    setPayee(val);
  }, []);


  const noOffset = useMemo(() => ({ root: classes.noOffset }), [classes.noOffset]);

  return (
    <Dialog
      open
      onClose={closeFn}
      fullWidth
      maxWidth="md"
      hideBackdrop
      disableEnforceFocus
    >
      <div className={classes.content}>
        <div className={classes.panelLeft}>
          <Typography variant="h6">
            Edit multiple transactions
          </Typography>

          <Typography variant="body2" color="textSecondary" className={classes.subHeader}>
            Choose what changes to apply to selected transactions
          </Typography>

          {/* Field Selection */}
          <FormControlLabel
            control={<Checkbox />}
            label="Payee name"
            labelPlacement="end"
            onChange={togglePayee}
            checked={editPayee}
            classes={noOffset}
          />
          <FormControlLabel
            control={<Checkbox />}
            label="Category"
            labelPlacement="end"
            onChange={toggleCategory}
            checked={editCategory}
            classes={noOffset}
          />
          <FormControlLabel
            control={<Checkbox />}
            label="Tags"
            labelPlacement="end"
            onChange={toggleTags}
            checked={editTags}
            classes={noOffset}
          />
          <FormControlLabel
            control={<Checkbox />}
            label="Memo"
            labelPlacement="end"
            onChange={toggleNote}
            checked={editNote}
            classes={noOffset}
          />

          {showFlag &&
          <FormControlLabel
            control={<Checkbox />}
            label="Follow-up flag"
            labelPlacement="end"
            onChange={toggleFlag}
            checked={editFlag}
            classes={noOffset}
          />}

        </div>

        <div className={classes.panelRight}>
          {/* Dynamic Fields */}

          {editPayee &&
          <SPayeeField
            defaultValue={payee}
            onChange={changePayee}
            popupIcon={null}
            id="bulk-payee-field"
            className={classes.bottomPadding}
          />}

          {editCategory &&
          <CategoryField
            margin="normal"
            textFieldVariant="outlined"
            variant="body1"
            label="Category"
            editable
            id="bulk-category-field"
            value={category}
            onChange={setCategory}
            width={260}
            classes={{ root: classes.bottomPadding }}
          />}

          {editTags &&
          <>
            <TagsField
              label="Tags"
              margin="normal"
              textFieldVariant="outlined"
              placeholder={null}
              id="bulk-tags-field"
              value={tags}
              onChange={setTags}
              className={classes.tagsField}
            />

            <Typography variant="caption" className={classes.bottomPadding}>
              These tags will be added (in addition to any already applied)
            </Typography>
          </>}

          {editNote &&
          <BoxField
            id="bulk-notes-field"
            label="Memo"
            placeholder="Add your memo here"
            value={note}
            onChange={setNote}
            className={editFlag ? classes.bottomPadding : undefined}
          />}

          {editFlag &&
          <FlagField
            value={flag}
            onChange={(val) => setFlag(val)}
          />}

        </div>
      </div>

      <div className={classes.footer}>
        <QButton
          id="bulk-edit-cancel"
          onClick={closeFn}
        >
          Cancel
        </QButton>

        <QButton
          id="bulk-edit-apply"
          onClick={doSave}
          variant="contained"
          style={buttonMargin}
        >
          Apply
        </QButton>
      </div>
    </Dialog>
  );

};

BulkEditPopover.propTypes = {
  closeFn: PropTypes.func,
  saveFn: PropTypes.func,
  // txnArray: PropTypes.object,  // immutable Set()
};

export default BulkEditPopover;
