import React, { Fragment, useState, useMemo, useEffect, useCallback } from 'react';
import { DateTime } from 'luxon';
import { isEqual } from 'lodash';
import { makeStyles } from 'tss-react/mui';
import { useSelector, useDispatch } from 'react-redux';

import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import FormGroup from '@mui/material/FormGroup';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import TableContainer from '@mui/material/TableContainer';
import LinearProgress from '@mui/material/LinearProgress';
import FormControlLabel from '@mui/material/FormControlLabel';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { authSelectors } from 'companion-app-components/flux/auth';
import type RootState from 'companion-app-components/utils/redux-store/rootState';
import { featureFlagsSelectors } from 'companion-app-components/flux/feature-flags';
import { budgetItemsActions, budgetItemsSelectors } from 'companion-app-components/flux/budget-items';
import { BudgetTreeNodeProps, BudgetRollOverProps } from 'companion-app-components/flux/budgets/budgetsTypes';
import { getAnnualBudgetTreeNodes, getTotalValuesForAnnualBudget } from 'companion-app-components/flux/budgets/budgetsSelectors';

import QPopper from 'components/QPopper';
import { formatNumber } from 'components/QuickenControls/AmountField';
import ZeroStateView from 'components/ZeroStateView';
import zeroStateIcon from 'assets/zero-state-images/budgets.svg';
import { getFetchInProgress } from 'data/app/selectors';
import { showIndividualCategoryAnnualBudget } from 'components/Dialogs/BudgetV2/IndividualCategoryAnnualBudget/actions';
import { showYearlyBudgetEdit } from 'components/Dialogs/BudgetV2/YearlyBudgetEdit/actions';
import {
  updateMonthlyBudgetAmount,
  processDataToUpdateBudgetItems,
} from 'components/Dialogs/BudgetV2/YearlyBudgetEdit/utils';
import AnnualViewCategoryRow from './AnnualViewCategoryRow';
import RolloverBalanceCalculationView from './RolloverBalanceCalculationView';
import styles, { border, fontWeight, StickyTableCell, StickyTableHead } from './styles';
import {
  BUDGET_ANNUAL_SUBVIEWS,
  BUDGET_STICKY_TOP_HEIGHT,
  BUDGET_ANNUAL_INVISIBLE_AMOUNT_SYMBOL,
} from '../../constants';
import {
  getProgressColor,
  getMonthlyCellSxProps,
  getIterableMonthCells,
  getIterableCells,
  getAmountColor,
  getHighlightColor,
  hideToDateColumn,
  generateEditOptionsForAnnualTable,
  generateMonthCopyOptions,
  validateAmount9Digits2Decimals,
  hideBalanceViewFutureAmount,
  hideDetailsViewCells,
  getBudgetViewStateObject,
  generateRolloverBalanceCalculationTable,
  getKeyFutureMonthsVisibility,
  generateRolloverAmount,
} from '../../utils';
import { useBudgetOptions } from '../../hooks';

/* eslint react/prop-types: 0 */

let initializingView: boolean = true;
const useStyles = makeStyles()(styles as Record<string, any>);
const currentMonth: number = DateTime.local().month;
const currentYear: number = DateTime.local().year;
const detailsViewKey: string = BUDGET_ANNUAL_SUBVIEWS.details.key;
const viewsOptions = Object.values(BUDGET_ANNUAL_SUBVIEWS).filter(
  (subView) => subView.key !== detailsViewKey,
);
const boolShowMonthOptions = (showMonthOptions: boolean, monthNumber: number | string): boolean =>
  showMonthOptions && monthNumber !== -1;
let budgetItemOfSelectedDate;
let isIncomeForDialog: boolean | undefined;
let rolloverAmountForDialog: number = 0;

interface IAnnualViewTableProps {
  budget: Record<string, any>;
  budgetUiState: Record<string, any>;
  setBudgetUiState: (obj: Record<string, any>) => void;
  updateBudgetCalcAndItems: () => void;
}

const AnnualViewTable: React.FC<IAnnualViewTableProps> = ({ budget, budgetUiState, setBudgetUiState, updateBudgetCalcAndItems }) => {
  const { classes } = useStyles();
  const { palette, components }: any = useTheme();
  const { isToDateColumnEnabled, annualSubView, setDatasetPreference, viewState } = useBudgetOptions(budget.id);
  const amountFormat = budget.showCents ? '0,00.00' : '0,0';
  const colorLightBlue = palette.blue7;
  const colorLightGray = palette.gray5;
  const colorActual = palette.blue1;
  const colorBalance = palette.red1;
  const colorGreen = components.barChart.primaryColor;
  const colorSelectedRow = palette.grey.level1;

  const dispatch = useDispatch();
  const featureFlags = useSelector((state: RootState) => featureFlagsSelectors.getFeatureFlags(state));
  const budgetItemsLoadingState = useSelector(budgetItemsSelectors.getIsLoading);
  const enableBudgetRolloverEdit = featureFlags.get('budgetAnnualRolloverEdit');
  const enableBudgetCategoryGraph = featureFlags.get('budgetAnnualCategoryGraph');
  const enableBudgetCategoryEdit = featureFlags.get('budgetAnnualCategoryEdit');
  const dispatchUpdateBudgetItem = useCallback((data) => dispatch(budgetItemsActions.updateBudgetItem(data)), [dispatch]);
  const dispatchUpdateBudgetItems = useCallback((data) =>
    dispatch(budgetItemsActions.updateBudgetItemsAction(data)), [dispatch]);
  const [hasAmountChanged, setHasAmountChanged] = useState(false);
  const [hasAmountSaved, setHasAmountSaved] = useState(false);
  const [savedAmount, setSavedAmount] = useState(null);
  const objAnnualBudgetData = useSelector((state: RootState) => getAnnualBudgetTreeNodes(state, budget.id));
  const isQWinDataset = useSelector((state: RootState) => authSelectors.getIsWinDataset(state));
  const fetchInProgress = useSelector(getFetchInProgress);
  const annualBudgetData = objAnnualBudgetData.nodes;
  const { budgetStartDate = DateTime.now().startOf('year') } = objAnnualBudgetData;
  const budgetStartMonth = budgetStartDate?.month;
  const budgetStartYear = budgetStartDate?.year;
  const budgetEnding = budgetStartDate.plus({ month: 11 });
  const budgetEndMonth = budgetEnding.month;
  const budgetEndYear = budgetEnding.year;
  const annualTotalsData = useSelector(() => getTotalValuesForAnnualBudget(annualBudgetData));
  const [budgetAnnualData, setBudgetAnnualData] = useState(annualBudgetData);
  const summaryTotalAmount = annualTotalsData.summary[BUDGET_ANNUAL_SUBVIEWS[annualSubView].amountKey];
  const isDetailsView = annualSubView === detailsViewKey;
  const iterableMonths = useMemo(() => getIterableMonthCells(
    budgetStartMonth, currentMonth, budgetEndMonth, budgetStartYear, budgetEndYear, isToDateColumnEnabled,
  ), [budgetStartMonth, budgetEndMonth, isToDateColumnEnabled, budgetStartYear, budgetEndYear]);
  
  const [anchorEl, setAnchorEl] = useState(null);
  const [showPopper, setShowPopper] = useState(false);
  const [popperData, setPopperData] = useState({});
  const [anchorGearIcon, setAnchorGearIcon] = useState<HTMLElement | null>(null);
  const openBudgetItemEditMenu = Boolean(anchorGearIcon);
  const [editedRolloverAmount, setEditedRolloverAmount] = useState<number | null>(null);
  const [hasSentRolloverCalc, setHasSentRolloverCalc] = useState(false);

  const rolloverTableData = useMemo(() => generateRolloverBalanceCalculationTable(popperData), [popperData]);

  const showMonthOptions = (isDetailsView || annualSubView === BUDGET_ANNUAL_SUBVIEWS.budget.key);
  const [anchorMonthBox, setAnchorMonthBox] = useState(null);
  const openBudgetMonthOptions = Boolean(anchorMonthBox) && showMonthOptions;
  const [monthClicked, setMonthClicked] = useState<DateTime | null>(null);
  const showFutureMonthDetails = viewState[getKeyFutureMonthsVisibility(annualSubView)] ?? budget.showBalanceInFutureMonths;

  const iterableCells = useMemo(() => getIterableCells(
    annualTotalsData.monthlyTotal,
    budgetEndMonth,
    currentMonth,
    currentYear,
    annualSubView,
    showFutureMonthDetails,
    isDetailsView,
    isToDateColumnEnabled,
  ), [annualTotalsData.monthlyTotal, budgetEndMonth, isDetailsView, annualSubView, isToDateColumnEnabled, showFutureMonthDetails]);

  const allCategoryIdsOfBudget = useMemo(() => budgetAnnualData.reduce((accumulator, category) => [...accumulator, category.id], []), [budgetAnnualData]);

  const hidePopperAndContents = () => {
    setShowPopper(false);
    setAnchorEl(null);
    setPopperData({});
    setEditedRolloverAmount(null);
    budgetItemOfSelectedDate = undefined;
    isIncomeForDialog = undefined;
    rolloverAmountForDialog = 0;
  };

  const handleCheckShowFutureMonthDetails = () => {
    setDatasetPreference(getBudgetViewStateObject(
      budget.id,
      budget.name,
      viewState.viewType,
      annualSubView,
      showFutureMonthDetails,
      true,
    ));
  };

  const handleParentCategoryToggle = (clickedBudgetItem, evt) => {
    evt.stopPropagation();
    hidePopperAndContents();
    const budgetAnnualDataUpdated = budgetAnnualData.map((budgetItem) => {
      if (clickedBudgetItem.key === budgetItem.key) {
        return { ...budgetItem, isSelfExpanded: !clickedBudgetItem.isSelfExpanded };
      }
      if (clickedBudgetItem?.childrenKeys?.includes(budgetItem.key)) {
        const parentItem = budgetAnnualData.find((budItem) => budgetItem.parentId === budItem.id);
        // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
        return clickedBudgetItem.key !== parentItem?.key && !parentItem?.isSelfExpanded && parentItem?.depth! > 0
          ? budgetItem
          : { ...budgetItem, isVisible: !clickedBudgetItem.isSelfExpanded };
      }

      return budgetItem;
    });
    setBudgetAnnualData(budgetAnnualDataUpdated);
  };

  const handleBudgetItemSelect = (clickedBudgetItem, evt) => {
    if (budgetUiState?.selectedAnnualBudgetItem?.key === clickedBudgetItem?.key) { return; }

    evt.stopPropagation();
    hidePopperAndContents();
    const budgetAnnualDataUpdated = budgetAnnualData.map((budgetItem) => budgetItem.key === clickedBudgetItem.key
      ? { ...budgetItem, isSelected: true }
      : { ...budgetItem, isSelected: false });
    setBudgetAnnualData(budgetAnnualDataUpdated);

    const trBudgetItemRow = evt.target.closest('tr');
    setTimeout(() => {
      // focus the first input element in table row if not other input selected
      const inputElems = trBudgetItemRow.querySelectorAll('input');
      [...inputElems].every((inputElem, idx) => {
        if (inputElem === evt.target) {
          inputElems[idx].focus();
          return false;
        }
        inputElems[0].focus();
        return true;
      });
      setBudgetUiState({ selectedAnnualBudgetItem: clickedBudgetItem });
      // focus the selected input column
      if (evt.target.dataset?.inputNumber) {
        inputElems[Number(evt.target.dataset?.inputNumber) - 1]?.focus?.();
      }
    }, 200);
  };

  const handleBudgetItemGraphClick = (budgetItemId, clickedMonthDate = null, isEverythingElse = false, displayLabel = '') => {
    dispatch(showIndividualCategoryAnnualBudget(budget.id, budgetItemId, clickedMonthDate, allCategoryIdsOfBudget, isEverythingElse, displayLabel));
  };

  const handleBudgetItemMenuClick = (evt) => setAnchorGearIcon(evt.currentTarget);

  const handleBudgetItemMenuClose = () => setAnchorGearIcon(null);

  const handleMonthBoxOpen = (objMonthYear, evt) => {
    if (!showMonthOptions) { return; }
    setMonthClicked({ ...objMonthYear });
    setAnchorMonthBox(evt.currentTarget);
  };

  const handleMonthBoxClose = () => setAnchorMonthBox(null);

  const handleEditYearlyAction = useCallback(() => {
    handleBudgetItemMenuClose();
    dispatch(showYearlyBudgetEdit(budgetUiState?.selectedAnnualBudgetItem, budget.id, setHasAmountSaved, false, false, allCategoryIdsOfBudget, budgetUiState?.selectedMonthDate));
  }, [dispatch, budgetUiState, budget, allCategoryIdsOfBudget]);

  // TODO: hide calculate average feature
  // const handleCalculateAverageAction = useCallback(() => {
  //   handleBudgetItemMenuClose();
  //   dispatch(showYearlyBudgetEdit(budgetUiState?.selectedAnnualBudgetItem, budget.id, setHasAmountSaved, true, true, allCategoryIdsOfBudget, budgetUiState?.selectedMonthDate));
  // }, [dispatch, budgetUiState, budget, allCategoryIdsOfBudget]);

  const handleBudgetItemAmountFocus = (objMonthlyAmounts, evt) => {
    evt.target.select();
    setTimeout(() => {
      setSavedAmount(evt.target.value);
      setBudgetUiState({ selectedMonthDate: objMonthlyAmounts.startDate });
    }, 100);
  };

  const handleBudgetItemAmountChange = (arrMonthlyAmounts, setArrMonthlyAmounts, evt) => {
    const { selectedMonthDate } = budgetUiState;
    const currentValue = evt.target.value;
    if (!validateAmount9Digits2Decimals(currentValue) || !selectedMonthDate) { return; }

    if (!hasAmountChanged && savedAmount !== currentValue) {
      setHasAmountChanged(true);
    }
    const updatedMonthlyAmounts = arrMonthlyAmounts.map((objMonthlyAmounts) => {
      if (objMonthlyAmounts.startDate.month === selectedMonthDate.month && !objMonthlyAmounts.isToDate) {
        return {
          ...objMonthlyAmounts,
          budgetAmount: currentValue,
        };
      }
      return objMonthlyAmounts;
    });
    setArrMonthlyAmounts(updatedMonthlyAmounts);
  };

  const handleRolloverAmountSave = useCallback((selectedBudgetItem, objMonthlyAmounts, calculatedBalance) => {
    const changedBudgetItem = selectedBudgetItem.budgetItems.find((budgetItem) => budgetItem.id === objMonthlyAmounts.budgetItemId);
    let editedRolloverAmountToSave = Number(editedRolloverAmount) || 0;
    if (!isIncomeForDialog) {
      editedRolloverAmountToSave *= -1;
    }
    if (changedBudgetItem?.id) {
      dispatchUpdateBudgetItem({
        id: changedBudgetItem.id,
        rolloverOverrideAmount: editedRolloverAmountToSave,
      });
      if (selectedBudgetItem?.rolloverType) {
        const newBudgetData = annualBudgetData.map((objBudgetItem, idx) => {
          if (objBudgetItem.key === selectedBudgetItem.key) {
            return {
              ...objBudgetItem,
              isSelected: budgetAnnualData[idx].isSelected,
              balanceAmount: calculatedBalance,
            };
          }
          return objBudgetItem;
        });
        setBudgetAnnualData(newBudgetData);
        setTimeout(() => setHasAmountSaved(true), 2500);
      }
      setSavedAmount(null);
      setHasAmountChanged(false);
      setHasAmountSaved(true);
      hidePopperAndContents();
    }
  }, [dispatchUpdateBudgetItem, editedRolloverAmount, budgetAnnualData, annualBudgetData]);

  const handleBudgetItemAmountSave = (selectedBudgetItem, objMonthlyAmounts) => {
    const changedBudgetItem = selectedBudgetItem.budgetItems.find((budgetItem) => budgetItem.id === objMonthlyAmounts.budgetItemId);
    if (hasAmountChanged && changedBudgetItem?.id) {
      const changedAmount = changedBudgetItem?.isIncome ? objMonthlyAmounts.budgetAmount : -1 * objMonthlyAmounts.budgetAmount;
      dispatchUpdateBudgetItem({
        id: changedBudgetItem.id,
        amount: +changedAmount,
      });
    }
    if (!hasAmountChanged) { return; }
    if (budgetUiState?.selectedAnnualBudgetItem?.rolloverType) {
      const newBudgetData = annualBudgetData.map((objBudgetItem, idx) => {
        if (objBudgetItem.key === budgetUiState.selectedAnnualBudgetItem.key) {
          return { ...objBudgetItem, isSelected: budgetAnnualData[idx].isSelected };
        }
        return objBudgetItem;
      });
      setBudgetAnnualData(newBudgetData);
      setTimeout(() => setHasAmountSaved(true), 2000);
    }
    
    setSavedAmount(null);
    setHasAmountChanged(false);
    setHasAmountSaved(true);
    if (budgetUiState.selectedAnnualBudgetItem?.key !== selectedBudgetItem.key) {
      setBudgetUiState({ selectedAnnualBudgetItem: null });
    }
    
    const { rolloverType } = changedBudgetItem;
    const isRollover = rolloverType === BudgetRollOverProps.ROLLOVER_POSITIVE || rolloverType === BudgetRollOverProps.ROLLOVER_ALL;
    if (isRollover) { setHasSentRolloverCalc(true); }
  };

  const handleMonthCopyAction = (option: string) => {
    handleMonthBoxClose();
    if (!monthClicked) { return; }
    const selectedMonthDate = DateTime.fromFormat(`${monthClicked.month}-${monthClicked.year}`, 'M-yyyy').startOf('month');
    const arrBudgetItemsToUpdate = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const objBudgetItem of annualBudgetData) {
      if (objBudgetItem.hasChildren) { continue; } // eslint-disable-line no-continue
      const { monthlyAmounts = [], budgetItems, negate } = objBudgetItem;
      let budgetAmountToUpdate = 0;
      monthlyAmounts.forEach((monthlyItem) => {
        const selectedMonthDateCloned = selectedMonthDate.month ? selectedMonthDate : DateTime.fromISO(`${selectedMonthDate}`);
        if (!monthlyItem.isToDate && DateTime.fromISO(monthlyItem.startDate as unknown as string).month === selectedMonthDateCloned.month) {
          budgetAmountToUpdate = monthlyItem.budgetAmount;
        }
      });
      const updatedBudgetItems = updateMonthlyBudgetAmount(
        option, 
        JSON.parse(JSON.stringify(objBudgetItem)), 
        budgetAmountToUpdate, 
        selectedMonthDate,
      );
      const budgetItemsToUpdate: [] = processDataToUpdateBudgetItems(updatedBudgetItems.budgetItems, budgetItems, negate);
      if (budgetItemsToUpdate.length > 0) {
        arrBudgetItemsToUpdate.push(...budgetItemsToUpdate);
      }
    }
    if (arrBudgetItemsToUpdate.length > 0) {
      dispatchUpdateBudgetItems(arrBudgetItemsToUpdate);
    }
    setHasAmountSaved(true);
  };

  const handleApplyAction = useCallback((option: string) => {
    handleBudgetItemMenuClose();
    const { selectedAnnualBudgetItem = {}, selectedMonthDate = DateTime.now() } = budgetUiState;
    const { monthlyAmounts = [], budgetItems, negate } = selectedAnnualBudgetItem;
    let budgetAmountToUpdate = 0;
    monthlyAmounts.forEach((monthlyItem) => {
      const selectedMonthDateCloned = selectedMonthDate.month ? selectedMonthDate : DateTime.fromISO(selectedMonthDate);
      if (!monthlyItem.isToDate && DateTime.fromISO(monthlyItem.startDate).month === selectedMonthDateCloned.month) {
        budgetAmountToUpdate = monthlyItem.budgetAmount;
      }
    });
    const updatedBudgetItems = updateMonthlyBudgetAmount(option, JSON.parse(JSON.stringify(selectedAnnualBudgetItem)), budgetAmountToUpdate, selectedMonthDate);
    const budgetItemsToUpdate = processDataToUpdateBudgetItems(updatedBudgetItems.budgetItems, budgetItems, negate);
    if (budgetItemsToUpdate.length) {
      dispatchUpdateBudgetItems(budgetItemsToUpdate);
      setHasAmountSaved(true);
    }
  }, [budgetUiState, dispatchUpdateBudgetItems]);

  const isSameData = isEqual(budgetAnnualData, annualBudgetData);

  const editOptions = useMemo(
    () => generateEditOptionsForAnnualTable(
      budgetUiState?.selectedMonthDate || DateTime.now(),
      budgetStartYear,
      budgetEndYear,
      handleApplyAction,
      handleEditYearlyAction,
      // TODO: hide calculate average feature
      // handleCalculateAverageAction,
    ),
    [budgetUiState, budgetStartYear, budgetEndYear, handleApplyAction, handleEditYearlyAction],
  );

  const monthCopyOptions = useMemo(
    () => generateMonthCopyOptions(
      monthClicked,
      budgetStartMonth,
      budgetEndMonth,
      budgetStartYear,
      budgetEndYear,
      handleMonthCopyAction,
    ),
    [monthClicked, budgetEndYear], // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => (() => {
    budgetItemOfSelectedDate = undefined;
    isIncomeForDialog = undefined;
    rolloverAmountForDialog = 0;
  }), []);
  
  useEffect(() => {
    if (isSameData) { return; }
    setBudgetAnnualData(annualBudgetData);
  }, [budget.id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!fetchInProgress && !budgetItemsLoadingState) {
      setBudgetAnnualData(annualBudgetData);
      setHasAmountSaved(true);
    }
  }, [fetchInProgress, budgetItemsLoadingState]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!hasAmountSaved) { return; }
    const refreshData = async () => {
      let newBudgetItemRecord: BudgetTreeNodeProps | null = null;
      const newBudgetAnnualData = objAnnualBudgetData.nodes.map((budgetItem) => {
        if (budgetItem?.key === budgetUiState.selectedAnnualBudgetItem?.key) {
          newBudgetItemRecord = budgetItem;
          return { ...budgetItem, isSelected: true };
        }
        return budgetItem;
      });
      setBudgetAnnualData(newBudgetAnnualData);
      setBudgetUiState({ selectedAnnualBudgetItem: newBudgetItemRecord });
    };
    refreshData();
    setHasAmountSaved(false);
  }, [hasAmountSaved]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!initializingView) { return; }
    updateBudgetCalcAndItems();
    initializingView = false;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!hasSentRolloverCalc) { return; }
    updateBudgetCalcAndItems();
    setHasSentRolloverCalc(false);
  }, [hasSentRolloverCalc]); // eslint-disable-line react-hooks/exhaustive-deps
  

  const setPopperAndContents = (refElement, popoverContents, evt) => {
    evt.stopPropagation();
    setShowPopper(true);
    setAnchorEl(refElement);
    setPopperData(popoverContents);
    const selectedDate = DateTime.fromISO(popoverContents?.objMonthlyAmounts?.startDate);
    budgetItemOfSelectedDate = popoverContents?.budgetItem?.budgetItems?.find((budgetItem) => DateTime.fromISO(budgetItem.startDate).month === selectedDate.month);
    isIncomeForDialog = popoverContents?.budgetItem?.negate > 0;
    rolloverAmountForDialog = generateRolloverAmount(budgetItemOfSelectedDate);
    setEditedRolloverAmount(rolloverAmountForDialog);
  };

  if (budgetAnnualData.length === 0) {
    return <ZeroStateView
      style={{ marginTop: 120 }}
      primary="No Data"
      icon={zeroStateIcon}
      secondary="We can't fetch your data from the server."
    />;
  }
  return (
    <Paper sx={{ p: 2 }}>
      <TableContainer className={classes.tableContainer}>
        <Table stickyHeader aria-label="annual budgets table">
          <TableHead>
            <TableRow key="months-row">
              <StickyTableHead
                key="months-row-blank-cell"
                sx={{ border, borderBottom: 'none', top: 0 }}
              />
              {iterableMonths.map(({ number, name, shortName, year = budgetStartYear }) => {
                const isFutureCellHidden = hideDetailsViewCells(
                  showFutureMonthDetails,
                  { isToDate: number === -1, startDate: { month: number, year } },
                  annualSubView,
                  currentMonth,
                  currentYear,
                );
                return (
                  <TableCell
                    key={name}
                    colSpan={isDetailsView && number !== -1 && !isFutureCellHidden ? 3 : 0}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    sx={{
                      p: 0,
                      border: border || 'none',
                      backgroundColor: getHighlightColor(number, currentMonth, currentYear, budgetEndYear, colorLightBlue),
                      ...(boolShowMonthOptions(showMonthOptions, number) && { cursor: 'pointer' }),
                    }}
                    align="center"
                  >
                    <Box
                      sx={{ display: 'flex', justifyContent: 'center' }}
                      onClick={(evt) => handleMonthBoxOpen({ month: number, year }, evt)}
                    >
                      <Typography variant="subtitle2">
                        {isDetailsView && !isFutureCellHidden ? name : shortName}
                      </Typography>
                      {boolShowMonthOptions(showMonthOptions, number) && <ArrowDropDownIcon />}
                    </Box>
                  </TableCell>
                );
              })}
              <TableCell
                key="months-row-year-cell"
                sx={{ py: 0, border }}
                align="center"
              >
                <Typography variant="subtitle2" sx={{ whiteSpace: 'nowrap' }}>
                  {budgetEndYear}{budgetStartYear !== budgetEndYear && ' (FY)'}
                  <br />Summary
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow key="months-amountType-row">
              <StickyTableHead
                key="months-amountType-row-blank-cell"
                sx={{ border, borderTop: 'none', borderBottom: 'none', top: BUDGET_STICKY_TOP_HEIGHT.rowAmountType }}
              />
              {iterableMonths.map(({ number, name, year }) => {
                const isFutureCellHidden = hideDetailsViewCells(
                  showFutureMonthDetails,
                  { isToDate: number === -1, startDate: { month: number, year: year || budgetEndYear } },
                  annualSubView,
                  currentMonth,
                  currentYear,
                );
                return isDetailsView && number !== -1 && !isFutureCellHidden ? (
                  <Fragment key={name}>
                    {viewsOptions.map((subView) => (
                      <TableCell
                        key={subView.columnLabel}
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        sx={{
                          py: 0,
                          border,
                          backgroundColor: getHighlightColor(number, currentMonth, currentYear, budgetEndYear, colorLightBlue),
                          top: BUDGET_STICKY_TOP_HEIGHT.rowAmountType,
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                        }}
                        align="center"
                      >
                        <Typography variant="overline" sx={{ whiteSpace: 'nowrap' }}>
                          {subView.columnLabel}
                        </Typography>
                      </TableCell>
                    ))}
                  </Fragment>
                ) : (
                  <TableCell
                    key={name}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    sx={{
                      py: 0,
                      border,
                      whiteSpace: 'nowrap',
                      backgroundColor: getHighlightColor(number, currentMonth, currentYear, budgetEndYear, colorLightBlue),
                      top: BUDGET_STICKY_TOP_HEIGHT.rowAmountType,
                    }}
                    align="center"
                  >
                    <Typography variant="overline">
                      {isFutureCellHidden 
                        ? BUDGET_ANNUAL_SUBVIEWS.budget.columnLabel 
                        : BUDGET_ANNUAL_SUBVIEWS[annualSubView].columnLabel}
                    </Typography>
                  </TableCell>
                );
              })}
              <TableCell
                key="months-amountType-row-summary-cell"
                sx={{ py: 0, top: BUDGET_STICKY_TOP_HEIGHT.rowAmountType }}
                align="center"
              >
                <Typography variant="overline">
                  {BUDGET_ANNUAL_SUBVIEWS[annualSubView].columnLabel}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow key="bar-row">
              <StickyTableHead
                key="bar-row-cell-blank"
                sx={{ p: 0, border, borderTop: 'none', top: BUDGET_STICKY_TOP_HEIGHT.rowProgressBar }}
              />
              {annualTotalsData.monthlyTotal.map((objMonthlyAmounts) => {
                if (hideToDateColumn(isToDateColumnEnabled, objMonthlyAmounts.isToDate, currentMonth, budgetEndMonth)) {
                  return null;
                }

                const getMonth = objMonthlyAmounts.startDate.get('month');
                const getYear = objMonthlyAmounts.startDate.get('year');
                const progress = getYear < currentYear
                  || (getMonth <= currentMonth && getYear <= currentYear) ? 100 : 0;
                const suffixKey = objMonthlyAmounts.isToDate ? 'toDate' : `${getMonth}-${getYear}`;
                const isFutureCellHidden = hideDetailsViewCells(
                  showFutureMonthDetails,
                  objMonthlyAmounts,
                  annualSubView,
                  currentMonth,
                  currentYear,
                );

                return (
                  <TableCell
                    sx={{ border, p: 0, top: BUDGET_STICKY_TOP_HEIGHT.rowProgressBar }}
                    colSpan={isDetailsView && !objMonthlyAmounts.isToDate && !isFutureCellHidden ? 3 : 0}
                    key={`bar-row-cell-${suffixKey}`}
                  >
                    <LinearProgress
                      variant="determinate"
                      value={progress}
                      sx={{
                        height: 7,
                        backgroundColor: colorLightGray,
                        '& .MuiLinearProgress-barColorPrimary': {
                          backgroundColor: getProgressColor(objMonthlyAmounts.balanceAmount, colorBalance, colorGreen),
                        },
                      }}
                    />
                  </TableCell>
                );
              })}
              <TableCell key="bar-row-cell-summary" sx={{ p: 0, border, top: BUDGET_STICKY_TOP_HEIGHT.rowProgressBar }}>
                <LinearProgress
                  variant="determinate"
                  value={budgetEndYear < currentYear
                    || (budgetEndYear <= currentYear && budgetEndMonth <= currentMonth) ? 100 : 0}
                  sx={{
                    height: 7,
                    backgroundColor: colorLightGray,
                    '& .MuiLinearProgress-barColorPrimary': {
                      // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
                      backgroundColor: getProgressColor(annualTotalsData?.summary?.balanceAmount!, colorBalance, colorGreen),
                    },
                  }}
                />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {budgetAnnualData.map((budgetItem) => budgetItem.isVisible && (
              <AnnualViewCategoryRow
                key={`${budget.id}-${budgetItem.key}-${budgetItem.uuid}`}
                budget={budget}
                budgetItem={budgetItem}
                detailsViewKey={detailsViewKey}
                annualSubView={annualSubView}
                handleParentCategoryToggle={handleParentCategoryToggle}
                budgetEndYear={budgetEndYear}
                budgetEndMonth={budgetEndMonth}
                colorGreen={colorGreen}
                colorLightBlue={colorLightBlue}
                colorActual={colorActual}
                colorBalance={colorBalance}
                isToDateColumnEnabled={isToDateColumnEnabled}
                amountFormat={amountFormat}
                colorSelectedRow={colorSelectedRow}
                handleBudgetItemSelect={handleBudgetItemSelect}
                handleBudgetItemGraphClick={handleBudgetItemGraphClick}
                handleBudgetItemMenuClick={handleBudgetItemMenuClick}
                handleBudgetItemAmountFocus={handleBudgetItemAmountFocus}
                handleBudgetItemAmountChange={handleBudgetItemAmountChange}
                handleBudgetItemAmountSave={handleBudgetItemAmountSave}
                setPopperAndContents={setPopperAndContents}
                isQWinDataset={isQWinDataset}
                showFutureMonthDetails={showFutureMonthDetails}
                enableBudgetCategoryGraph={enableBudgetCategoryGraph}
                enableBudgetCategoryEdit={enableBudgetCategoryEdit}
              />
            ))}
            <TableRow key="total-spacer-row">
              <StickyTableCell
                key="totals-spacer-row-blank-cell-sticky"
                sx={{ height: 216 }}
              />
              {iterableCells.map(({ startDate, isToDate }, idx) => {
                if (!isToDateColumnEnabled && isToDate) { return null; }

                return (
                  <TableCell
                    // eslint-disable-next-line react/no-array-index-key
                    key={`totals-spacer-row-blank-cell-${startDate.month}-${idx}`}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    sx={{
                      border,
                      backgroundColor: getHighlightColor(startDate.month, currentMonth, currentYear, budgetEndYear, colorLightBlue, isToDate),
                    }}
                  />
                );
              })}
              <TableCell key="totals-spacer-row-blank-cell-last" sx={{ border }} />
            </TableRow>
            <TableRow key="totals">
              <StickyTableCell align="right" key="totals-row-cell" sx={{ bottom: 0 }}>
                <Typography variant="subtitle2">Totals</Typography>
              </StickyTableCell>
              {iterableCells.map((objMonthlyAmounts, idx) => {
                if (!isToDateColumnEnabled && objMonthlyAmounts.isToDate) { return null; }

                const amount = isDetailsView && !objMonthlyAmounts.isToDate
                  ? objMonthlyAmounts.amount
                  : objMonthlyAmounts[BUDGET_ANNUAL_SUBVIEWS[annualSubView].amountKey];
                const objTotalCellSxProps = getMonthlyCellSxProps(
                  objMonthlyAmounts.startDate,
                  currentMonth,
                  currentYear,
                  budgetEndYear,
                  colorLightBlue,
                  border,
                  objMonthlyAmounts.isToDate,
                );
                return (
                  <TableCell
                    // eslint-disable-next-line react/no-array-index-key
                    key={`totals-row-cell-${objMonthlyAmounts.startDate.month}-${idx}`}
                    align="right"
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    sx={{
                      ...objTotalCellSxProps,
                      ...((objMonthlyAmounts?.isBalanceAmount || objMonthlyAmounts.isToDate || annualSubView === BUDGET_ANNUAL_SUBVIEWS.balance.key) && { fontWeight }),
                      color: getAmountColor(annualSubView, amount, colorActual, colorBalance, objMonthlyAmounts?.isBalanceAmount, objMonthlyAmounts?.isToDate, true),
                      fontWeight,
                      pr: 1,
                      position: 'sticky',
                      bottom: 0,
                      ...((objMonthlyAmounts.startDate.month !== currentMonth || objMonthlyAmounts.isToDate) 
                        && { backgroundColor: 'common.white' }),
                    }}
                  >
                    {hideBalanceViewFutureAmount(showFutureMonthDetails, objMonthlyAmounts, annualSubView, currentMonth, currentYear, budgetEndMonth, budgetEndYear)
                      ? BUDGET_ANNUAL_INVISIBLE_AMOUNT_SYMBOL
                      : formatNumber(amount, false, amountFormat)}
                  </TableCell>
                );
              })}
              <TableCell
                key="totals-row-cell-summary-amount"
                sx={{
                  border,
                  p: 0,
                  pr: 1,
                  fontWeight,
                  ...(summaryTotalAmount < 0 && { color: colorBalance }),
                  backgroundColor: 'common.white',
                  position: 'sticky',
                  bottom: 0,
                }}
                align="right"
              >
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/* @ts-ignore */}
                {hideBalanceViewFutureAmount(showFutureMonthDetails, {}, annualSubView, currentMonth, currentYear, budgetEndMonth, budgetEndYear, true)
                  ? BUDGET_ANNUAL_INVISIBLE_AMOUNT_SYMBOL
                  : formatNumber(summaryTotalAmount, false, amountFormat)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      {[BUDGET_ANNUAL_SUBVIEWS.details.key, BUDGET_ANNUAL_SUBVIEWS.balance.key].includes(annualSubView) && (
        <FormGroup>
          <FormControlLabel
            control={(
              <Checkbox
                checked={showFutureMonthDetails}
                onChange={handleCheckShowFutureMonthDetails}
              />
            )}
            label={`Show ${isDetailsView ? 'detail for' : 'balance in'} future months`}
            sx={{ alignSelf: 'flex-end' }}
          />
        </FormGroup>
      )}
      <QPopper
        open={showPopper}
        anchorEl={anchorEl}
        placement="top"
        disablePortal
        transition
        transitionEffect="fade"
        greyscaled
        onClickAway={hidePopperAndContents}
      >
        <RolloverBalanceCalculationView 
          {...popperData}  
          hidePopperAndContents={hidePopperAndContents}
          handleRolloverAmountSave={handleRolloverAmountSave}
          rolloverAmountForDialog={rolloverAmountForDialog}
          rolloverTableData={rolloverTableData}
          editedRolloverAmount={editedRolloverAmount}
          setEditedRolloverAmount={(amount) => setEditedRolloverAmount(amount)}
          colorBalance={colorBalance}
          enableBudgetRolloverEdit={enableBudgetRolloverEdit}
        />
      </QPopper>
      {anchorGearIcon && anchorGearIcon?.getBoundingClientRect().x !== 0 && (
        <Menu
          anchorEl={anchorGearIcon}
          open={openBudgetItemEditMenu}
          onClose={handleBudgetItemMenuClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          {editOptions.map((option) =>
            <MenuItem key={option.value} onClick={() => option.action(option?.value)}>
              <ListItemIcon>{option.icon}</ListItemIcon>
              <ListItemText>{option.label}</ListItemText>
            </MenuItem>)}
        </Menu>
      )}
      {monthClicked && boolShowMonthOptions(showMonthOptions, monthClicked.month) && monthCopyOptions && (
        <Menu
          disableAutoFocusItem
          anchorEl={anchorMonthBox}
          open={openBudgetMonthOptions}
          onClose={handleMonthBoxClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          {monthCopyOptions.map(({ key, icon, label, action, disabled = false }) =>
            <MenuItem key={key} onClick={() => action(key)} disabled={disabled}>
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText>{label}</ListItemText>
            </MenuItem>)}
        </Menu>
      )}
    </Paper>
  );
};

export default AnnualViewTable;
