export const styles = (theme: Record<string, any>) => ({
  root: {
    height: '100%',
  },
  link: {
    color: theme.palette.link,
    textDecoration: 'none',
    '& :hover': {
      textDecoration: 'none',
    },
  },
  skeletonBarOne: {
    background: theme.palette.grey[300],
    marginBottom: '4%',
    marginLeft: '14%',
    marginRight: '4%',
    borderRadius: 8,
  },
  skeletonBar: {
    background: theme.palette.grey[300],
    margin: '4%',
    borderRadius: 8,
    minWidth: 10,
  },
  skeletonBarWrapper: {
    display: 'flex',
    flexDirection: 'row',
    height: 300,
    alignItems: 'flex-end',
    paddingBottom: '60px',
    justifyContent: 'space-between',
  },
});
