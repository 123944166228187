import React from 'react';
import classNames from 'classnames';
import compose from 'utils/compose';
import { connect } from 'react-redux';
import numeral from 'numeral';
import { List, Set } from 'immutable';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';

import { getLogger } from 'companion-app-components/utils/core';
import { featureFlagsSelectors } from 'companion-app-components/flux/feature-flags';
import { categoriesTypes, categoriesSelectors } from 'companion-app-components/flux/categories';
import { transactionsUtils } from 'companion-app-components/flux/transactions';
import { scheduledTransactionsTypes } from 'companion-app-components/flux/scheduled-transactions';

import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import TextField from '@mui/material/TextField';
import RemoveCircleIcon from '@mui/icons-material/DeleteOutline';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import { withStyles } from 'tss-react/mui';
import { withTheme } from '@emotion/react';
import MUISplitIcon from '@mui/icons-material/CallSplit';
import Link from '@mui/material/Link';

import DetailsPanel from 'components/DetailsPanel';
import QIconButton from 'components/QIconButton';
import QCurrency from 'components/QCurrency';

import { getAccountString } from 'data/accounts/retrievers';
import { getMatchState, isExistingTxn } from 'data/transactions/utils';
import { sumSplitRows, splitTxnRemainder } from 'data/transactions/splitsHelpers';
import { copyObject, normalizeAmt } from 'utils/utils';
import CategoryField from '../QuickenControls/CategoryField/index';
import AmountField from '../QuickenControls/AmountField/index';
import TagsField from '../QuickenControls/TagsField/index';
import { styles } from './styles';
import { isAcme } from '../../isAcme';


const log = getLogger('component/SplitsAndDetailsPanel/index.js');

// ==============================================================
//
// Presents the form to edit/create splits for a transaction
//
// props:
//
// saveFn - the function to call with the object to save
// closeFn - the function to call to hide/dismiss the dialog
// txForm - the transaction record to edit
// onChangeTxnState - it gets called when we update the txnForm Object on the state
//
// Based on the value of the transaction coming in, the split amounts will base thier "sign" on the
// transaction amount value.  If there is no value (0), then it will base it off the first category entered
// (or the category brought in from the new transaction).
//
// Once that is in place, amount signs will be either absolute, or * -1 (reverse of their actual value) for
// easy entry.
//
//
export class SplitsAndDetailsPanel extends React.Component {

  constructor(props) {
    super(props);

    this.state = this.initState(props);
    const addlState = this.state.showSplits ? this.initSplits(props) : null;
    this.state = {
      ...this.state,
      ...addlState,
      wasSplit: transactionsUtils.isSplitTxn(props.txn),
      manualZero: parseFloat(props.txn.amount) === 0,
      autoFocusCategory: true,
    };

    // TODO resets txn being exited, which causes timeout error in recurring bill create flow
    // this.props.saveFn(this.state.txForm, false, false);
  }

  componentDidMount() {

    this.setInitialFocus(true);

    if (this.props.getSaveFn) {
      this.props.getSaveFn(this.saveFn);
    }

  }

  UNSAFE_componentWillReceiveProps(nextProps) {

    if (nextProps.txn.id !== this.props.txn.id) {
      const stateObj = this.initState(nextProps);
      this.setState(stateObj, () => {
        const addlState = this.state.showSplits ? this.initSplits(nextProps) : null;
        this.setState(addlState);
      });
    }
    if ((nextProps.txn.split !== this.props.txn.split) && nextProps.txn.split === null) {
      this.clearSplits();
    }
    /*
    if (nextProps.showSplits !== this.props.showSplits) {
      if (true || this.props.showDetails) {
        if (!this.props.showSplits) {
          this.initSplits(this.props, true);
          this.setState({ showSplits: true });
        } else {
          // remove uncategorized rows
          if (this.props.showDetails) {
            this.clearSplits();
            this.setState({ showSplits: false });
          } else {
            this.cleanUpRows();
          }
        }
        this.setState({ sizesChanged: true, setFocus: true });
      }
    }
    */
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.sizesChanged) {
      this.state.sizesChanged = false;
      if (this.props.onSizeChange) {
        setTimeout(() => this.props.onSizeChange(), 50);
      }
    }
    if (this.state.setFocus) {
      this.state.setFocus = false;
      this.setInitialFocus(true);
    }

    // checks reference of the state to see if they are different,
    // Don't call it when we set the state from props like on componentWillReceiveProps
    if ((prevState.txForm !== this.state.txForm) &&
      (prevProps.txn === this.props.txn) && this.props.onChangeTxnState) {
      this.props.onChangeTxnState(this.state.txForm);
    }
  }

  initState = (props) => ({
    showMemo: false,
    txForm: props.txn.toJS(),
    initialTxn: props.txn,
    isNewTxn: !isExistingTxn(props.txn),
    baseAmount: this.getBaseAmount(props.txn),
    type: this.props.txn.type,
    sizesChanged: true,
    currency: props.getTxnCurrency(props.txn),
    txnDirty: false,
    rowsCalcModeOff: new Set([]),
    detailsForm: null,
    setFocus: false,
    showSource: false,
    showSplits: props.showSplits || (transactionsUtils.isSplitTxn(props.txn) && props.showDetails),
  });

  getBaseAmount(txn) {
    if (!txn.type) {
      return txn.amount;
    }

    let baseAmount = txn.amount;
    switch (txn.type) {
      case scheduledTransactionsTypes.STTypeEnum.INCOME: {
        if (baseAmount < 0) {
          baseAmount *= -1;
        }
        break;
      }
      case scheduledTransactionsTypes.STTypeEnum.BILL: {
        if (baseAmount > 0) {
          baseAmount *= -1;
        }
        break;
      }
      default:
        break;
    }
    return baseAmount;
  }

  // Had to do this because componentDidMount was being called BEFORE the DOM was
  // finished being created.  The field does not exist in windowed mode (using Dialog)
  // until after CDM is called.
  //
  setInitialFocus = (retry) => {
    log.log('setting focus');
    const node = this.state.showSplits ?
      document.querySelectorAll('[name="row-0-field-category"]') :
      document.querySelectorAll('[name="details-first-tab"]');

    if (node && node.length > 0) {
      // needed delay, somtimes focus was not sticking
      setTimeout(() => node[0].focus(), 150);
      node[0].focus();
    } else if (retry) {
      setTimeout(() => this.setInitialFocus(), 250, false);
    }
  };

  initSplits = (props, addUncategorizedRow = true) => {

    const stateObj = {};

    const hasSplits = transactionsUtils.isSplitTxn(props.txn);

    if (hasSplits) {
      stateObj.isUpdating = true;
    } else {

      this.state.txForm.split = { items: [] };

      // if this is a new split,and there is a root category, make it the first row
      if (this.state.txForm.coa && (this.state.txForm.coa.type !== 'UNCATEGORIZED' && Number(this.state.txForm.coa.id) !== 0)) {

        this.addSplitRow(this.state.txForm, this.state.txForm.coa, this.state.txForm.tags);
      }
    }

    // if there is not an uncategorized row already, add one
    const splitItems = this.state.txForm.split?.items;
    if (splitItems &&
      splitItems.length <= 1 &&
      addUncategorizedRow &&
      (!hasSplits || this.splitUncategorizedRow(this.state.txForm) < 0)) {
      const txObj = this.state.txForm;
      this.addSplitRow(txObj, null, null);

      // if we didn't end up with at least 2 split rows, add one more
      if (txObj.split.items.length <= 1) {
        // Was causing new txn to have 2 Uncategorized line items
        this.addSplitRow(txObj, null, null);
      }
    }
    // finally, convert all amounts in split items to strings
    /* eslint-disable no-param-reassign */
    splitItems?.forEach((x) => {
      x.amount = numeral(x.amount).format('0.00');
    });

    return stateObj;
  };


  //-------------------------------------------------------
  // saveFn
  //
  saveFn = (autoSave = true, close = true) => {
    // fire off a saveCategory action

    const isSplit = transactionsUtils.isSplitTxn(this.state.txForm);

    log.log('SAVING SPLITs 1', isSplit, copyObject(this.state.txForm));
    if (isSplit) {
      if (this.state.manualZero) {
        // ensure the transaction amount is equal to the sum of the split rows
        this.state.txForm.amount = this.sumSplitRows(this.state.txForm.split.items);
      } else {
        this.state.txForm.amount = this.state.baseAmount;
      }

      this.state.txForm.coa = null;

      this.rebalanceRows(this.state.txForm);
      // if there is only 1 split item, don't use it.

      if (autoSave || close || this.countNonZeroRows(this.state.txForm) > 1) {
        this.cleanUpRows();
      }

      // remove any bogus tags
      this.state.txForm?.split?.items?.forEach((x) => {
        if (x.tags) {
          x.tags = x.tags.filter((y) => y.id !== '0');
        }
      });
    }


    // Merge in details if provided
    if (this.props.showDetails && this.state.detailsForm) {
      const { memo, check, tags, attachments, isExcludedFromReports, isExcludedFromF2S, isBill, isSubscription } = this.state.detailsForm;

      this.state.txForm = { ...this.state.txForm, memo, check, tags, attachments, isExcludedFromReports, isExcludedFromF2S, isBill, isSubscription };

    }

    // convert back to immutables now that editing session is done
    // const newTxn = new txnTypes.transactionsTypes.CashFlowTransaction({ ...this.state.txForm });

    this.props.saveFn(this.state.txForm, autoSave, this.state.txnDirty, !this.state.showSplits); // autosave

    if (close) {
      this.props.closeFn();
    }

    return true;
  };

  countNonZeroRows = (txObj) => (
    txObj.split.items.filter((x) => Number(x.amount) !== 0).length
  );

  clickPayeeLink = (name) => {
    window.open(`https://www.google.com/search?q=${encodeURIComponent(name)}`);
  };

  cleanUpRows = () => {
    // if there is only 1 split item, don't use it.
    const validRows = this.countNonZeroRows(this.state.txForm);

    if (validRows === 1) {
      this.state.txForm.coa = this.state.txForm.split.items[0].coa;
      this.state.txForm.split.items = [];
    } else if (validRows !== 0) {
      this.state.txForm.coa = null;
    } else { // 0 items
      this.state.txForm.split = { items: [] };
    }
  };

  clearSplits = () => {
    const { txForm } = this.state;

    if (transactionsUtils.isSplitTxn(txForm)) {
      txForm.coa = copyObject(txForm.split?.items[0]?.coa || this.state.initialTxn.coa);
      txForm.tags = copyObject(txForm.split?.items[0]?.tags || null);
      txForm.split = null;
      // txForm.split = { items: [{ coa: txForm.split.items[0].coa, amount: txForm.amount }] };
      // const stateObj = { ...this.state, ...this.initSplits(this.props, !this.props.showDetails), txnDirty: true };
      // this.setState({ ...stateObj });
      this.setState({ showSplits: false, txnDirty: true, txForm });
      this.saveFn(false, !this.props.showDetails);
    }
    if (this.props.onSizeChange) {
      this.props.onSizeChange();
    }
  };

  closeAndExit = () => {
    // this,state.txForm = this.props.txn.toJS();
    if (!this.doAutoSaveRegister()) this.props.saveFn(this.state.initialTxn.toJS(), false, true);
    this.props.closeFn();
  };

  sumSplitRows = (items) => sumSplitRows(items);

  splitRowsRemainder = (txForm) => splitTxnRemainder(txForm);

  addOneSplitRow = () => {
    const txObj = copyObject(this.state.txForm);
    this.addSplitRow(txObj);
    this.setState({ txForm: txObj });
    if (this.props.onSizeChange) {
      this.props.onSizeChange();
    }
  };

  addSplitRow = (txObj, coa = null, tags = null) => {

    if (!txObj.split || !txObj.split.items) {
      txObj.split = {
        items: [],
      };
    }

    let itemAmount = 0;
    if (!this.state.manualZero) {
      itemAmount = this.state.baseAmount - (Number(this.sumSplitRows(txObj.split.items)));
    } else {
      txObj.amount = this.sumSplitRows(txObj.split.items);
    }

    const newRow = {
      memo: '',
      tags,
      coa: coa || { type: 'UNCATEGORIZED', id: '0' },
      amount: numeral(itemAmount).format('0.00'),
    };

    txObj.split.items.push(newRow);

  };

  removeSplitRow = (row) => {
    const txObj = copyObject(this.state.txForm);

    txObj.split.items[row] = { memo: '', tags: null, coa: { type: 'UNCATEGORIZED', id: '0' }, amount: '0.00' };
    txObj.split.items.splice(row, 1);

    this.rebalanceRows(txObj);
    this.setState({ txnDirty: true });
    setTimeout(this.doAutoSave, 10);
  };

  // Use the first row as the uncategorized row while calculator mode is on for that row
  splitUncategorizedRow = (txForm, ignoreFirstRow = false) =>
    (this.state.isUpdating || ignoreFirstRow || this.state.rowsCalcModeOff.includes(0)) ?
      txForm.split.items.findIndex((item) =>
        ((item.coa && item.coa.type === 'UNCATEGORIZED') && Number(item.coa.id) === 0)) : 0;

  //-------------------------------------------------------
  // handleChange
  //
  // This is where we update state of current edit field based on changes
  // global now with specific checks, TODO: make this more configuration driven, not if statements
  //
  handleChange = (event, index, field) => {
    const txObj = copyObject(this.state.txForm);

    switch (field) {
      case 'category':
        if (!event) return;
        txObj.split.items[index].coa = event;
        this.state.txForm = txObj; this.state.txnDirty = true;
        this.doAutoSave();
        break;

      case 'amount':
        txObj.split.items[index][field] = event.target.value;
        break;
      default:
        txObj.split.items[index][field] = event.target.value;
        break;
    }

    this.setState({ txnDirty: true, txForm: txObj });

  };

  doAutoSave = () => {
    if (this.doAutoSaveRegister()) {
      this.saveFn(false, false);
    }
  };

  tagsChange = (tags, index) => {
    const txObj = copyObject(this.state.txForm);
    txObj.split.items[index].tags = tags;
    this.setState({ txnDirty: true, txForm: txObj, sizesChanged: true });
    setTimeout(this.doAutoSave, 1500);
    // this.doAutoSave();
  };

  detailsChanged = (details, forceSave) => {

    this.setState({ detailsForm: details, txnDirty: true });
    if (this.doAutoSaveRegister() || forceSave) {
      setTimeout(() => this.saveFn(this.doAutoSaveRegister() ? false : forceSave, false), 500);
    }
  };

  handleAmountFocus = (event, index) => {
    this.startingAmountValue = Number(this.state.txForm.split.items[index].amount);
  };

  handleAmountBlur = (event, index) => {

    const txObj = this.state.txForm;
    if (txObj.split.items[index]) {
      txObj.split.items[index].amount = event.target.value;
      this.rebalanceRows(txObj, index);

      if (Number(txObj.split.items[index].amount) !== this.startingAmountValue) {
        this.setState({ rowsCalcModeOff: this.state.rowsCalcModeOff.add(index) });
        this.doAutoSave();
      }
    }
  };

  rebalanceRows = (txObj, index = -1) => {


    if (txObj.split && txObj.split.items && txObj.split.items.length > 0) {
      const unCategorizedRow = this.splitUncategorizedRow(txObj, index === 0);

      let sizesChanged = index < 0;

      if (this.state.manualZero) {
        const amt = Number(this.sumSplitRows(txObj.split.items));
        txObj.amount = normalizeAmt(amt);
        if (unCategorizedRow < 0) {
          this.addSplitRow(txObj);
          sizesChanged = true;
        }
      } else {
        //
        // complicated rules here... if there is a remainder, we must allocate it
        // if there is an uncategorizedRow, and it is not the row we are on, then use it
        // otherwise create a new row
        //
        const remainder = this.splitRowsRemainder(txObj);

        if (remainder !== 0) {
          if (!this.state.wasSplit && index !== 0) {
            const item = txObj.split.items[0];
            item.amount = numeral(normalizeAmt(String(Number(item.amount) + remainder))).format('0.00');
          } else if (unCategorizedRow >= 0) {
            if (unCategorizedRow !== index) {
              const item = txObj.split.items[unCategorizedRow];
              item.amount =
                numeral(normalizeAmt(String(Number(item.amount) + remainder))).format('0.00');
            } else {
              const item = txObj.split.items[unCategorizedRow];
              item.amount =
                numeral(normalizeAmt(String(Number(item.amount) + remainder))).format('0.00');
            }
            if (index === 0) {
              this.state.wasSplit = true;
            }
          } else {
            this.addSplitRow(txObj);
            sizesChanged = true;
          }
        }
        // finally, if there is NO uncategorized row, add one (should always have one)
        if (this.splitUncategorizedRow(txObj, true) < 0) {
          // this.addSplitRow(txObj);
          sizesChanged = true;
        }
      }
      this.setState({ sizesChanged, txForm: txObj });
    }
  };

  getNextField = (currField, direction = 'forward') => {
    const { splitsSideBySide, splitShowNotes } = this.props;

    // const skip1 = splitShowNotes ? 'tags' : 'category';
    // const skip2 = splitShowNotes ? 'tags' : 'category';
    const amountBackField = splitShowNotes ? 'notes' : 'tags';
    const skip1 = 'tags';
    const skip2 = 'category';

    // Moving forward
    let focusField = null;

    if (direction === 'forward') {
      switch (currField) {

        case 'last-button':
          if (splitsSideBySide) {
            focusField = this.props.showDetails ? 'details-first-tab' : 'splits-panel-onoff';
          } else {
            focusField = 'splits-panel-onoff';
          }
          break;

        case 'details-last-tab':
          focusField = 'splits-panel-onoff';
          break;

        case 'splits-panel-onoff':
          if (!this.state.showSplits) {
            focusField = 'first-button';
          }
          break;

        case 'splits-last-tab':
          if (splitsSideBySide) {
            focusField = 'first-button';
          }
          break;

        default:
          if (currField && currField.includes(skip1)) {
            const row = currField.split('-')[1];
            focusField = `row-${row}-field-${skip2}`;
          }
          break;

      }
    } else {
      // Moving Backwards
      switch (currField) {
        case 'first-button':
          if (splitsSideBySide || !this.props.showDetails) {
            focusField = this.state.showSplits ? 'splits-last-tab' : 'splits-panel-onoff';
          } else {
            focusField = 'details-last-tab';
          }
          break;

        case 'splits-panel-onoff':
          if (this.props.showDetails) {
            focusField = splitsSideBySide ? 'details-last-tab' : 'last-button';
          } else {
            focusField = 'last-button';
          }
          break;

        case 'details-first-tab':
          focusField = splitsSideBySide ? 'last-button' : null;
          break;

        default:
          if (currField && currField.includes('amount')) {
            const row = currField.split('-')[1];
            focusField = `row-${row}-field-${amountBackField}`;
          }
          break;
      }
    }
    return focusField;
  };

  //------------------------------------------------------
  // handleKeyDown
  //
  //
  // Items that are relevant
  //
  // details-first-tab
  // details-last-tab
  // [name="row-0-field-category"]  (first tabbable field in split rows)
  // [name="row-${row}-field-amount"] (amount field for any row to skip the drop down button from category
  // splitLastTab (the last tabbable field in the split rows)
  // first-button
  // last-button
  //
  // And of course the feature flag splitsSideBySide determines the orientation of the splits and the details,
  // which impacts the tab order
  //


  keyUp = () => {
    // this.setState({showSource: false});
  };

  handleKeyDown = (e) => {
    // Hotkey source
    if (e.ctrlKey) {
      // this.setState({showSource: true});
    }
    if (e.key === 'Escape') {
      e.preventDefault();
      e.stopPropagation();
      if (this.doAutoSaveRegister()) {
        setTimeout(() => this.saveFn(true, true, 10));
      } else {
        this.closeAndExit();
      }
    }
    if (e.key === 'Enter') {
      // Save
      if (this.doAutoSaveRegister()) {
        // setTimeout(() => this.props.closeFn());
      } else {
        this.saveFn();
      }
      e.stopPropagation();
    }
    if (e.key === 'Tab') {
      e.stopPropagation();
      // won't successfully pass the name of the TAGS field.  back/forward tab from Tags is default behavior
      const focusField = this.getNextField(e.target.name, e.shiftKey ? 'backward' : 'forward');

      if (focusField) {
        e.preventDefault();
        let fld = document.querySelectorAll(`[name="${focusField}"]`)[0];

        // This is a hack until we refactor the tags field.  Select will not pass down the 'name' property
        // to the input field (despite it being in inputProps), and thus, we cannot attach the name directly
        // to the input. So... we attach it to the wrapper, and have to 'dig in' to get the input
        if (focusField.indexOf('tags') > 0) {
          fld = fld.querySelector('button').focus && fld.querySelector('button').focus();
        }
        if (fld) {
          fld.focus();
        }
      }
    }
  };

  showSplitsToggle = () => {
    const newState = !this.state.showSplits;
    if (!newState) {
      this.clearSplits();
      // this.props.saveFn(this.state.txForm, false, true);
      this.setState({ showSplits: false });
      // clearSplits
    } else {
      // initSplits
      this.initSplits(this.props, true);
      this.setState({ showSplits: true });
      this.props?.splitSwitch(newState);
      this.props.saveFn(this.state.txForm, false, true);
    }
  };

  doAutoSaveRegister = () =>
    this.props.forceAutoSave || (this.props.autoSaveRegister && Number(this.props.txn.id));

  renderSplitHeader = () => {

    const catHead = 'Split this transaction'; // isSplitTxn(txForm) ? 'Split' : 'Splits';
    const { classes, splitSwitchOnLeft } = this.props;
    const splitToggleClass = this.props.showDetails ? classes.pointer : null;

    return (
      <Paper elevation={0} className={classes.headerRow}>

        {/* -- CATEGORY FIELD -- */}

        {splitSwitchOnLeft &&
          <Switch
            id={`split-toggle-${this.state.showSplits ? 'active' : 'inactive'}`}
            onClick={() => this.showSplitsToggle()}
            checked={this.state.showSplits}
            focusRipple
            inputProps={{
              name: 'splits-panel-onoff',
            }}
          />}
        <Paper elevation={0} className={classNames(classes.pointer, classes.splitTitleIcon)}>
          <MUISplitIcon />
        </Paper>
        <div
          className={classNames('flex2', classes.splitField, splitToggleClass)}
        >
          <Typography
            className={classes.splitTitle}
          >
            {this.state.showSplits ? catHead : catHead}
          </Typography>

          <div style={{ display: 'flex', alignItems: 'center' }}>
            {this.state.showSplits &&
              <>
                <Typography
                  style={{ marginRight: 10 }}
                >
                  Transaction Total:
                </Typography>
                <Typography
                  style={{ marginRight: 30 }}
                >
                  {`${numeral(this.state.baseAmount).format('0,00.00')}`}
                </Typography>
              </>}

            {splitSwitchOnLeft ?
              <div
                className={classes.switchFill}
              />
              :
              <Switch
                id={`split-toggle-${this.state.showSplits ? 'active' : 'inactive'}`}
                onClick={() => this.showSplitsToggle()}
                checked={this.state.showSplits}
                focusRipple
                inputProps={{
                  name: 'splits-panel-onoff',
                }}
              />}
          </div>

        </div>

      </Paper>
    );
  };

  /*
   * RENDER 'APPEARS ON...'
   * TEXT FIELD
   */
  renderAppearsOn = (txForm, classes) => {
    const { cpData } = txForm;
    let bankName;
    if (cpData) {
      bankName = getAccountString(txForm.accountId);
    }

    const matchVerb = isAcme && txForm.cpData ? 'link' : 'match';

    return (
      txForm.cpData &&
      <div className={classes.detailsRow}>
        {!(isAcme) &&
          <div className={classes.rowIcon}>
            <AccountBalanceIcon />
          </div>}
        <div className={classes.bankInfoContainer}>
          <Typography
            variant="caption"
            color="textSecondary"
            display="block"
          >
            {`Appears on your ${bankName} statement as `}
            <Link
              onClick={() => this.clickPayeeLink(cpData.payee)}
            >
              {cpData.payee}
            </Link>
            {` on ${DateTime.fromFormat(cpData.postedOn || '', 'yyyy-M-d').toLocaleString(DateTime.DATE_MED)} `}
            {this.state.showSource && `source is ${txForm.source}`}
          </Typography>

          {getMatchState(txForm) === 'matched' &&
            <Typography
              variant="caption"
              color="textSecondary"
              display="block"
            >
              This transaction was matched to an existing transaction when it was downloaded.&nbsp;
              {this.props.unMatchAction &&
                <Link
                  onClick={this.props.unMatchAction}
                >
                  {`Un${matchVerb}`}
                </Link>}
            </Typography>}
        </div>
      </div>
    );
  };

  toogleCategoryFocus = (focus = true) => {
    this.setState({ autoFocusCategory: focus });
  };

  /*
   * RENDER CATEGORY FIELD
   */
  renderCatField = (opts, classes) => {
    const catFieldName = (opts.index === 0 && !this.props.showDetails) ? 'row-0-field-category' : null;
    return (
      // eslint-disable-next-line
      <div
        key={`split-cat-${opts.index}`}
        className={classNames('flex2', this.props.classes.textField)}
        onKeyDown={this.handleKeyDown}
      >
        <CategoryField
          InputProps={{ input: classes.ellipsePlaceholder }}
          name={`row-${opts.index}-field-category`}
          id={`row-${opts.index}-field-category`}
          onChange={(coa) => this.handleChange(coa, opts.index, 'category')}
          value={opts.item.coa}
          fontSize={this.props.theme.components.register.fontSize.default}
          editable={this.props.editable}
          createEnabled
          recommendedCategoryType={opts.item.amount > 0 ? categoriesTypes.CategoryTypeEnum.INCOME : categoriesTypes.CategoryTypeEnum.EXPENSE}
          disableUnderline
          onBlur={() => this.toogleCategoryFocus(false)}
          initialFocus={this.state.autoFocusCategory && catFieldName !== null}
          longCats={this.props.longCats}
          onKeyDown={this.handleKeyDown}
        >
        </CategoryField>
      </div>
    );
  };

  /*
   * RENDER AMOUNT FIELD
   */
  renderAmountField = (opts) => {

    const catObj = opts.item.coa && this.props.categoriesById.get(opts.item.coa.id);
    const isIncomeCategory = catObj && catObj.type === 'INCOME';

    // if(this.props.splitTxnCoaType.get(opts.item.coa && opts.item.coa.id) === TransactionBucketEnum.INCOME) {
    //   isIncomeCategory = true;
    // }

    return (
      <div
        key={`split-amount-${opts.index}`}
        className={classNames('amountField', this.props.splitsSideBySide ? 'flex2' : '', this.props.classes.textField)}
      >
        <AmountField
          className={this.props.classes.inputStyle}
          name={`row-${opts.index}-field-amount`}
          id={`row-${opts.index}-field-amount`}
          onChange={(e) => this.handleChange(e, opts.index, 'amount')}
          onBlur={(e) => this.handleAmountBlur(e, opts.index, 'amount')}
          onFocus={(e) => this.handleAmountFocus(e, opts.index, 'amount')}
          onKeyDown={this.handleKeyDown}
          proposedSign={isIncomeCategory ? 1 : -1}
          value={String(opts.item.amount) || ''}
          editable
          disableUnderline
          amountType="amount"
          currencySymbol={this.props.showCurrencySymbol ? this.state.currency : 'N/A'}
        />
      </div>
    );
  };

  /*
   * RENDER TAGS FIELD
   */
  renderTagsField = (opts) => {

    const immutableTags = List(opts.item.tags || []);

    return (
      <div
        key={`split-tags-${opts.index}`}
        className={classNames('flex2', this.props.classes.textField)}
      >
        <TagsField
          onKeyDown={this.handleKeyDown}
          value={immutableTags}
          onChange={(tags) => this.tagsChange(tags, opts.index)}
          disableUnderline
          name={`row-${opts.index}-field-tags`}
          id={`row-${opts.index}-field-tags`}
          fieldId={`row-${opts.index}`}
          editable={this.props.editable}
        >
        </TagsField>
      </div>
    );
  };

  /*
   * RENDER NOTES FIELD
   */
  renderNotesField = (opts) => (
    <div
      key={`split-notes-${opts.index}`}
      className={classNames(this.props.classes.textField)}
    >
      <TextField
        id={`row-${opts.index}-field-notes`}
        name={`row-${opts.index}-field-notes`}
        className={this.props.classes.inputStyle}
        onChange={(e) => this.handleChange(e, opts.index, 'memo')}
        onKeyDown={this.handleKeyDown}
        placeholder="Memo"
        value={opts.item.memo || ''}
        InputProps={{ disableUnderline: true }}
      />
    </div>
  );

  renderOneSplitRow = (item, index, total, classes) => {
    // log.log('RenderOneSplitRow:', item, this.state.currency, this.props.showCurrencySymbol);

    const lastRow = (index === (total - 1));

    const fields = [];

    fields.push(this.renderCatField({ item, index }, classes));
    if (this.props.splitShowNotes) {
      fields.push(this.renderTagsField({ item, index }));
      fields.push(this.renderNotesField({
        item,
        index,
      }));
      fields.push(this.renderAmountField({ item, index }));
    } else {
      fields.push(this.renderTagsField({ item, index }));
      fields.push(this.renderAmountField({ item, index }));
    }

    return (
      <Paper key={`spl-${index}`} elevation={0} className={this.props.classes.listRow}>

        {fields}

        {/* -- ADD ROW -- */}
        <Paper elevation={0} className={classNames(this.props.classes.pointer, this.props.classes.rowIcons)}>

          <QIconButton
            aria-label="Remove Split Row"
            onClick={() => this.removeSplitRow(index)}
            name={`row-${index}-remove-row`}
            id={`row-${index}-remove-row`}
            size="small"
          >
            <RemoveCircleIcon>
            </RemoveCircleIcon>
          </QIconButton>
          {lastRow &&
            <QIconButton
              className={classes.addIconButton}
              aria-label="Add Split Row"
              onClick={() => this.addOneSplitRow()}
              size="medium-no-padding"
              name={lastRow ? 'splits-last-tab' : null}
              id={`add-split-row-${index}`}
            >
              <AddCircleIcon />
            </QIconButton>}
        </Paper>
      </Paper>
    );
  };

  renderSplitRows = (txForm, classes) => {
    const splitRows = [];
    // render the existing rows
    if (txForm.split) {
      txForm.split?.items?.forEach((item, index) => {
        splitRows.push(this.renderOneSplitRow(item, index, txForm.split.items.length, classes));
      });
    }
    return splitRows;
  };

  /* eslint-disable arrow-body-style */
  renderSplitPanel = (classes, txForm, showDetails, hideFooter) => {

    const { splitsSideBySide, inDialog, editable } = this.props;
    const containerClass = splitsSideBySide && showDetails ? 'sidebyside' : null;

    return (
      <div className={classNames(classes.panelContainer, !editable ? 'disable' : '')}>
        <Paper
          className={classNames(classes.panel, inDialog ? 'inDialog' : '')}
          onKeyDown={this.handleKeyDown}
          onKeyUp={this.keyUp}
          elevation={0}
        >
          <div className={classes.splitsDetailsParent}>
            {showDetails &&
              <Paper elevation={0} className={classNames((isAcme ? classes.detailsContainer : classes.webFirstDetails), containerClass)}>
                <DetailsPanel
                  onChange={this.detailsChanged}
                  txForm={this.props.txn}
                  onSizeChange={this.props.onSizeChange}
                  unMatchAction={this.props.unMatchAction}
                  firstTabName="details-first-tab"
                  lastTabName="details-last-tab"
                  goToTransferAction={this.props.goToTransferAction}
                  editable={editable}
                />
              </Paper>}
            <div className={showDetails ? classes.rightCol : classes.splitsOnly}>
              <Paper elevation={0} className={classNames(classes.splitsContainer, containerClass)}>
                {this.renderSplitHeader(classes)}
                {this.state.showSplits &&
                  <>
                    {this.renderSplitRows(txForm, classes)}
                  </>}
                {/* Section below is the 'appears on your statement as...' section
              * This function is a bit of misnomer because the splits panel also renders the details panel
              * so in this sense it's more like a parent flex container
            */}
              </Paper>
              {this.props.showDetails &&
                <>
                  {this.renderAppearsOn(txForm, classes)}
                </>}

            </div>
          </div>
          {!hideFooter &&
            <div className={classes.buttonHolder}>
              {this.props.showDetails && !this.state.isNewTxn &&
                <Paper elevation={0} className={classes.buttonsLeft}>
                  <Button
                    className={classes.deleteButton}
                    onClick={this.props.deleteFn}
                    color="primary"
                    name="first-button"
                    id="splits-panel-delete"
                  >
                    Delete Transaction
                  </Button>
                </Paper>}
              <Paper elevation={0} className={classes.buttonsRight}>
                {!this.doAutoSaveRegister() &&
                  <Button
                    className={classes.buttons}
                    onClick={this.closeAndExit}
                    color="primary"
                    id="splits-panel-cancel"
                  >
                    Cancel
                  </Button>}
                <Button
                  className={classes.buttons}
                  onClick={this.doAutoSaveRegister() ? this.closeAndExit : this.saveFn}
                  name="last-button"
                  color="primary"
                  id="splits-panel-save"
                >
                  {this.doAutoSaveRegister() ? 'Close' : 'Save'}
                </Button>
              </Paper>
            </div>}
        </Paper>
      </div>
    );
  };

  render() {

    const { classes, showDetails, hideFooter, inDialog } = this.props;
    const { txForm } = this.state;
    if (inDialog) {
      return (
        <Dialog
          open
          maxWidth="xlg"
          classes={{ paperWidthLg: classes.dialogPaper }}
        >
          {this.renderSplitPanel(classes, txForm, showDetails, hideFooter)}
        </Dialog>
      );
    }
    return (this.renderSplitPanel(classes, txForm, showDetails, hideFooter));
  }
}

SplitsAndDetailsPanel.propTypes = {
  txn: PropTypes.object,
  closeFn: PropTypes.func,
  classes: PropTypes.object,
  saveFn: PropTypes.func,
  deleteFn: PropTypes.func,
  getSaveFn: PropTypes.func,
  longCats: PropTypes.bool,
  showCurrencySymbol: PropTypes.bool,
  onSizeChange: PropTypes.func,
  showDetails: PropTypes.bool,
  hideFooter: PropTypes.bool,
  inDialog: PropTypes.bool,
  // showSplits: PropTypes.bool,
  unMatchAction: PropTypes.func,
  goToTransferAction: PropTypes.func,
  splitShowNotes: PropTypes.bool,
  splitsSideBySide: PropTypes.bool,
  autoSaveRegister: PropTypes.bool,
  splitSwitchOnLeft: PropTypes.bool,
  theme: PropTypes.object,
  onChangeTxnState: PropTypes.func,
  forceAutoSave: PropTypes.bool,
  categoriesById: PropTypes.object,
  editable: PropTypes.bool,
  splitSwitch: PropTypes.func,
};

SplitsAndDetailsPanel.defaultProps = {
  editable: true,
};

function mapStateToProps(state) {
  return {
    splitShowTransactionTotal: featureFlagsSelectors.getFeatureFlags(state).get('splitShowTransactionTotal'),
    splitShowNotes: featureFlagsSelectors.getFeatureFlags(state).get('splitShowNotes'),
    splitsSideBySide: featureFlagsSelectors.getFeatureFlags(state).get('splitsSideBySide'),
    autoSaveRegister: featureFlagsSelectors.getFeatureFlags(state).get('autoSaveRegister'),
    splitSwitchOnLeft: featureFlagsSelectors.getFeatureFlags(state).get('splitSwitchOnLeft'),
    categoriesById: categoriesSelectors.getCategoriesById(state),
  };
}

SplitsAndDetailsPanel.whyDidYouRender = true;

// These are prop injectors
export default compose(
  QCurrency(),
  withTheme,
  connect(mapStateToProps, null),
)(withStyles(SplitsAndDetailsPanel, styles));
