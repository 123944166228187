import * as React from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { List as ImmutableList, Map as ImmutableMap } from 'immutable';
import { useNavigate, useLocation } from 'react-router-dom';

import List from '@mui/material/List';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import ListItem from '@mui/material/ListItem';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import CheckIcon from '@mui/icons-material/Check';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import CircularProgress from '@mui/material/CircularProgress';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';

import { profileSelectors } from 'companion-app-components/flux/profile';
import { datasetsSelectors } from 'companion-app-components/flux/datasets';
import type RootState from 'companion-app-components/utils/redux-store/rootState';
import { featureFlagsSelectors } from 'companion-app-components/flux/feature-flags';
import { authActions, authTypes, authSelectors } from 'companion-app-components/flux/auth';
import { getBuildConfig, getEnvironmentConfig, tracker } from 'companion-app-components/utils/core';

import Dump from 'components/Dump';
import QButton from 'components/QButton';
import ZeroStateView from 'components/ZeroStateView';
import {
  DIALOG_TYPE as DIALOG_NOTIFICATION,
  mkNotificationDialogProps,
} from 'components/Dialogs/NotificationDialog/types';
import MiniDatasetPicker from 'components/MiniDatasetPicker';
import useQPreferences from 'components/QPreferences/useQPreferences';
import { DIALOG_TYPE_EMPTY_DIALOG } from 'components/Dialogs/EmptyDialog';
import { DIALOG_TYPE_REPORT_ERROR } from 'components/Dialogs/ReportErrorDialog';
import { overviewLayout, overviewLayoutVersion } from 'containers/OverviewPage/config';
import { doAdditionalDesktopFeatures as doAdditionalDesktopFeaturesAction } from 'components/Dialogs/WFDesktopFeatures/actions';
import { getOwnerName } from 'containers/ProfilePage/utils';
import SubscriptionAlert from 'containers/ProfilePage/SubscriptionAlert';

import { mkRootUiData } from 'data/rootUi/types';
import { getSSOToken } from 'data/sso/ssoActions';
import { dispatchSimpleNotification } from 'data/notifications/notificationsUtils';
import * as subscriptionsSelectors from 'data/subscriptions/subscriptionsSelectors';
import { createDialog as createDialogAction, removeDialog } from 'data/rootUi/actions';

import ConfusedStarImage from 'assets/confused-star.svg';
import WarningImage from 'assets/warning.svg';

import ProfileAvatar from './ProfileAvatar';
import { getFullName } from './utils';

interface ProfileMenuProps {
  classes: Record<string, any>;
}

const ProfileMenu: React.FC<ProfileMenuProps> = ({ classes }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const theme: Record<string, any> = useTheme();
  const { setDatasetPreference } = useQPreferences();
  const navigate = useNavigate();

  const profile = useSelector((state: RootState) => profileSelectors.getProfile(state));
  const datasetId = useSelector((state: RootState) => authSelectors.getDatasetId(state));
  const authSession = useSelector((state: RootState) => authSelectors.getAuthSession(state));
  const datasets = useSelector((state: RootState) => datasetsSelectors.getActiveDatasets(state));
  const subscription = useSelector((state: RootState) => subscriptionsSelectors.getActiveSubscription(state));
  const feedbackEnabled = useSelector((state: RootState) => featureFlagsSelectors.getFeatureFlags(state).get('feedback'));
  const financesEnabled = useSelector((state: RootState) => featureFlagsSelectors.getFeatureFlags(state).get('finances'));
  const profileHelpSupport = useSelector((state: RootState) => featureFlagsSelectors.getFeatureFlags(state).get('profileHelpSupport'));
  const resetDashEnabled = useSelector((state: RootState) => featureFlagsSelectors.getFeatureFlags(state).get('resetDefaultDashboard'));
  const entitledDatasets = useSelector((state: RootState) => datasetsSelectors.getEntitledDatasets(state));

  const dispatchRemoveDialog = (dialogId) => dispatch(removeDialog(dialogId));
  const createDialog = (uiRootData) => dispatch(createDialogAction(uiRootData));
  const doWFDesktopFeatures = () => dispatch(doAdditionalDesktopFeaturesAction());
  const dispatchGetSSOTokenAction = (data, meta) => dispatch(getSSOToken(data, meta));
  const authLogout = (payload, meta) => dispatch(authActions.authLogout(payload, meta));
  const authSelectDataset = (dataset) => dispatch(authActions.authSelectDataset(dataset));

  const [showHiddenMenu, setShowHiddenMenu] = React.useState<boolean | null>(false);
  const [profileButtonAnchorEl, setProfileButtonAnchorEl] = React.useState<HTMLElement | null>(null);

  const popoverActions = React.useRef<any>(null);

  React.useEffect(() => {
    if (profileButtonAnchorEl && popoverActions.current) {
      popoverActions.current.updatePosition();
    }
  }, [datasets, profileButtonAnchorEl]);

  const closeProfile = () => {
    setProfileButtonAnchorEl(null);
    setShowHiddenMenu(null);
  };

  const myAccountClick = () => {
    tracker.track(tracker.events.myAccount, { location: 'nav menu' });
    closeProfile();
    window.open(`${getEnvironmentConfig().myaccount_url}?bearer=${authSession.accessToken}`);
  };

  const logoutClick = () => authLogout({ reason: 'AUTH_LOGOUT_USER_INITIATED' }, { context: 'profile' });

  const openDesktopAdditionalFeatures = () => {
    closeProfile();
    doWFDesktopFeatures();
  };

  const helpClick = () => {
    closeProfile();
    window.open('https://www.quicken.com/support');
  };

  const handleDatasetClick = (dataset) => {
    if (datasetId !== dataset.id) {
      tracker.track(tracker.events.datasetChange, { location: 'nav menu' });
      setTimeout(() => authSelectDataset(authTypes.AuthSelectDatasetPayload({ dataset })), 100);
      closeProfile();
      createDialog(mkRootUiData({
        type: DIALOG_NOTIFICATION,
        allowNesting: false,
        props: ImmutableMap(mkNotificationDialogProps({
          buttonLabel: null,
          content: (
            <div className={classes.datasetSwitchingContent}>
              <Typography variant="body1">
                Switching to {dataset.name}
              </Typography>
              <CircularProgress classes={{ root: classes.datasetSwitchingProgress }} size={50} />
            </div>
          ),
          dialogProps: {
            disableBackdropClick: true,
            disableEscapeKeyDown: true,
          },
          title: '',
        })),
      }));
    } else {
      setTimeout(closeProfile, 100);  // the delay is only for feel to the user
    }
  };

  const profileClick = (e: React.MouseEvent<HTMLElement>) => {
    if (location.pathname === '/profile') { return; }
    setProfileButtonAnchorEl(e.currentTarget);
  };

  const handleProfileClose = () => {
    closeProfile();
  };
  
  const getFirstLetterOfUserName = (userProfile) => {    
    if (!userProfile) {
      return '';
    }
    const firstName = userProfile.firstName || (userProfile.primaryAddress ? userProfile.primaryAddress.fullName : '') || userProfile.username;
    return firstName[0].toUpperCase();
  };

  const goToCommunity = () => {
    dispatchRemoveDialog('community-verify-email');
    closeProfile();
    // eslint-disable-next-line no-promise-executor-return
    new Promise((resolve, reject) => dispatchGetSSOTokenAction(undefined, {
      resolve,
      reject,
      axiosConfig: {
        params: {
          clientId: getBuildConfig().sso_client_id,
          redirect_uri: getBuildConfig().community_url,
        },
      },
    }))
      .then((response: Record<string, any>) => {        
        if (response?.data?.errors?.[0].code === 'QCS-0401-7') {
          createDialog(mkRootUiData({
            id: 'community-verify-email',
            type: DIALOG_TYPE_EMPTY_DIALOG,
            allowNesting: true,
            props: ImmutableMap({
              colorBar: theme.palette.background.default,
              content:
  <ZeroStateView
    icon={ConfusedStarImage}
    primary="Verify your email address"
    secondary="We sent you an email to confirm your identity. Once verified, click here to try again."
  >
    <QButton
      variant="contained"
      onClick={goToCommunity}
    >
      Go to community
    </QButton>
  </ZeroStateView>,
            }),
          }));
        } else if (assert(response?.data?.redirectUri, 'no sso redirectUri')) {
          setTimeout(() => window.open(response.data.redirectUri, '_blank'), 1000); // setTimeout protects from popup blocker
        }
      })
      .catch((error) => assert(false, error));
  };

  const dataset = datasets.get(datasetId);
  const fullName = getFullName(profile);
  const overviewLayoutAcme = ImmutableList(overviewLayout);

  const handleShareFiles = () => {
    handleProfileClose();
    navigate('/profile', { replace: true });
  };

  const ownDatasets = datasets.filter((item) => !item.shared);
  const sharedDatasets = datasets.filter((item) => item.shared);

  const renderDatasetName = (theDataset, index, ownDataset = false) => {
    const hasValidEntitlement = entitledDatasets.get(theDataset.id);
    const noDatasetOrEntitlement = !ownDataset && !hasValidEntitlement;
    const isDisabled = noDatasetOrEntitlement || (ownDataset && !subscription?.active);
    return (
      <ListItem
        button
        key={theDataset.id}
        onClick={() => handleDatasetClick(theDataset)}
        classes={{ root: classes.datasetListItemDefault }}
        disabled={isDisabled}
        sx={{
          '&.Mui-disabled': {
            opacity: 1, 
          },
        }}
      >
        <Dump obj={theDataset} />
        <ListItemIcon
          style={{
            visibility: `${(dataset === theDataset) || noDatasetOrEntitlement
              ? 'visible'
              : 'hidden'}`,
            marginRight: 0,
          }}
        >
          {noDatasetOrEntitlement ? <img src={WarningImage} alt="alert" height={24} width={24} /> : <CheckIcon /> }
        </ListItemIcon>
        <ListItemText
          sx={{
            opacity: isDisabled ? 0.5 : 1, 
          }}
          primary={
            <div className={classNames(classes.textEllipsis, classes.datasetNameWrapper)}>
              {theDataset.name}
            </div>
          }
          secondary={
            ownDataset 
              ? null
              : (
                <Typography
                  variant="caption"
                  color="koalaGrey"
                >
                  {`Owner: ${getOwnerName(theDataset.grantorFirstName, theDataset.grantorLastName)}  ${!hasValidEntitlement ? '(Subscription expired)' : ''}`}
                </Typography>
              )
          }
          id={`data-set-${index + 1}`}
        />
        { ownDataset && <ProfileAvatar datasetId={theDataset.id} /> }
      </ListItem>
    );
  };

  return (
    <>
      <div
        id={'profile-menu-bar'}
        role={'button'}
        tabIndex={0}
        onKeyPress={() => null}
        onClick={profileClick}
        className={classNames(classes.navMenuItem)}
      >
        <Avatar className={classes.circleButton}>
          <div
            id={'profile-letter'}
            className={classes.firstNameLetter}
          >
            {getFirstLetterOfUserName(profile)}
          </div>
        </Avatar>
        <Typography style={{ marginLeft: 24, textAlign: 'left' }} className={classes.navText}>
          Profile
        </Typography>
      </div>
      <Popover
        action={(actions) => {
          popoverActions.current = actions;
        }}
        id="profile-menu"
        open={!!(profileButtonAnchorEl)}
        anchorEl={profileButtonAnchorEl}
        onClose={handleProfileClose}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        PaperProps={{
          style: {
            transform: 'translate3d(0, 0, 0)',  // fixes a scroll issue: https://github.com/mui-org/@mui/material/issues/10601
          },
        }}
        onKeyDown={(e) => {
          if (e.altKey) { // can't use 'control' key because of MAC's control + click
            setShowHiddenMenu(true);
          }
          if (e.key === 'Escape') {
            handleProfileClose();
          }
        }}
        onKeyUp={() => {
          setShowHiddenMenu(false);
        }}
        disableEnforceFocus
      >
        <div className={classes.profilePopover}>
          {profile &&
          <div className={classes.profileHeader}>
            <Dump obj={{ dataset, profile, subscription }} />
            <h3>{fullName}</h3>
            <p id="my-account-quicken-id">{profile.username}</p>
            {subscription && subscription.tierName &&
            <p id="my-account-product-tier">{subscription.tierName}</p>}

            {showHiddenMenu && dataset &&
            <p
              id="my-account-dataset-id"
              style={{ position: 'absolute', right: 2, top: 2 }}
            >
              {/* @ts-expect-error: Property 'userId' does not exist on type 'Record<Profile>  */}
              {profile.userId}:{dataset.id} 
              <IconButton
                size="small"
                onClick={() => {
                  navigator.clipboard.writeText(
                    `user id: ${profile?.userId}\n`
                    + `dataset id: ${dataset?.id}\n`
                    + `email: ${profile?.username}`,
                  );
                  dispatchSimpleNotification('User copied to clipboard');
                }}
              >
                <FileCopyOutlinedIcon fontSize="inherit" />
              </IconButton>
            </p>}

            <Button
              variant="contained"
              onClick={myAccountClick}
              color="primary"
              id="my-account-button"
            >
              My Account
            </Button>
          </div>}
          {(datasets && datasets.size > 0) && (datasets || financesEnabled) && 
          <div className={classes.profileDataset}>
            {
              ownDatasets && 
                (ownDatasets.size > 1 || (ownDatasets.size > 0 && sharedDatasets.size > 0)) &&
                  <Typography color="inherit" variant="subtitle1" className={classes.profileFinancesTitle}>
                    {`My Cloud Accounts (${ownDatasets.size})`}
                  </Typography>
            }
            {
              subscription !== null && !subscription?.active && <SubscriptionAlert
                alertClass={classes.alert}
                label="Subscription expired. Shared Cloud Accounts are still available. "
              />
            }
            <List disablePadding>
              {ownDatasets && 
                (ownDatasets.size > 1 || (ownDatasets.size > 0 && sharedDatasets.size > 0)) && 
                  ownDatasets.valueSeq().map((theDataset, index) => {
                    if (index < 3) {
                      return renderDatasetName(theDataset, index, true);
                    }
                    return null;
                  })}
              {
                sharedDatasets.size > 0 && 
                <Typography color="inherit" variant="subtitle1" className={classes.profileFinancesTitle}>
                  {`Shared with me (${sharedDatasets.size})`}
                </Typography>
              }
              {sharedDatasets && sharedDatasets.valueSeq().map((theDataset, index) => {
                if (index < 1) {
                  return renderDatasetName(theDataset, index, false);
                }
                return null;
              })}
              {((ownDatasets && ownDatasets.size > 3) || (sharedDatasets && sharedDatasets.size > 1)) &&
              <MiniDatasetPicker
                ownDatasets={ownDatasets}
                sharedDatasets={sharedDatasets}
                entitledDatasets={entitledDatasets}
                subscription={subscription}
                dataset={dataset}
                onChange={(d) => handleDatasetClick(d)}
                label="See all..."
                labelStyle={{ label: classes.financesButtonStyle }}
                id="mini-dataset-picker"
              />}
              <div>
                <Button
                  onClick={handleShareFiles}
                  sx={{ textTransform: 'none', fontSize: 16 }}
                >
                  Manage Sharing
                </Button>
              </div>
            </List>
          </div>}
          <MenuList>
            {dataset && dataset.createdByClientId === 'quicken_webapp' &&
            <MenuItem id="my-account-do-more-quicken" onClick={openDesktopAdditionalFeatures}>
              Do more with Quicken Desktop
            </MenuItem>}
            {profileHelpSupport &&
            <MenuItem
              onClick={helpClick}
              id="my-account-help-and-support"
            >
              Help &amp; Support
            </MenuItem>}

            <MenuItem
              id="feedback-launcher"
              onClick={goToCommunity}
            >
              Community
            </MenuItem>

            {resetDashEnabled &&
            <MenuItem
              onClick={() => {
                closeProfile();
                setDatasetPreference({ overviewLayoutVersion, overviewPageLayout: overviewLayoutAcme });
              }}
              id="reset-default-dashboard"
            >
              Reset Dashboard Layout
            </MenuItem>}

            {feedbackEnabled && showHiddenMenu &&
            <MenuItem
              onClick={() => {
                closeProfile();
                createDialog(mkRootUiData({
                  id: 'report-error',
                  type: DIALOG_TYPE_REPORT_ERROR,
                  allowNesting: true,
                  props: ImmutableMap({
                    feedbackSubject: 'send feedback',
                  }),
                }));
              }}
              id="send-feedback"
            >
              Send Feedback
            </MenuItem>}

            <MenuItem onClick={logoutClick} id="my-account-sign-out">Sign Out</MenuItem>
          </MenuList>
        </div>
      </Popover>
    </>
  );

};

export default ProfileMenu;
