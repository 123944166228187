import React, { useState, useRef } from 'react';
import { makeStyles } from 'tss-react/mui';
import classNames from 'classnames';
import { useTheme } from '@emotion/react';

import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import FlagIcon from '@mui/icons-material/Flag';
import OutlinedFlagIcon from '@mui/icons-material/OutlinedFlag';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';

import { flagFieldOptions } from 'components/TransactionRegister/transactionsConfig';
import { styles } from './style';

const useStyles = makeStyles()(styles as Record<string, any>);

interface FlagFieldProps {
  value: string;
  autoFocus: boolean;
  inputRef?: (ref: HTMLButtonElement | null) => void;
  onChange?: (event: { target: { value: string | null } }) => void;
  editable: boolean;
  includeEmpty: boolean;
  show: boolean;
}

const FlagField: React.FC<FlagFieldProps> = ({
  value,
  autoFocus,
  inputRef,
  onChange,
  editable,
  includeEmpty,
  show,
  ...other
}) => {
  const { classes } = useStyles();
  const theme: Record<string, any> = useTheme();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const btnRef = useRef<HTMLButtonElement | null>(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };

  const handleClose = (item) => {
    setAnchorEl(null);
    if (onChange && item) {
      onChange({ target: { value: item.value ? String(item.value) : null } });
    }
    setTimeout(() => btnRef.current?.focus(), 100);
  };

  const setButtonRef = (ref) => {
    btnRef.current = ref;
    if (inputRef) {
      inputRef(ref);
    }
  };

  const onKeyDown = (event) => {
    if (event.key === 'Escape' || event.key === 'Enter') {
      event.stopPropagation();
    }
    if (event.key === 'Escape') handleClose(null);
  };

  const makeMenuItem = (item) => {
    if (!item.inMenu) return '';
    return (
      <MenuItem key={`flagmenu:${item.label}`} onClick={() => handleClose(item)}>
        <ListItemIcon>
          {item.value !== 'NONE' ?
            <FlagIcon
              style={{
                fontSize: 20,
                color: item.color,
              }}
            />
            :
            <OutlinedFlagIcon
              style={{
                fontSize: 20,
                color: theme.components.payeeField.displayTemplate,
              }}
            />}
        </ListItemIcon>
      </MenuItem>
    );
  };


  const menuItems = flagFieldOptions.map((item) => makeMenuItem(item));
  if (includeEmpty) {
    menuItems.push(makeMenuItem({ label: ' No Change', value: null }));
  }

  const currItemRef = value ? flagFieldOptions.find((x) => String(x.value) === String(value)) : null;

  return (
    <div {...other}>
      {show &&
        <Button
          {...other}
          classes={{ root: classes.buttonRoot }}
          autoFocus={autoFocus}
          aria-owns={anchorEl ? 'flagField-menu' : undefined}
          aria-haspopup="true"
          onClick={handleClick}
          ref={setButtonRef}
        >
          {(currItemRef && currItemRef.value !== 'NONE') ?
            (<FlagIcon style={{ color: currItemRef.color }} className={classes.iconRoot} />) :
            (<OutlinedFlagIcon className={classNames(classes.iconRoot, editable ? 'editable' : '')} />)}
        </Button>}
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => handleClose(null)}
        onKeyDown={onKeyDown}
      >
        {menuItems}
      </Menu>
    </div>
  );
};

export default FlagField;
