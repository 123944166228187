import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { authActions } from 'companion-app-components/flux/auth';

const Logout: FC = () => {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(authActions.authLogout({ reason: 'AUTH_LOGOUT_ROUTE' }, { context: 'url' }));
  }, [dispatch]);

  return null;
};

export default Logout;
