import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { useTheme } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/AddRounded';
import ChevronRightIcon from '@mui/icons-material/ChevronRightRounded';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeftRounded';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuList from '@mui/material/MenuList';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import CheckIcon from '@mui/icons-material/Check';
import Typography from '@mui/material/Typography';
import Portal from '@mui/material/Portal';

import RootState from 'companion-app-components/utils/redux-store/rootState';
import { featureFlagsSelectors } from 'companion-app-components/flux/feature-flags';
import { setPreference } from 'companion-app-components/flux/preferences/actions';
import { getSharedPreferencesByPath } from 'companion-app-components/flux/preferences/selectors';
import { ShowBalance } from 'companion-app-components/flux/preferences/types';

import QTip from 'components/QuickenControls/QTip';
import QIconButton from 'components/QIconButton';
import BalanceSelectMenu from 'components/BalanceSelectMenu';
import RefreshButton from 'containers/App/Header/RefreshButton';
import QButton from 'components/QButton';

import { HandleAddInstitutionLogin } from './types';

interface AccountDrawerToggleProps {
  classes: Record<string, any>;
  handleDrawerToggle: (e: React.MouseEvent) => void;
  open?: boolean;
  anchorEl: null | HTMLElement;
}

export const AccountDrawerToggle: React.FC<AccountDrawerToggleProps> = (props) => {
  const { classes, handleDrawerToggle, open, anchorEl } = props;
  const [isQTipOpen, setIsQTipOpen] = React.useState<boolean>(true);
  const DrawerControlIcon = open ? ChevronLeftIcon : ChevronRightIcon;

  // Once the value of open changes, we know it is safe to show QTips again without the 'render forever' ToolTip bug!
  React.useEffect(() => {
    setIsQTipOpen(true);
  }, [open]);

  // This guarantees there is no race condition and setQTipOpen is always set to false first!
  const toggleQTip = (e, callback) => {
    setIsQTipOpen(false);
    callback(e);
  };

  const renderQIconButton = () => (
    <QIconButton
      size="small-no-padding"
      aria-label="Close the account bar"
      onClick={(e) => {
        toggleQTip(e, handleDrawerToggle);
      }}
      id="account-drawer-toggle"
      className={classes.drawerQIconButton}
    >
      <DrawerControlIcon className={classes.drawerIcons} />
    </QIconButton>
  );

  return (
    <Portal container={anchorEl}>
      <div className={classNames(classes.drawerOpenControl, open ? 'open' : '')}>
        {/* This was to fix a bug where the QTip remained onscreen forever 
        if it was rendered during the AccountDrawer opening/closing */}
        {isQTipOpen ? (
          <QTip
            title={`${!open ? 'Open' : 'Close'} the account bar`}
            placement="right"
            wrapId="account-drawer"
          >
            {renderQIconButton()}
          </QTip>
        ) : renderQIconButton()}

      </div>
    </Portal>
  );
};

const balanceMenuItems = [{
  primary: 'Balance with pending',
  secondary: 'Your bank balance minus any pending transactions. This balance is used to project your cash flow.',
  showBalance: ShowBalance.INCLUDE_PENDING,
}, {
  primary: 'Bank Balance',
  secondary: 'The balance we received from your bank. It typically does not include pending transactions.',
  showBalance: ShowBalance.BANK_REPORTED,
}];

interface AccountListHeaderProps {
  classes: Record<string, any>;
  handleAddInstitutionLogin: HandleAddInstitutionLogin;
  addFiEnabled: boolean;
}

export const AccountListHeader: React.FC<AccountListHeaderProps> = ({
  classes,
  addFiEnabled,
  handleAddInstitutionLogin,
}) => {
  const theme: Record<string, any> = useTheme();
  const dispatch = useDispatch();
  const [balanceMenuAnchorEl, setBalanceMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const showBalance = useSelector((state: RootState) =>
    getSharedPreferencesByPath(state, { group: 'dataset', path: ['shared', 'showBalance'] }))
    || ShowBalance.INCLUDE_PENDING;
  const balanceToggle = useSelector((state: RootState) => featureFlagsSelectors.getFeatureFlag(state, 'balanceToggle'));

  const label = theme.components.accountDrawer.addFiLabel;
  const size = theme.components.accountDrawer.actionButtonSize;

  return (
    <>
      <Typography variant="h5" className={classes.drawerListTitle}>
        Accounts
      </Typography>
      <RefreshButton
        isInAccountBar
        // @ts-expect-error TS(2322) - FIXME: Property 'id' does not exist on type 'IntrinsicAttributes & RefreshButtonProps'
        id="sync-arrow"
        classes={{ buttonRoot: classes.drawerHeaderIcons }}
      />
      {addFiEnabled && (
        <QTip
          title="Add your account"
          placement="right"
        >
          <QButton
            id="addFiButton"
            aria-label="Add Institution Login"
            onClick={handleAddInstitutionLogin()}
            style={{
              minWidth: 0,
              backgroundColor: 'transparent',
              height: !label && size,
              width: !label && size,
              marginRight: label && -16,
            }}
            variant={label ? 'text' : 'containedIcon'}
          >
            <AddIcon className={!label ? classes.drawerHeaderIcons : ''} />
            {label && <Box component="span" mt={0.25} />}
          </QButton>
        </QTip>
      )}

      {balanceToggle && (
        <div
          role="none"
          onClick={(event) => event.stopPropagation()}
        >
          <IconButton
            id="balance-options"
            aria-label="balance options"
            className={classes.buttonShift}
            onClick={(event: React.MouseEvent<HTMLElement>) => {
              setBalanceMenuAnchorEl(event.currentTarget);
              event.stopPropagation();
            }}
            size="large"
          >
            <MoreVertIcon />
          </IconButton>

          <Menu
            id="balance-menu"
            anchorEl={balanceMenuAnchorEl}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            keepMounted
            open={Boolean(balanceMenuAnchorEl)}
            onClose={() => setBalanceMenuAnchorEl(null)}
            PaperProps={{
              style: { maxWidth: '38ch' },
            }}
          >
            <MenuList className={classes.borderCols}>
              {balanceMenuItems.map((item) => (
                <ListItem
                  key={item.primary}
                  id={item.primary}
                  button
                  selected={item.showBalance === showBalance}
                  alignItems="flex-start"
                  onClick={(event) => {
                    event.stopPropagation();
                    dispatch(setPreference({
                      section: 'shared',
                      group: 'dataset',
                      preference: {
                        shared: {
                          showBalance: item.showBalance,
                        },
                      },
                    }));
                    setBalanceMenuAnchorEl(null);
                  }}
                >
                  <ListItemIcon>
                    {item.showBalance === showBalance && <CheckIcon color="primary" />}
                  </ListItemIcon>
                  <ListItemText
                    primary={item.primary}
                    secondary={item.secondary}
                  />
                </ListItem>
              ))}
            </MenuList>
          </Menu>
        </div>
      )}
      <BalanceSelectMenu
        classes={{ balanceMenu: classes.balanceMenu, iconColor: classes.drawerHeaderIcons }}
        menuIconButtonSize="small"
      />
    </>
  );
};
