import _ from 'lodash';
import { createTheme } from '@mui/material/styles';
import { theme as quickenTheme } from 'themes/themeQuicken';
import { applyOpacityToHex, replaceRgbaColor } from 'themes/themeUtils';
import { getThemeOverrideByKey } from 'themes/allThemeOverrides';
import { createCachedSelector, LruObjectCache } from 're-reselect';
import { isAcme } from 'isAcme';

export const boxShadowsToDropShadows = (boxShadows) => {
  const boxShadowsMatches = boxShadows.matchAll?.(/(.+?(?:\([^)]*\))*?)(?:,|$)/g);
  const dropShadows = boxShadowsMatches && Array.from(boxShadowsMatches, (match) => match.length ? match[1] : undefined)
    .filter((boxShadow) => boxShadow?.includes(' '))
    .map((boxShadow) => { // convert box-shadow params to drop-shadow filters
      const boxShadowParamsMatches = boxShadow.matchAll(/(\S+?(?:\(.*?\))?)(?:\s|$)+/g);
      const boxShadowParams = Array.from(boxShadowParamsMatches, (match) => match.length ? match[1] : undefined);
      return `drop-shadow(${
        boxShadowParams
          .filter((param) => param !== 'inset') // drop 'inset'
          .filter((param, index) => index !== 3 || !/^(\+|-)?\d+?.*?$/m.test(param)) // drop spread-radius
          .join(' ')
      })`;
    })
    .join(' ');
  return dropShadows || boxShadows;
};

const blue1 = '#295e9b';
const blue2 = '#3e6da3';
const blue3 = '#4f89cb';
const blue4 = '#5cb3ea';
const blue5 = '#9bcbeb';
const blue6 = '#2db7f5';
const blue7 = '#eBf5f8';

const green1 = '#008e38';
const green2 = '#00b045';
const green3 = '#5ac402';

const red1 = '#d70015';
const red2 = '#e12b3d';
const red3 = '#db4d48';

const teal2 = '#088391';

const gray2 = '#404040';
const gray3 = '#474F57';
const gray5 = '#c8c8c8';
const gray6 = '#3e3e3e';

const gold = '#eaa844';

const status = {
  lowLight: '#67de6f',
  lowMain: '#34ab3c',
  lowDark: '#118819',
  mediumLight: '#ffd444',
  mediumMain: '#eaa111',
  mediumDark: '#c67d00',
  highLight: '#ff6e6e',
  highMain: '#dc3b3b',
  highDark: '#b91818',
};


const steps = 20;
const singleStep = 100 / steps;
const halfStep = singleStep / 2;
const bottomCutOffPath = [...Array(steps + 1).keys()]
  .slice(1, steps + 1)
  .reverse()
  .map((step) => `${step * singleStep}% ${100}%, ${step * singleStep - halfStep}% ${100 - halfStep}%, ${step * singleStep - halfStep - halfStep}% ${100}%`)
  .join(', ');
const bottomCutOffClipPath = `polygon(0 0, 100% 0, 100% 100%, ${bottomCutOffPath}, 0 100%)`;

// This is the base theme for the app, it's the same for Quicken and ACME
const themeBase = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 768,   // inserted, default was 960, added xxl
      lg: 960,
      xl: 1280,
      xxl: 1920,
    },
  },
  featureHeader: {
    titleVariant: 'h6',
    subTitleVariant: 'subtitle1',
  },
  typography: {
    caption: {
      letterSpacing: 'normal',
    },
    overline: {
      lineHeight: 1.66,
    },
    subtitle1: {
      fontWeight: 500,
    },
    h5: {
      fontWeight: 500,
    },
  },
  spacing: 8,
  shape: {
    padding: [0, 4, 8, 12, 16, 20, 24, 32, 40, 48, 56, 64, 70, 78, 86, 94, 102],
    shapesRadius: {
      rounded: '50%',
    },
    bottomCutOffClipPath,
  },

  defaults: {
    dense: window.screen.width > 1024,
    buttonHoverBackgroundColor: '#ebebeb',
    content: {
      // -- sizing -- //
      height: '100%',
      width: 960,
      pageMinWidth: 690,
      // -- spacing -- //
      paddingLeft: 32,
      paddingLeftMobile: 32,
      paddingRight: 32,
      paddingRightMobile: 32,
    },
    headers: {
      // -- sizing -- //
      subHeaderHeight: isAcme ? 75 : 54,
      subHeaderHeightTall: isAcme ? 75 : 70,
      headerHeight: isAcme ? 75 : 70,
    },

  },

  palette: {  // this should be merged with themeQuicken
    linkColor: '#4a90e2',
    linkPress: '#283E9F',
    removeButton: '#707070',
    tooltip: 'rgba(0,0,0,.75)',
    background: {
      default: '#ffffff',
    },
    divider: 'rgba(0, 0, 0, 0.12)',
    status,
    primary: {
      main: '#1f74ba',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#666666',
      contrastText: '#ffffff',
    },
    // -- TEMPORARY -- //
    positiveLight: '#ADD7AD',

    blue1,
    blue2,
    blue3,
    blue4,
    blue5,
    blue6,
    blue7,

    green1,
    green2,
    green3,

    red1,
    red2,
    red3,

    teal2,

    gray2,
    gray3,
    gray5,
    gray6,

    gold,
  },

  // mui5 use components for global styling and props overrides
  components: {
    MuiModal: {
      defaultProps: {
        // This is because if any dialog / popover / menu is open, the user cannot type into Intercom. This has the side effect of potentially losing
        // focus for the modal, which affects accessibility. If we decide to remove this, please consider all things
        disableEnforceFocus: true,
      },
    },
    MuiInputBase: {
      defaultProps: {
        inputProps: {
          autoCorrect: 'off',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        legend: {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        input: {
          letterSpacing: '0.01071em',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        outlined: {
          '&$shrink': {
            backgroundColor: '#ffffff',
          },
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: 24,
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: 24,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        spacing: {
          padding: 24,
        },
      },
    },

    // vvv code below is deprecated - please don't add new components to this object - 'components' is not for storing settings!!! - it's for overrides
    register: {
      // -- spacing -- //
      paddingLeft: 20,
      paddingRight: 20,
      rowInnerPadding: 6,
      fieldPadding: 1,
      projectedSmallOffset: 116, // subHeaderHeightTall + regProjectedHeightClosed,
      projectedBigOffset: 396,  // subHeaderHeightTall + regProjectedHeightOpen,

      // -- typography -- //
      fontSize: {
        small: '12px',
        default: '14px',
        large: '16px',
      },
      // -- sizing -- //
      projectedHeightClosed: 52,
      projectedHeightOpen: 332,
      splitRowHeight: 45,
      checkboxSize: 20,
      controlBarHeight: 60,
      sectionHeaderHeight: 34,
      // -- options -- //
      noUnderline: true,

      // -- COLORS -- //
      fieldBorderColor: '#4a90e2',
      splitsBackground: 'rgba(0, 100, 171, 0.03)',
      headerBackground: '#fafaf9',
      headerBorder: 'rgba(201, 199, 197, 0.5)',
      editRowColor: '#fafaf9',
      catTagDropdown: 'rgba(0, 100, 171, 0.08)',
      sectionHeaderColor: 'rgba(236, 235, 234, 0.3)',
      sectionHeaderBorder: 'rgba(201, 199, 197, 0.3)',
      rowHoverColor: '#fafaf9',
      fieldHoverColor: 'rgba(0, 0, 0, 0.2)',
    },
    logoText: {},
    accountCard: {
      fiWarningBorder: '#ffe4a0',
      fiWarningBackground: '#fff9e9',
      shadowColor: 'rgba(62, 62, 60, 0.1)',

      statusColor: 'rgba(0, 0, 0, 0.6)',
      changeIndicator: 'rgba(220,230,235, 0.5)',
      settingsActionColor: '#ecebea',
    },
    accountDrawer: {
      // -- spacing -- //
      width: 280,
      accountListLevelIndent: 4,
      accountListBaseFontSize: 16,
      drawerListTitleFontSize: 20,
      titleTransform: 'uppercase',
      titleWeight: 500,

      basePadding: 24,
      listPaddingLeft: 24,
      listPaddingRight: 24,
      iconSize: 20,

      // -- sizing -- //
      collapsePoint: 1150,
      collapsedWidth: 16,

      // -- Text colors -- //
      positiveBalance: null,
      negativeBalance: '#ffb7b5',

      // -- Background colors -- //
      background: '#1f6bb1',
      highlightColor: null, // total + net-worth
      hoverColor: null, // hover effect
      l1Color: null, // banking + investments
      logoBackground: '#678fe81a',
      headerBackground: '#1f74ba',

      // -- animation --//
      accountDrawerAnimation: 'all 500ms cubic-bezier(0.550, 0.085, 0.680, 0.530)',

      // other colors
      borderColor: null, // right-side border
      divider: '#1e69ac', // border of collapse button
      openControl: '#fafaf9',  // background inside collapse button,
      icon: '#1f74ba',  // icon inside collapse button,
      actionButtonSize: 40,

      // row sizing
      l1Margin: 0,
      l0Height: 40,
      l1Height: 40,
      l2Height: 40,
      l3Height: 32,

      addFiLabel: null,
    },
    navigation: {
      // -- sizing -- //
      barWidth: 56,
      iconSize: 24,
      // -- spacing -- //
      iconVerticalSpacing: 16,

      // -- animation -- //
      selectedIconAnimation: 'all 200ms cubic-bezier(0.550, 0.085, 0.680, 0.530)',
    },
    watchlist: {
      // -- sizing -- //
      spendingCardWidth: 380,
      spendingCardHeight: 284,
      spendingBarWidth: 48,
      spendingBarWidthSelected: 60,
      fieldHoverColor: 'rgba(0, 0, 0, 0.2)',
      buttonsBorder: '#e5f0fb',
    },
    amountField: {
      border: '#304ffe',
      positiveColor: '#116611',
      negativeColor: '#d70015',
      negativeColor2: '#f5a623',
      positiveColor2: '#04a769',
      extremelyNegative: '#300', // Never used?
    },
    projectedBalances: {
      positiveGradientDark: '#bfd9fc',
      positiveGradientLight: 'rgba(245, 252, 254, 0)',
      todayBar: '#81929b',
      // negativeGradientDark: 'rgba(218, 101, 97, 0.8)',
      // negativeGradientLight: 'rgba(252, 223, 116, 0)',
    },
    categoryRegister: {
      labelHover: 'rgba(0, 0, 0, 0.2)',
      icon: 'rgba(0, 0, 0, 0.5)',
      collapseFilter: 'none',
    },
    reports: {
      graphHeight: 324,
    },
    tagsField: {
      hoverColor: '#e5e5e5',
      clearIconColor: '#aaa',
    },
    addAndBrowserDocuments: {
      deleteIconColor: '#c7939b',
    },
    payeeField: {
      shadowColor: '#ddd',
      displayTemplate: '#777',
    },
    scheduledTxns: {
      chip: '#F5DBB5',
      positiveColor: '#4caf50',
    },
    transactionCalendar: {
      incomeColor: '#e8f5ea',
      expenseColor: '#fcf3e0',
      overdueColor: '#fcebee',
      normalColor: '#f5f5f5',
      notCurrentMonth: '#f4f4f4',
      currentDay: '#0091ff',
      positiveBalance: '#80bb4a',
      negativeBalance: '#e22f31',
      resetDefaultColor: '#2BAB83',
    },
    calendar: {
      checkboxBG: '#ecebea',
    },
    txns: {
      filterHover: '#ECF4FC',
    },
    // ^^^ code above is deprecated - please don't add new components to this object - 'components' is not for storing settings!!! - it's for overrides
  },
  applyOpacityToHex,
  replaceRgbaColor,
};

const themeApp = quickenTheme;

// This function ensures that arrays are overridden in the merge instead of concatenated
//    - might be unnecessary but left here just in case
function mergeCustomizer(objValue, srcValue) {
  if (_.isArray(objValue)) {
    return srcValue;
  }
  return undefined; // lets lodash handle the merge
}

// don't use this method directly ever
export const getThemeByKey = createCachedSelector(
  (themeKey) => themeKey || '',
  (themeKey) => {
    const themeDynamic = getThemeOverrideByKey(themeKey);
    const themeMerged = _.mergeWith(  // deep merge of objects
      {},               // destination object for merge
      themeBase,     // base theme consistent across the web app
      themeApp,         // ACME or Quicken specific theme
      themeDynamic, // applying custom theme if there is one
      mergeCustomizer,  // ensures arrays are overridden in the merge instead of concatenated
    );
    const theme = createTheme(themeMerged);
    return theme;
  },
)({
  keySelector: (themeKey) => themeKey || '',
  cacheObject: new LruObjectCache({ cacheSize: 5 }),
});
