/* eslint-disable react/prop-types */
/* eslint-disable react/forbid-foreign-prop-types */
/* eslint-disable react/no-children-prop */

/* --- MAIN --- */
import React from 'react';
import { Set as ImmutableSet } from 'immutable';

/* --- MUI --- */
import Box from '@mui/material/Box';
import EditIcon from '@mui/icons-material/Edit';
import AppleIcon from '@mui/icons-material/Apple';
import AndroidIcon from '@mui/icons-material/Android';

/* --- LOCAL COMPONENTS--- */
import PayeeAutocomplete from 'components/PayeeAutocomplete';
import QButton, { QButtonVariantEnum } from 'components/QButton';
import QDateRangePicker from 'components/QDateRangePicker';
import CategoryField from 'components/QuickenControls/CategoryField';
import AccountField from 'components/QuickenControls/AccountField';
import AutoTagsField from 'components/AutoTagsField';
import AttachmentsField from 'components/AttachmentsField';
import SymbolLookupField from 'components/SymbolLookupField';
import QSwitch from 'components/QSwitch';
import SearchBox from 'components/SearchBox';
import QTextButton from 'components/QTextButton';
import QPanelButton from 'components/QPanelButton';
import QAmountField from 'components/QAmountField';
import QResourcePicker from 'components/QResourcePicker';
import QFilterSelect from 'components/QFilterSelect';
import { FilterObject } from 'components/QFilterSelect/types';
import CategoryReviewPopupCard from 'components/CategoryReviewPopupCard';
import AmountSpan from 'components/QuickenControls/AmountField/AmountSpan';
import QInputSpan from 'components/QInputSpan';
import DownshiftField from 'components/QuickenControls/DownshiftField';
import QChoiceChips from 'components/QuickenControls/QChoiceChips';
import ThreeStateOption from 'components/ThreeStateOption';
import SPayeeField from 'components/SPayeeField';
import AmountField, { ShowSignEnum, ShowColorEnum } from 'components/QuickenControls/AmountField';
import InvestmentTransactionTypeField from 'components/InvestmentTransactionTypeField';
import TagsField from 'components/QuickenControls/TagsField';

import { mkSharedComponent } from '../Wrappers/factories';

const config = {
  name: 'Inputs',
  id: 'INPUTS',
  children: [
    {
      name: 'Amounts',
      id: 'AMOUNTS',
      children: [
        mkSharedComponent({
          name: 'Amount Field (controlled)',
          description: 'AmountField',
          componentPropTypes: AmountField.propTypes,
          id: 'AMOUNT_FIELD',
          searchTags: ['amount', 'input', 'field', 'money'],
          defaultState: { amount: 56.05 },
          examples: [
            {
              label: 'Omit Cents',
              description: 'omit cents from being shown',
              props: { editable: false, omitCents: true },
            },
            {
              label: 'Show Cents',
              description: 'show cents',
              props: { omitCents: false },
            },
            {
              label: 'Read Only',
              description: 'make the input field read only',
              props: { onChange: undefined, submitOnBlur: undefined, editable: false, currencySymbol: 'EUR' },
            },
            {
              label: '+12.34 - auto optimistic colors/sign',
              description: 'positive = green color / positive = + sign',
              props: { showColor: ShowColorEnum.POSITIVE_ONLY, showSign: ShowSignEnum.POSITIVE_ONLY, value: +12.34, onChange: undefined, submitOnBlur: undefined, editable: false, currencySymbol: 'USD' },
            },
            {
              label: '-12.34 - auto optimistic colors/sign',
              description: 'negative = no color / negative = no sign',
              props: { showColor: ShowColorEnum.POSITIVE_ONLY, showSign: ShowSignEnum.POSITIVE_ONLY, value: -12.34, onChange: undefined, submitOnBlur: undefined, editable: false, currencySymbol: 'USD' },
            },
          ],
          component: (props) => (
            <AmountField
              editable
              value={props.state.amount}
              currencySymbol="USD"
              onChange={(e) => props.setstate({ amount: Number(e.target.value) })}
              submitOnBlur
              {...props.exampleProps}
            />
          ),
          // todo: add docs/readme render on bottom to display the:
          // `Object.values(ShowColorEnum).map((showColor) => (`
          // section from index-prev
        }),

        mkSharedComponent({
          name: 'QAmountField',
          description: 'QAmountField',
          componentPropTypes: QAmountField.propTypes,
          id: 'QAmountField',
          searchTags: ['amount', 'input', 'field', 'money'],
          defaultState: { amount: 56.05 },
          examples: [
            {
              label: 'Omit Cents',
              description: 'omit cents from being shown',
              props: { editable: true, omitCents: true },
            },
            {
              label: 'Show Cents',
              description: 'show cents',
              props: { omitCents: false },
            },
            {
              label: 'Read Only',
              description: 'make the input field read only',
              props: { onChange: undefined, editable: false, currency: 'EUR' },
            },
          ],
          component: (props) => (
            <QAmountField
              editable
              value={props.state.amount}
              currency="USD"
              onChange={(e) => props.setstate({ amount: Number(e.target.value) })}
              {...props.exampleProps}
            />
          ),
        }),

        mkSharedComponent({
          name: 'AmountSpan',
          description: 'AmountSpan',
          componentPropTypes: AmountSpan.propTypes,
          id: 'AMOUNT_SPAN',
          searchTags: ['amount', 'input', 'field', 'money', 'span'],
          defaultState: { amount: 56.05 },
          examples: [
            {
              label: 'Default',
              props: {},
            },
            {
              label: 'Omit cents',
              props: { omitCents: true },
            },
            {
              label: 'Read Only',
              description: 'make the input field read only',
              props: { editable: false },
            },
            {
              label: "Don't show sign, BROKEN?",
              props: { showSign: false },
            },
          ],
          component: (props) => (
            <AmountSpan
              currencyString="USD"
              editable
              initialValue={props.state.amount}
              onBlur={(amount) => props.setstate({ amount })}
              {...props.exampleProps}
            />
          ),
        }),
      ],
    },
    mkSharedComponent({
      name: 'Payee Autocomplete',
      description: 'PayeeAutocomplete',
      componentPropTypes: PayeeAutocomplete.propTypes,
      searchTags: ['payees', 'input'],
      id: 'PAYEE_AUTOCOMPLETE',
      defaultState: {
        inputValue: '',
      },
      component: (props) => (
        <PayeeAutocomplete
          inputValue={props.state.inputValue}
          onInputChange={(event, value, _reason) => (event || value) && props.setstate({ inputValue: value })}
          textFieldProps={{
            fullWidth: true,
            required: true,
            variant: 'outlined',
            margin: 'normal',
            helperText: 'helper text',
            style: { width: 320 },
          }}
        />
      ),
    }),
    mkSharedComponent({
      name: 'Investment Transaction Type Field',
      description: 'InvestmentTransactionTypeField',
      componentPropTypes: InvestmentTransactionTypeField.propTypes,
      searchTags: ['investment', 'transaction', 'type', 'field'],
      id: 'INVESTMENT_TRANSACTION_TYPE_FIELD',
      defaultState: {
        value: 'STOCK_DIVIDEND',
      },
      component: (props) => (
        <InvestmentTransactionTypeField
          value={props.state.value}
          onChange={(value) => props.setstate({ value })}
        />
      ),
    }),
    mkSharedComponent({
      name: 'Date Range Picker',
      id: 'DATE_RANGE_PICKER',
      searchTags: ['dates', 'select', 'popper', 'input'],
      examples: [
        {
          label: 'Date Range Select',
          description: 'set component to select a range of dates',
          props: { range: true },
        },
        {
          label: 'Date Select',
          description: 'set component to select a single date',
          props: { range: false },
        },
      ],
      defaultState: {
        anchorEl: null,
      },
      componentPropTypes: QDateRangePicker.propTypes,
      component: (props) => (
        <>
          <QButton variant={'contained'} onClick={(e) => props.setstate({ anchorEl: e.currentTarget })}>
            Click Me
          </QButton>
          <QDateRangePicker
            anchorEl={props.state.anchorEl}
            onClose={() => props.setstate({ anchorEl: null })}
            onSelect={() => props.setstate({ anchorEl: null })}
            range
            {...props.exampleProps}
          />
        </>
      ),
    }),

    mkSharedComponent({
      name: 'Account Field',
      componentPropTypes: AccountField.propTypes,
      searchTags: ['accounts', 'input'],
      id: 'ACCOUNT_FIELD',
      defaultState: {
        selectedAccount: null,
        outlined: true,
      },
      examples: [
        {
          label: 'With Outline',
          description: '',
          props: { variant: 'outlined' },
        },
        {
          label: 'Without Outline',
          description: '',
          props: {},
        },
      ],
      component: (props) => (
        <AccountField
          onSelected={(selectedAccount) => props.setstate({ selectedAccount })}
          required
          helperText="helper text"
          label="Account"
          {...props.exampleProps}
        />
      ),
    }),

    mkSharedComponent({
      name: 'Category Field',
      componentPropTypes: CategoryField.propTypes,
      id: 'CATEGORY_FIELD',
      searchTags: ['categories', 'select', 'popper', 'input'],
      examples: [
        {
          label: 'Editable and Creatable',
          description: 'select, edit, and create a category',
          props: { editable: true, createEnabled: true },
        },
        {
          label: 'Editable Long',
          description: 'select a category, use long names',
          props: { editable: true, longCats: true },
        },
        {
          label: 'Editable Short',
          description: 'select a category, use short names',
          props: { editable: true },
        },
        {
          label: 'Read Only',
          description: 'Read Only category field',
          props: {},
        },
      ],
      defaultState: {
        coaValue: null,
      },

      component: (props) => (
        <CategoryField
          value={props.sharedstate.coaValue}
          onChange={(newCOA) => props.setsharedstate({ coaValue: newCOA })}
          {...props.exampleProps}
        />
      ),
    }),

    mkSharedComponent({
      name: 'Tags Field',
      description: 'TagsField',
      componentPropTypes: TagsField.propTypes,
      id: 'TagsField',
      searchTags: ['tag', 'tags', 'select', 'popper', 'input', 'field'],
      examples: [
        {
          label: 'Selectable, Deleteble and Creatable',
          description: 'select, delete and create a tag',
          props: {
            disableUnderline: true,
          },
        }, {
          label: 'Outlined',
          description: 'Outlined',
          props: {
            label: 'Tags',
            placeholder: null,
            margin: 'normal',
            textFieldVariant: 'outlined',
          },
        },
      ],
      defaultState: {
        tags: null,
      },

      component: (props) => (
        <TagsField
          value={props.state.tags}
          onChange={(value) => props.setstate({ tags: value })}
          {...props.exampleProps}
        />
      ),
    }),

    mkSharedComponent({
      name: 'Auto Tags Field',
      componentPropTypes: AutoTagsField.propTypes,
      searchTags: ['tags', 'input'],
      id: 'AUTO_TAGS_FIELD',
      defaultState: {
        tags: ['tag1', 'tag2'],
      },
      examples: [
        {
          label: 'Controlled',
          description: '',
          props: { defaultValue: undefined },
        },
        {
          label: 'Uncontrolled',
          description: '',
          props: { value: undefined },
        },
      ],
      component: (props) => (
        <AutoTagsField
          style={{ minWidth: 320 }}
          value={props.state.tags || []}
          defaultValue={props.state.tags || ['tag1', 'tag2']} // why? because defaultState is not set on first invocation, and subsequent values ignored
          onChange={(e, val) => props.setstate({ tags: val })}
          margin="normal"
          variant="outlined"
          fullWidth
          helperText="use space as separator"
          {...props.exampleProps}
        />
      ),
    }),

    mkSharedComponent({
      name: 'Attachments Field',
      componentPropTypes: AttachmentsField.propTypes,
      searchTags: ['docs', 'attachments', 'documents', 'input'],
      id: 'ATTACHMENTS_FIELD',
      defaultState: {
        attachments: [{ name: 'file1.txt' }, { name: 'file2.jpg' }],
        dropzoneRef: null,
      },
      examples: [
        {
          label: 'Outlined',
          description: '',
          props: { variant: 'outlined' },
        },
        {
          label: 'Standard',
          description: '',
          props: { variant: 'standard' },
        },
      ],
      component: (props) => (
        <AttachmentsField
          attachments={props.state.attachments}
          onChange={(_event, newAttachments, _reason, _details) => props.setstate({ attachments: newAttachments })}
          textFieldProps={{
            fullWidth: true,
            required: true,
            variant: 'outlined',
            margin: 'normal',
            helperText: 'helper text',
            style: { minWidth: 320 },
          }}
          {...props.exampleProps}
        />
      ),
    }),

    mkSharedComponent({
      name: 'QButton',
      componentPropTypes: QButton.propTypes,
      searchTags: ['buton', 'buttn'],
      id: 'Q_BUTTON',
      defaultState: {},
      examples: [
        {
          label: 'Default',
          description: 'variant={undefined}',
          props: { variant: undefined, children: 'button' },
        },
        {
          label: 'Contained',
          description: `variant={"${QButtonVariantEnum.CONTAINED}"}`,
          props: { variant: QButtonVariantEnum.CONTAINED, children: 'button' },
        },
        {
          label: 'Outlined',
          description: `variant={"${QButtonVariantEnum.OUTLINED}"}`,
          props: { variant: QButtonVariantEnum.OUTLINED, children: 'button' },
        },
        {
          label: 'Outlined Basic',
          description: `variant={"${QButtonVariantEnum.OUTLINED_BASIC}"}`,
          props: { variant: QButtonVariantEnum.OUTLINED_BASIC, children: 'button' },
        },
        {
          label: 'Delete',
          description: `variant={"${QButtonVariantEnum.DELETE}"}`,
          props: { variant: QButtonVariantEnum.DELETE, children: 'button' },
        },
        {
          label: 'Mini',
          props: { mini: true, children: 'button' },
        },
        {
          label: 'With start icon',
          props: { children: 'edit', startIcon: <EditIcon /> },
        },
      ],
      component: (props) => (
        <Box display={'flex'} width={200} justifyContent={'space-around'}>
          <QButton variant={'contained'} children={'QButton'} {...props.exampleProps} />
          <QButton variant={'contained'} children={'QButton'} {...props.exampleProps} disabled />
        </Box>
      ),
    }),

    mkSharedComponent({
      name: 'QTextButton',
      componentPropTypes: QTextButton.propTypes,
      searchTags: ['buton', 'buttn'],
      id: 'Q_TEXT_BUTTON',
      defaultState: {},
      examples: [
        {
          label: 'Regular',
          props: { title: 'button' },
        },
        {
          label: 'Override Typography Variant',
          props: { title: 'button', variant: 'h3' },
        },
      ],
      component: (props) => <QTextButton {...props.exampleProps} />,
    }),

    mkSharedComponent({
      name: 'QPanelButton',
      componentPropTypes: QPanelButton.propTypes,
      searchTags: ['buton', 'buttn', 'panel', 'choices', 'selection', 'arrow', 'next'],
      id: 'Q_PANEL_BUTTON',
      defaultState: {},
      examples: [
        {
          label: 'Panel is clickable',
          props: {},
        },
        {
          label: 'Hide ">" Button',
          props: {
            hideArrow: true,
          },
        },
        {
          label: 'No subtitle',
          props: {
            subtitle: undefined,
          },
        },
      ],
      component: (props) => (
        <div>
          <QPanelButton
            key={1}
            title="Title goes here"
            subtitle="Subtitle goes here"
            startIcon={<AppleIcon />}
            {...props.exampleProps}
          />
          <QPanelButton
            key={2}
            title="Title goes here"
            subtitle="Subtitle goes here"
            startIcon={<AndroidIcon />}
            {...props.exampleProps}
          />
        </div>
      ),
    }),

    mkSharedComponent({
      name: 'SymbolLookupField',
      componentPropTypes: SymbolLookupField.propTypes,
      searchTags: ['symbol', 'bank', 'holding', 'ticker', 'stock', 'investment'],
      id: 'SYMBOL_LOOKUP_FIELD',
      defaultState: { symbol: '' },
      examples: [
        {
          label: 'Standard',
          props: { variant: 'standard' },
        },
        {
          label: 'Filled',
          props: { variant: 'filled' },
        },
        {
          label: 'Outlined',
          props: { variant: 'outlined' },
        },
      ],
      component: (props) => (
        <div style={{ display: 'flex' }}>
          <SymbolLookupField
            value={props.state.symbol}
            onInputValueChange={(symbol) => props.setstate({ symbol })}
            {...props.exampleProps}
          />
        </div>
      ),
    }),

    mkSharedComponent({
      name: 'QSwitch',
      description: '2 second delay being simulated',
      componentPropTypes: QSwitch.propTypes,
      searchTags: ['toggle', 'two', 'qtoggle', 'delay'],
      id: 'Q_SWITCH',
      defaultState: { checked: false },
      examples: [
        {
          label: 'With Progress',
          props: { showUncheckProgress: true, showCheckProgress: true },
        },
        {
          label: 'Without Progress',
          props: { showUncheckProgress: false, showCheckProgress: false },
        },
      ],
      component: (props) => (
        <QSwitch
          checked={props.sharedstate.checked}
          onChange={() => setTimeout(() => props.setsharedstate({ checked: !props.sharedstate.checked }), 2000)}
          showUncheckProgress
          showCheckProgress
          {...props.exampleProps}
        />
      ),
    }),

    mkSharedComponent({
      name: 'SearchBox',
      componentPropTypes: SearchBox.propTypes,
      searchTags: ['lookup', 'downshift', 'find', 'select'],
      id: 'SEARCH_BOX',
      defaultState: { key: null },
      examples: [
        {
          label: 'With autoSearch',
          props: { autoSearch: true },
        },
        {
          label: 'Manual Search',
          props: { autoSearch: false },
        },
        {
          label: 'Collapsable Search',
          props: { autoSearch: false, collapsable: true },
        },
      ],
      component: (props) => (
        <SearchBox
          autoFocus={false}
          onSearch={(key) => props.setsharedstate({ key })}
          initialValue={props.sharedstate.key}
          placeholder="Type to Search"
          {...props.exampleProps}
        />
      ),
    }),

    mkSharedComponent({
      name: 'QResourcePicker',
      componentPropTypes: QResourcePicker.propTypes,
      searchTags: ['lookup', 'resources', 'category', 'categories', 'payees', 'tags', 'select', 'search', 'select'],
      id: 'Q_RESOURCE_PICKER',
      defaultState: { value: ImmutableSet() },
      examples: [
        {
          label: 'Categories',
          props: { resourceType: 'categories' },
        },
        {
          label: 'Tags',
          props: { resourceType: 'tags' },
        },
        {
          label: 'Payees',
          props: { resourceType: 'payees' },
        },
        {
          label: 'Accounts',
          props: { resourceType: 'accounts' },
        },
        {
          label: 'No header',
          props: { noHeader: true },
        },
      ],
      component: (props) => (
        <Box>
          <QResourcePicker
            resourceType="categories"
            selectedItems={props.state.value}
            onSelect={(value) => props.setstate({ value })}
            // onChange={(value) => props.setstate({ value })}
            pageSize={10}
            {...props.exampleProps}
          />
        </Box>
      ),
    }),

    mkSharedComponent({
      name: 'QFilterSelect',
      componentPropTypes: QFilterSelect.propTypes,
      searchTags: ['lookup', 'resources', 'category', 'categories', 'payees', 'tags', 'select', 'search', 'select'],
      id: 'Q_FILTER_SELECT',
      defaultState: { anchorEl: false, object: null },
      examples: [
        {
          label: 'Allow Filter Out',
          props: { allowFilterOut: true },
        },
        {
          label: 'Disallow Filter Out',
          props: { allowFilterOut: false },
        },
      ],
      component: (props) => (
        <Box>
          <QButton onClick={(e) => props.setstate({ anchorEl: e.target })}>OPEN</QButton>
          {props.state.anchorEl && (
            <QFilterSelect
              popover
              initialFilter={props.state.object}
              anchorEl={props.state.anchorEl}
              allowFilterOut
              onClose={() => props.setstate({ anchorEl: null })}
              onApply={(filterObj) => {
                props.setstate({ anchorEl: null, object: new FilterObject(filterObj) });
              }}
              {...props.exampleProps}
            />
          )}
        </Box>
      ),
    }),

    mkSharedComponent({
      name: 'Category Review Card, BROKEN!!',
      componentPropTypes: CategoryReviewPopupCard.propTypes,
      searchTags: ['check', 'resources', 'category', 'categories'],
      id: 'CATEGORY_REVIEW_CARD',
      defaultState: { anchorEl: false, coa: null, amount: 0 },
      component: (props) => (
        <Box>
          <CategoryField value={props.state.coa} onChange={(newCOA) => props.setstate({ coa: newCOA })} editable />
          <QButton onClick={(e) => props.setstate({ anchorEl: e.target })}>OPEN</QButton>
          {props.state.anchorEl && (
            <CategoryReviewPopupCard
              coa={props.state.coa}
              anchorEl={props.state.anchorEl}
              onClose={() => props.setstate({ anchorEl: null })}
              open
              onSetValueFromGraph={(amount) => props.setstate({ amount })}
            />
          )}
        </Box>
      ),
    }),

    mkSharedComponent({
      name: 'QInputSpan',
      componentPropTypes: QInputSpan.propTypes,
      searchTags: ['input', 'span', 'grow', 'field'],
      id: 'Q_INPUT_SPAN',
      defaultState: { value: 'type to see the span grow' },
      examples: [
        {
          label: 'Single line',
          props: {},
        },
        {
          label: 'Multiline, BROKEN',
          props: { multiline: true },
        },
      ],
      component: (props) => (
        <QInputSpan
          defaultValue={props.sharedstate.value}
          onChange={(value) => props.setsharedstate({ value })}
          {...props.exampleProps}
        />
      ),
    }),

    mkSharedComponent({
      name: 'QChoice Chips',
      componentPropTypes: QChoiceChips.propTypes,
      searchTags: ['multi', 'select', 'chips', 'choose', 'three', 'state', 'options'],
      id: 'Q_CHOICE_CHIPS',
      defaultState: { items: ['item1', 'item2', 'item3'], selectedItems: [] },
      examples: [
        {
          label: 'Single select controlled',
          props: {},
        },
        {
          label: 'Single select uncontrolled',
          props: { selectedItems: undefined, defaultSelectedItems: [] },
        },
        {
          label: 'Multi select controlled',
          props: { multiple: true },
        },
        {
          label: 'Multi select uncontrolled',
          props: { selectedItems: undefined, defaultSelectedItems: [], multiple: true },
        },
      ],
      component: (props) => (
        <QChoiceChips
          items={props.state.items}
          selectedItems={props.sharedstate.selectedItems}
          labelFromItem={(item) => item}
          keyFromItem={(item) => item}
          onSelected={(selectedItems) => props.setsharedstate({ selectedItems })}
          {...props.exampleProps}
        />
      ),
    }),

    mkSharedComponent({
      name: 'Three state option',
      componentPropTypes: ThreeStateOption.propTypes,
      searchTags: ['multi', 'select', 'choose', 'three', 'state', 'options'],
      id: 'THREE_STATE_OPTION',
      defaultState: { value: null },
      component: (props) => (
        <ThreeStateOption value={props.state.value} onChange={(value) => props.setstate({ value })} onOffText={'On'} />
      ),
    }),

    mkSharedComponent({
      name: 'Downshift field',
      componentPropTypes: DownshiftField.propTypes,
      searchTags: ['down', 'select', 'choose', 'field', 'search', 'options'],
      id: 'DOWNSHIFT_FIELD',
      defaultState: { items: ['item1', 'item2', 'item3'], selectedItem: [] },
      component: (props) => (
        <DownshiftField
          items={props.state.items}
          initialItemSelected={props.state.items[1]}
          initialInputValue={props.state.items[1]}
          itemToString={(item) => item}
          itemKey={(item) => item}
          allowNonListedItems
          autoFocus={false}
          onSelected={(selectedItem) => props.setstate({ selectedItem })}
          onInputValueChange={(search) => props.setstate({ search })}
        />
      ),
    }),
    mkSharedComponent({
      name: 'Simplifi Payee Field',
      componentPropTypes: SPayeeField.propTypes,
      searchTags: ['payee', 'names', 'list', 'simplifi', 'spayeefield'],
      id: 'S_PAYEE_FIELD',
      defaultState: { value: 'Default Payee', lastOnChange: {} },
      component: (props) => (
        <>
          <div>
            {JSON.stringify(props.state.lastOnChange)}
          </div>
          <SPayeeField
            defaultValue={props.state.value}
            onChange={(_event, value, reason) => props.setstate({ lastOnChange: { value, reason }, value })}
          />
        </>
      ),
    }),
  ],
};

export default config;
