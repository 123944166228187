import React, { FC, memo, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { usePrevious } from 'react-use';
import { isTablet } from 'react-device-detect';
import { v4 as uuidv4 } from 'uuid';
import noop from 'lodash/noop';
import type { List as ImList } from 'immutable';

import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import TextField, { TextFieldVariants } from '@mui/material/TextField';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import ErrorIcon from '@mui/icons-material/Error';
import CircularProgress from '@mui/material/CircularProgress';

import RootState from 'companion-app-components/utils/redux-store/rootState';
import { getLogger } from 'companion-app-components/utils/core';
import { accountsSelectors } from 'companion-app-components/flux/accounts';
import { categoriesTypes, categoriesActions, categoriesSelectors } from 'companion-app-components/flux/categories';
import { chartOfAccountsUtils, chartOfAccountsTypes, chartOfAccountsSelectors } from 'companion-app-components/flux/chart-of-accounts';

import { getMostPopularCatsByAccountId } from 'data/transactions/selectors';
import NestedQMenu from 'components/NestedQMenu';
import QTip from 'components/QuickenControls/QTip';
import QIconButton from 'components/QIconButton';
import QTypography from 'components/MUIWrappers/QTypography';
import { safeRefIn } from 'utils/utils';
import { coaIncludedInList, getDisplayName, isTransfer } from '../helpers';
import { CategoryFieldFilterOption, CategoryFieldMenuItem } from '../types';

const log = getLogger('components/QuickenControls/CategoryFieldEditable/index.js');
// eslint-disable-next-line @typescript-eslint/no-var-requires
const isIe = require('is-iexplorer');

const inputHeight = isIe ? 27 : 24;

const NONE_COA_NODE = {
  id: 'NONE',
  coa: { type: 'NONE', id: '0' },
  name: '- None -',
  fullPathName: '- None -',
  inList: true,
};

type StateProps = {
  tfValue?: string | null;
  isTransfer?: boolean | null;
  query?: string | null;
  showMenu?: boolean | null;
  placeholder?: string | null;
  inProgress?: boolean;
  categoriesList?: ImList<chartOfAccountsTypes.ChartOfAccountNodeProps> | null;
  newCategoryClientId?: string;
};

interface CategoryFieldEditableProps {
  style?: Record<string, any>;
  width?: number | string;
  variant?: string;
  editable?: boolean;
  onChange?: (coa: chartOfAccountsTypes.ChartOfAccount) => void;
  value?: chartOfAccountsTypes.ChartOfAccountProps;
  initialFocus?: boolean;
  name?: string;
  id?: string;
  clickable?: boolean;
  returnSelectionMethod?: boolean;
  longCats?: boolean;
  onlyL1?: boolean;
  allowBlank?: boolean;
  allowNone?: boolean;
  classNameWhenNotEditable?: string;
  tooltip?: boolean; // show a tooltip on static value?
  disableUnderline?: boolean;
  autoFocus?: boolean;
  history?: Record<string, any>;
  fontSize?: string;
  label?: string;
  margin?: 'dense' | 'normal' | 'none';
  inputRef?: any;
  onBlur?: (obj: Record<string, any>) => void;
  error?: boolean;
  createEnabled?: boolean;
  recommendedCategoryType?: string;
  filterFn?: (coa?: chartOfAccountsTypes.ChartOfAccount | null) => any;
  inputStyle?: string;
  transferOnly?: boolean;
  stType?: string;
  InputProps?: Record<string, any>;
  textFieldProps?: Record<string, any>;
  className?: string;
  numPopularCats?: number;
  openMenu?: boolean;
  blurOnMenuClose?: boolean;
  classes?: Record<string, any>;
  header?: Record<string, any>;
  menuClose?: () => void;
  textFieldVariant?: TextFieldVariants;
  coaNodesById?: Record<string, any>;
  sharedcomponentid?: string;
  context?: string;
}

const CategoryFieldEditable: FC<CategoryFieldEditableProps> = (props) => {
  const theme: Record<string, any> = useTheme();
  const dispatch = useDispatch();
  const coaTreeNormal = useSelector((state: RootState) => chartOfAccountsSelectors.getChartOfAccountsTree(state, props));
  const coaTreeTransfer = useSelector((state: RootState) => chartOfAccountsSelectors.getTransferCOATree(state));
  const coaTree = props.transferOnly ? coaTreeTransfer : coaTreeNormal;
  const categoriesById = useSelector((state: RootState) => categoriesSelectors.getCategoriesById(state));
  const accountsById = useSelector((state: RootState) => accountsSelectors.getAccountsById(state));
  const popularCats = useSelector((state: RootState) => getMostPopularCatsByAccountId(state));

  const getCoaNode = useCallback((coaId: string) => {
    let ret = props.coaNodesById ? props.coaNodesById.get(coaId) : null;
    if (!ret && coaId === 'NONE') {
      return NONE_COA_NODE;
    }

    if (props.value && props.coaNodesById && chartOfAccountsUtils.isTransferCoaNotFound(props.value)) {
      ret = props.coaNodesById.get(chartOfAccountsUtils.TRANSFER_NOT_FOUND_COA_ID);
    }
    return ret;
  }, [props.coaNodesById, props.value]);

  const coaId = chartOfAccountsUtils.getCoaId(props.value, props.allowBlank); // allow blank
  const coaNode = getCoaNode(coaId);
  const displayName = getDisplayName('', Boolean(props.longCats), coaNode);

  const [state, setState] = useState<StateProps>({
    tfValue: coaId === 'BLANK' ? '' : displayName,
    isTransfer: isTransfer(coaNode),
    query: null,
    showMenu: false,
    placeholder: null,
    inProgress: false,
    categoriesList: null,
  });
  const currentHover = useRef<null | CategoryFieldMenuItem>(null);
  const inputRef = useRef<null | HTMLInputElement>(null);
  const fieldRef = useRef<null | HTMLDivElement>(null);

  const handleRequestClose = () => {
    log.log('On Request Close...');
    setState((prevState) => ({
      ...prevState,
      showMenu: false,
    }));
    if (props.menuClose) {
      props.menuClose();
    }
  };

  const menuOnChange = (item) => {
    if (item) {
      if (item.id === 'viewAll') {
        setState((prevState) => ({
          ...prevState,
          query: null,
          showMenu: true,
        }));
        return;
      }
      if (props.onChange) {
        if (props.returnSelectionMethod) {
          props.onChange(item.coa);
        } else {
          props.onChange(item.coa);
        }
      }
      handleRequestClose();
      setState((prevState) => ({
        ...prevState,
        query: null,
      }));
      if (props.menuClose) {
        props.menuClose();
      }
    }
  };

  const renderCOAMenuNode = (objCoaNode) => {
    if (!props.onlyL1 && objCoaNode.children) {
      const menuItems = objCoaNode.children.map((childNode) => (
        renderCOAMenuNode(childNode)
      ));
      return { label: objCoaNode.name, value: objCoaNode, subMenu: menuItems };
    }
    if (objCoaNode.inList && (!props.filterFn || props.filterFn(objCoaNode.coa)) &&
      coaIncludedInList(objCoaNode.coa, categoriesById, accountsById, props.stType)) {
      return { label: objCoaNode.name, value: objCoaNode };
    }

    return { nop: true };
  };

  const buildMatchedString = (match, indexOfMatch) => {
    const parentSplit = match.indexOf(':');
    if (parentSplit !== -1 && parentSplit < indexOfMatch && state.query) { // need to grey out parent
      return (
        <QTypography>
          <Box component="span" sx={{ color: theme.palette.text.secondary }}>
            {match.slice(0, parentSplit)}
          </Box>
          <span>{match.slice(parentSplit, indexOfMatch)}</span>
          <Box component="span" sx={{ fontWeight: theme.typography.fontWeightMedium }}>
            {match.slice(indexOfMatch, indexOfMatch + state.query.length)}
          </Box>
          <span>{match.slice(indexOfMatch + state.query.length)}</span>
        </QTypography>
      );
    }

    const index = indexOfMatch === -1 ? 0 : indexOfMatch;
    let queryLength = 0;
    if (index && state.query) {
      queryLength = index + state.query.replace(':', ' : ').length;
    }

    // no parent so no greying out
    return (
      <QTypography>
        {match.slice(0, index)}
        <Box component="span" sx={{ fontWeight: theme.typography.fontWeightMedium }}>
          {match.slice(index, queryLength)}
        </Box>
        {match.slice(queryLength)}
      </QTypography>
    );
  };

  const filterOptions = (options, query) => {
    let items: CategoryFieldFilterOption[] = [];
    const lowerCasedQuery = query?.toLowerCase();

    options.forEach((option) => {
      if (!option.nop && !option.isSubheader && option.value && option.value.fullPathName
        && option.label && option.value.fullPathName?.toLowerCase().indexOf(query?.toLowerCase()) !== -1
        && (!props.filterFn || props.filterFn(option.value.coa))) {
        const name = option.value.fullPathName.replace(':', ' : ');
        const boldIndex = name?.toLowerCase().indexOf(lowerCasedQuery);
        items.push({
          label: name,
          customRender: buildMatchedString(name, boldIndex),
          value: option.value,
        });
      }
      if (option.subMenu) {
        items = items.concat(filterOptions(option.subMenu, query));
      }
    });
    return items;
  };

  const dropDownClick = () => {
    setState((prevState) => ({
      ...prevState,
      showMenu: !state.showMenu,
      query: null,
    }));
    if (inputRef.current) {
      setTimeout(() => inputRef.current?.focus(), 50);
    }
  };

  const handleInputFocus = ({ target }) => {
    target.focus();
    target.select();  // automatically highlights the input value
  };

  const handleQueryChange = ({ target }) => {
    setState((prevState) => ({
      ...prevState,
      query: target.value,
    }));
  };

  const handleKeyDown = (event) => {
    const queryLength = state.query?.length || 0;
    if (state.showMenu && event.key !== 'Tab' && event.key !== 'Enter') {
      event.stopPropagation();
    } else if (event.key !== 'Shift' && event.key !== 'Escape' && event.key !== 'Enter' && event.key !== 'Tab') {
      setState((prevState) => ({
        ...prevState,
        showMenu: !state.showMenu,
      }));
    } else if (event.key === 'Enter' && (state.showMenu || state.query !== null)) {
      event.stopPropagation();
    } else if (event.key === 'Tab' && (state.showMenu || queryLength > 0) && currentHover) {
      if (currentHover.current?.onSelect) {
        event.stopPropagation();  // if need to wait for category creation
        currentHover.current.onSelect();
      } else if (typeof currentHover.current?.value === 'object' && currentHover.current?.value?.coa && props.onChange) {
        props.onChange(currentHover.current?.value?.coa);
      }
    } else if (event.key === 'Escape') {
      handleRequestClose();
    }
  };

  const handleCurrentHover = (newCurrent) => {
    if (currentHover.current !== newCurrent) {
      currentHover.current = newCurrent;
    }
  };

  const handleNewCategoryClick = (
    newCatLabel: string,
    parentNode: chartOfAccountsTypes.ChartOfAccountNodeProps | null = null,
  ) => {
    const newCategoryClientId = uuidv4().toUpperCase();
    let type = props.recommendedCategoryType || categoriesTypes.CategoryTypeEnum.EXPENSE;
    if (parentNode && parentNode.coa && parentNode.coa.type === chartOfAccountsTypes.CoaTypeEnum.CATEGORY && parentNode.coa.id) {
      const parentCategory = categoriesById.get(parentNode.coa.id);
      type = parentCategory && parentCategory.type ? parentCategory.type : categoriesTypes.CategoryTypeEnum.EXPENSE;
    }
    if (assert(newCatLabel, `category name is required (name = '${newCatLabel}') #bug-trap`)) {
      const newCategory = categoriesTypes.mkCategory({
        clientId: newCategoryClientId,
        // @ts-expect-error - FIXME: need to add null type as well for id in CategoryProps in companinon-app-components
        parentId: parentNode ? parentNode.id : '0',
        name: newCatLabel.trim(),
        type: type as categoriesTypes.CategoryType,
      });
      dispatch(categoriesActions.createCategory(
        newCategory,
        { undo: { userMessage: 'Category created.' }, context: props?.context },
      ));
      setState((prevState) => ({
        ...prevState,
        newCategoryClientId,
        showMenu: false,
        query: null,
        placeholder: newCatLabel,
        inProgress: true,
      }));
      setTimeout(() => {
        if (state.newCategoryClientId === newCategoryClientId && state.inProgress) {
          setState((prevState) => ({
            ...prevState,
            inProgress: false,
          }));
        }
      }, 8000);
    }
  };

  const treeHasMainCategory = (name) => state.categoriesList
    ?.some((objCoaNode) => objCoaNode.name?.toLowerCase().trim() === name?.toLowerCase().trim());

  const flipIcon = state.showMenu || state.query !== null;
  const toolTipTitle = state.isTransfer ? `[${state.tfValue}]` : state.tfValue;
  const disableUnderlineObj = props.textFieldVariant === 'outlined' ? {} : { disableUnderline: props.disableUnderline };
  const popularCatsFiltered = props.filterFn ? popularCats.filter((x) => props.filterFn && props.filterFn(x.coa)) : popularCats;
  const prevProps = usePrevious<CategoryFieldEditableProps>(props);
  const prevCategoriesById = usePrevious(categoriesById);

  let menuItems: CategoryFieldMenuItem[] = [];
  let selectedMenuItem;
  if (props.allowNone) {
    menuItems.push(renderCOAMenuNode(NONE_COA_NODE));
    menuItems.push({ nop: true, divider: true });
  }
  if (!state.query && popularCatsFiltered && popularCatsFiltered.size > 0) {
    if (props.numPopularCats && props.numPopularCats > 0) {
      menuItems.push({
        isSubheader: true,
        label: 'Most Used Categories',
      });
    }
    popularCatsFiltered.slice(0, props.numPopularCats).forEach((catRecord, index) => {
      menuItems.push({
        label: chartOfAccountsSelectors.getCoaStringSelector(undefined, catRecord.coa, true),
        value: catRecord,
        subIndent: true,
        divider: index === (popularCatsFiltered.slice(0, props.numPopularCats).size - 1),
      });
    });
  }

  if (props.editable) {
    menuItems = menuItems.concat([{ isSubheader: true, label: 'All Categories' }]);
    // @ts-expect-error - FIXME: some type issue with concatinating menuItems
    menuItems = menuItems.concat(coaTree.map((objCoaNode) =>
      (!props.filterFn || props.filterFn(objCoaNode.coa)) ? renderCOAMenuNode(objCoaNode) : null).toJS());
  }
  menuItems = menuItems.filter((x) => x !== null);

  if (state.query && state.query.trim()) {
    let addCatOption: boolean | undefined | Record<string, any> = props.createEnabled && !treeHasMainCategory(state.query);
    if (addCatOption) {
      addCatOption = {
        customRender: (
          <QTypography>
            Create
            <Box component="span" sx={{ fontWeight: theme.typography.fontWeightMedium }}>
              {` "${state.query}"`}
            </Box>
          </QTypography>
        ),
        onSelect: noop,
        value: 'create-category-bucket',
        label: 'create-category',
        subMenu: [
          {
            label: 'Main Category',
            value: 'create-parent-category',
            onSelect: () => handleNewCategoryClick(state.query || ''),
          },
          {
            label: 'Subcategory of',
            value: 'create-child-category',
            onSelect: noop,
            subMenu: coaTree
              .filter((objCoaNode) => objCoaNode?.coa && (objCoaNode.coa.type === chartOfAccountsTypes.CoaTypeEnum.CATEGORY))
              .map((objCoaNode) => {
                const menuNode = renderCOAMenuNode(objCoaNode);
                return {
                  ...menuNode,
                  subMenu: null,
                  onSelect: () => handleNewCategoryClick(state.query || '', menuNode.value),
                };
              }),
          },
        ],
      };
    }

    menuItems = filterOptions(menuItems, state.query.trim());

    if (menuItems && menuItems.length) {
      selectedMenuItem = menuItems[0];
    } else if (addCatOption) {
      selectedMenuItem = addCatOption;
    }

    if (addCatOption) {
      menuItems.unshift(addCatOption);
    }

    menuItems.push({
      label: 'See All Categories',
      value: 'see-all',
      onSelect: () => {
        setState((prevState) => ({
          ...prevState,
          query: null,
        }));
        if (inputRef.current) {
          inputRef.current.focus();
          inputRef.current.select();
        }
      },
    });
  }

  useEffect(() => {
    if (props.initialFocus && inputRef.current) {
      inputRef.current.focus();
    }
    if (props.openMenu) {
      setState((prevState) => ({
        ...prevState,
        showMenu: true,
      }));
    }
    if (categoriesById) {
      // @ts-expect-error - FIXME: issue with categoriesList
      setState((prevState) => ({
        ...prevState,
        categoriesList: categoriesById.toList(),
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!prevProps) { return; }

    if (props.coaNodesById) {
      if (props.value !== prevProps.value
        || props.coaNodesById !== prevProps.coaNodesById
        || props.longCats !== prevProps.longCats) {
        // TODO: if coaNode cannot be found, set it to uncategorized coaNode
        if (coaNode) {
          setState((prevState) => ({
            ...prevState,
            isTransfer: isTransfer(coaNode),
            tfValue: displayName,
          }));
        } else {
          setState((prevState) => ({
            ...prevState,
            isTransfer: false,
            tfValue: '',
          }));
        }

        if (!props.value ||
          (inputRef && safeRefIn(props, ['value', 'id']) !== safeRefIn(prevProps, ['value', 'id']))) {
          if (inputRef.current && prevProps.autoFocus) {
            if (isTablet) {
              setTimeout(() => {
                if (inputRef.current) {
                  inputRef.current.focus();
                  inputRef.current.select();
                }
              }, 250);
            } else {
              inputRef.current.focus();
              inputRef.current.select();
            }
          }
        }
      }
    }
  }, [props, prevProps, coaNode, displayName]);

  useEffect(() => {
    if (prevCategoriesById !== categoriesById && state.newCategoryClientId) {
      const newCategoryCreated = categoriesById.find((category) => category.clientId === state.newCategoryClientId);
      if (newCategoryCreated) {
        const newNode = props.coaNodesById && props.coaNodesById.get(newCategoryCreated.id);
        const newCOA = newNode && newNode.coa;
        if (props.onChange && newCOA) {
          props.onChange(newCOA);
          setState((prevState) => ({
            ...prevState,
            newCategoryClientId: undefined,
            inProgress: false,
          }));
        }
      }
    }
  }, [state.newCategoryClientId, prevCategoriesById, categoriesById, props]);

  if (props.editable) {
    return (
      <Box
        id={props.id ? `${props.id}-root` : 'category-root'}
        ref={fieldRef}
        sx={{
          ...props.style,
          width: props.width,
        }}
        className={props.classes?.root}
      >
        <TextField
          disabled={state.inProgress}
          onClick={dropDownClick}
          {...props.textFieldProps}
          variant={props.textFieldVariant}
          className={props.className || ''}
          label={props.label}
          value={state.query !== null ? state.query : state.placeholder || state.tfValue || ''}
          onChange={handleQueryChange}
          onKeyDown={handleKeyDown}
          onFocus={handleInputFocus}
          onBlur={state.showMenu ? undefined : props.onBlur}
          autoFocus={props.autoFocus}
          sx={{ width: '100%' }}
          error={props.error}
          name={props.name}
          margin={props.margin}
          autoComplete="off"
          InputProps={{
            ...props.InputProps,
            ...disableUnderlineObj,
            id: props.id ? `${props.id}-input` : 'category-input',
            endAdornment: (
              <>
                {state.inProgress &&  // dont take up precious space for a 5% use case (on create cat)
                  <CircularProgress
                    size={16}
                    sx={{ visibility: state.inProgress ? 'visible' : 'hidden' }}
                  />}
                {props.error && <ErrorIcon sx={{ color: theme.palette.number.negative }} />}
                <QIconButton
                  id={props.id ? `${props.id}-button` : 'category-button'}
                  disabled={state.inProgress}
                  sx={{ color: theme.palette.grey.level6, cursor: 'pointer', fontSize: '23px', opacity: flipIcon && 1 }}
                  aria-label="Open Category List"
                  focusRipple
                  IconComponent={flipIcon ? ExpandLessRoundedIcon : ExpandMoreRoundedIcon}
                  onClick={dropDownClick}
                  size="small-no-padding"
                  TooltipProps={{ enterDelay: 10 }}
                  tabIndex={-1}
                />
              </>
            ),
            sx: {
              paddingTop: 0,
              paddingBottom: 0,
              height: props.textFieldVariant === 'outlined' ? 'auto' : inputHeight,
              lineHeight: '22px',
              fontSize: props.fontSize,
              color: (state.query && state.query !== state.tfValue) || (state.placeholder && state.placeholder !== state.tfValue) ?
                theme.palette.text.secondary : undefined,
              ...(props.InputProps ? props.InputProps.style : {}),
            },
          }}
          // eslint-disable-next-line react/jsx-no-duplicate-props
          inputProps={{
            className: props.inputStyle || '',
            style: { height: 23 },
          }}
          inputRef={props.inputRef ? props.inputRef : inputRef}
        />
        <Box sx={{ margin: 'auto' }}>
          {fieldRef.current && (
            <NestedQMenu
              anchorEl={fieldRef.current}
              header={props.header}
              name="catmenu"
              options={menuItems}
              selectedOption={selectedMenuItem}
              onChange={menuOnChange}
              open={state.showMenu || state.query !== null}
              onClose={() => {
                setState((prevState) => ({
                  ...prevState,
                  query: null,
                }));
                handleRequestClose();
                if (props.onBlur && props.blurOnMenuClose) {
                  props.onBlur({ relatedTarget: 'categoryfield' });
                }
              }}
              currentHover={handleCurrentHover}
            />
          )}
        </Box>
      </Box>
    );
  }

  return (
    <QTip
      wrapOnly
      title={props.tooltip ? toolTipTitle : null}
    >
      <QTypography
        variant={props.variant}
        clickable={props.clickable}
        className={props.classNameWhenNotEditable}
      >
        {state.isTransfer ? `[${state.tfValue}]` : state.tfValue}
      </QTypography>
    </QTip>
  );
};

CategoryFieldEditable.defaultProps = {
  variant: 'body2',
  transferOnly: false,
  numPopularCats: 5,
  width: '100%',
  textFieldVariant: 'standard',
  context: '',
};

export default memo(CategoryFieldEditable);
