import React, { FC, useState } from 'react';
import { useEffectOnce } from 'react-use';
import { makeStyles } from 'tss-react/mui';
import { useSelector, useDispatch } from 'react-redux';

import { tracker } from 'companion-app-components/utils/core';
import { npsActions } from 'companion-app-components/flux/nps';
import { authActions, authSelectors } from 'companion-app-components/flux/auth';

import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleRounded';

import { removeDialog } from 'data/rootUi/actions';

import QButton from 'components/QButton';
import StdDialog from 'components/Dialogs/StdDialog';

export const styles = () => ({
  dialogBackground: {
    backgroundColor: 'unset',
  },
});

const useStyles = makeStyles()(styles);

interface NPSSurveyDialogProps {
  onClose: (event) => void;
  title: string;
  dialogId: string;
}

const NPSSurveyDialog: FC<NPSSurveyDialogProps> = ({ onClose, dialogId, title }) => {
  
  const [feedback, setFeedback] = useState('');
  const [score, setScore] = useState<number | undefined>(undefined);
  const [hoverScore, setHoverScore] = useState<number | undefined>(undefined);
  const [thankYou, setThankYou] = useState(false);

  const { classes } = useStyles();
  const dispatch = useDispatch();

  const isWebFirstDataset = useSelector(authSelectors.getIsWebfirstDataset);

  useEffectOnce(() => tracker.track(tracker.events.nps, { status: 'start' }));

  const handleClose = (event: Event, reason?: string) => {
    let surveyResult;
    if (!thankYou) {
      dispatch(authActions.updateAuth({ surveyRequested: false }));
      tracker.track(tracker.events.nps, {
        status: 'response',
        feedback: feedback.length > 0 ? feedback : undefined,
        rating: score,
      });

      if (reason === 'OK_BUTTON') {
        surveyResult = 'COMPLETED';
      } else if (!score) {
        surveyResult = 'ABANDONED';
      } else {
        surveyResult = 'CANCELLED';
      }
      dispatch(npsActions.postNPSSurveyResultAction({ surveyResult }));
    }

    if (surveyResult === 'COMPLETED') {
      setThankYou(true);
      setTimeout(() => {
        onClose?.(event);
        dispatch(removeDialog(dialogId));
      }, 2500);
    } else {
      onClose?.(event);
      dispatch(removeDialog(dialogId));
    }
  };

  const renderThankYou = () => (
    <DialogContent>
      <CheckCircleOutlineIcon
        color="primary"
        style={{
          width: 64,
          height: 64,
          marginTop: 24,
          marginBottom: 24,
          display: 'block',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      />
      <Typography
        variant="h6"
        align="center"
        style={{
          marginBottom: 24,
        }}
      >
        Thank you.
      </Typography>
      <Typography
        variant="subtitle1"
        align="center"
      >
        Your feedback helps us discover new opportunities to improve Quicken and make sure you have the best possible
        experience.
      </Typography>
    </DialogContent>
  );

  const renderNPS = () => {

    let message;
    if (isWebFirstDataset) {
      message = 'How likely are you to recommend Quicken to a friend or colleague?'; // Quicken Web First
    } else {
      message = 'How likely are you to recommend Quicken on the Web to a friend or colleague who uses Quicken?'; // Quicken
    }

    return (
      <>
        <DialogTitle
          className={classes.dialogBackground}
        >
          {message}
        </DialogTitle>
        <DialogContent>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexWrap: 'nowrap',
            }}
          >
            { [...Array(11).keys()].map((scoreMapped) => (
              <IconButton
                style={{
                  border: scoreMapped === score ? 'solid 2px' : 'solid 1px',
                  width: 32,
                  height: 32,
                  fontSize: 14,
                }}
                key={scoreMapped}
                id={`nps-score-${scoreMapped}`}
                aria-label={String(scoreMapped)}
                color={scoreMapped === hoverScore || scoreMapped === score ? 'primary' : 'default'}
                onClick={() => setScore(scoreMapped)}
                onMouseOver={() => setHoverScore(scoreMapped)}
                onFocus={() => setHoverScore(scoreMapped)}
                onMouseOut={() => setHoverScore(undefined)}
                onBlur={() => setHoverScore(undefined)}
                size="large"
              >
                <div style={{ position: 'absolute' }}>
                  {scoreMapped}
                </div>
              </IconButton>
            )) }
          </div>

          <Typography
            variant="caption"
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexWrap: 'nowrap',
              marginTop: 8,
            }}
          >
            <span>
              Not likely at all
            </span>
            <span>
              Extremely likely
            </span>
          </Typography>

          <br />

          {score !== undefined &&
          <TextField
            autoFocus
            label="What is the primary reason for your score?"
            id="nps-feedback"
            aria-label="What is the primary reason for your score?"
            multiline
            value={feedback}
            onChange={(event) => setFeedback(event.target.value)}
            margin="normal"
            fullWidth
            inputProps={{ maxLength: 255 }}
            helperText={`You can enter up to 255 characters (${255 - feedback.length} remaining).`}
            rows="4"
            maxRows="4"
            variant="outlined"
          />}
        </DialogContent>
        {score !== undefined &&
          <DialogActions>
            <QButton
              id="nps-send"
              aria-label="Send"
              onClick={(event) => handleClose(event, 'OK_BUTTON')}
            >
              Submit
            </QButton>
          </DialogActions>}
      </>
    );
  };

  return (
    <StdDialog
      open
      onClose={(e) => !thankYou ? handleClose(e) : null}
      title={title}
      sharedcomponentid={'DIALOG_TYPE_NPS_SURVEY'}
      showCloseButton={!thankYou}
    >
      {thankYou ? renderThankYou() : renderNPS()}
    </StdDialog>
  );
};

export default NPSSurveyDialog;
