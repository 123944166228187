import React from 'react';
import PropTypes from 'prop-types';

import { getLogger } from 'companion-app-components/utils/core';

import Dialog from '@mui/material/Dialog';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import QButton from 'components/QButton';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import { withStyles } from 'tss-react/mui';
import { Set } from 'immutable';

import TagsField from 'components/QuickenControls/TagsField';
import PayeeField from 'components/QuickenControls/PayeeField';
import ClrField from 'components/QuickenControls/ClrField';
// import FlagField from 'components/QuickenControls/FlagField';
import note from 'assets/note.svg';
import tag from 'assets/tags.svg';
import pie from 'assets/pie.svg';
import profile from 'assets/profile.svg';

import { isAcme } from 'isAcme';
import { isMobile, isTablet } from 'react-device-detect';

import CategoryField from '../QuickenControls/CategoryField/index';
import { styles } from './styles';


const log = getLogger('component/BulkEditPanel/index.js');

// ==============================================================
//
// Presents the form to bulk edit a group of transactions
//
//
export class BulkEditPanel extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      payee: '',
      category: null,
      memo: '',
      tags: null,
      state: '',
      userFlag: null,
      clearFields: new Set(),
    };
  }

  componentDidMount() {
    this.setInitialFocus();
  }

  setInitialFocus = () => {
    const node = document.querySelectorAll('[name="bulk-edit-first-tab"]');

    if (node && node.length > 0) {
      node[0].focus();
    } else {
      setTimeout(() => this.setInitialFocus(), 250);
    }
  };

  //-------------------------------------------------------
  // saveFn
  //
  saveFn = () => {

    const retObj = {};
    if (this.state.payee.trim().length > 0) {
      retObj.payee = this.state.payee;
    }
    if (this.state.memo === ' ' || this.state.memo.trim().length > 0) {
      retObj.memo = this.state.memo === ' ' ? '' : this.state.memo;
    }
    if (this.state.category) {
      retObj.category = this.state.category;
    }
    if (this.state.state && this.state.state.trim().length > 0) {
      retObj.state = this.state.state;
    }
    if (this.state.userFlag) {
      retObj.userFlag = this.state.userFlag;
    }
    const newTags = this.state.tags && this.state.tags.filter((x) => x.id !== '0');
    if (newTags && newTags.size > 0) {
      retObj.tags = newTags;
    }

    this.state.clearFields.forEach((item) => {
      switch (item) {
        case 'category':
          retObj[item] = { id: 0, type: 'UNCATEGORIZED' };
          break;
        case 'tags':
          retObj[item] = [];
          break;
        default:
          retObj[item] = ' ';
      }
    });

    if (retObj.category) {
      retObj.coa = retObj.category;
    }

    this.props.saveFn(retObj);
    // doing a close immediately was causing a crash in MUI, new behavior with MUI 3.3
    // putting in a timeout lets the current thread complete
    setTimeout(() => {
      this.props.closeFn();
    }, 10);
    // this.props.closeFn();
  };

  closeAndExit = () => {
    this.props.closeFn();
  };

  handleCheck = (field) => {
    if (this.state.clearFields.has(field)) {
      this.setState({ clearFields: this.state.clearFields.remove(field) });
    } else {
      this.setState({ [field]: '', clearFields: this.state.clearFields.add(field) });
      if (field === 'category') {
        this.setState({ category: null });
      }
      if (field === 'tags') {
        this.setState({ tags: [] });
      }
    }
  };

  handleChange = (event, field) => {

    if (field === 'category') {
      this.setState({ category: event });
    } else if (field === 'payee') {
      if (event && event.target.value) {
        this.setState({ payee: event.target.value.name });
      }
    } else {
      this.setState({ [field]: event.target.value });
    }
  };

  tagsChange = (tagSet) => {
    log.log('TAG CHANGE RECEIVED ', tagSet);
    this.setState({ tags: tagSet });
  };

  //------------------------------------------------------
  // handleKeyDown
  //

  handleKeyDown = (e) => {

    if (e.key === 'Escape') {
      this.closeAndExit();
      e.preventDefault();
      e.stopPropagation();
    }
    if (e.key === 'Tab') {

      e.stopPropagation();

      // if we are on SAVE then loop back to close
      if (!e.shiftKey && e.target.name === 'bulk-edit-last-tab') {
        document.querySelectorAll('[name="bulk-edit-first-tab"]')[0].focus();
        e.preventDefault();
      }
      if (e.shiftKey && e.target.name === 'bulk-edit-first-tab') {
        document.querySelectorAll('[name="bulk-edit-last-tab"]')[0].focus();
        e.preventDefault();
      }

    }
  };

  renderBulkEdit = (showCheckFields) =>
    (
      <Paper elevation={0}>
        {/* {this.state.hasSplits && */}
        {/* <Typography className={this.props.classes.disclaimer}> */}
        {/*  Split transaction lines will not be affected by these changes */}
        {/* </Typography> */}
        {/* } */}

        {/* -- PAYEE FIELD -- */}
        <Paper
          id="bulk-edit-payee-field"
          className={this.props.classes.editSection}
          elevation={0}
        >
          <img src={profile} alt="profile" className={this.props.classes.icon} />
          <PayeeField
            name="bulk-edit-first-tab"
            editable
            autoFocus
            placeholder=""
            fontSize="14px"
            value={{ payee: (this.state.payee || '') }}
            label="Payee Name"
            onChange={(e) => this.handleChange(e, 'payee')}
            textFieldVariant={'outlined'}
            marginProp={'dense'}
            width={'100%'}
            disableUnderline
            inputRef={(input) => {
              this.focusField = input; // eslint-disable-line react/no-unused-class-component-methods
            }}
            textFieldProps={isAcme ? undefined : { size: 'small' }}
          >
          </PayeeField>
        </Paper>
        {showCheckFields &&
          <FormControlLabel
            classes={{ root: this.props.classes.formLabel, label: this.props.classes.checkboxLabel }}
            control={
              <Checkbox
                color="primary"
                className={this.props.classes.checkbox}
                checked={this.state.clearFields.has('payee')}
                onChange={() => this.handleCheck('payee')}
                value={String(this.state.clearFields.has('payee'))}
              />
            }
            label="Clear payee fields"
          />}

        {/* -- CATEGORY FIELD -- */}
        <Paper
          className={this.props.classes.categoryFieldSection}
          elevation={0}
          onKeyDown={this.handleKeyDown}
          id="bulk-edit-category-field"
        >
          <img src={pie} alt="pie" className={this.props.classes.icon} />
          <CategoryField
            onChange={(coa) => this.handleChange(coa, 'category')}
            value={this.state.category}
            editable
            createEnabled
            longCats={this.props.longCats}
            name="bulkEdit-CatField"
            textFieldVariant={'outlined'}
            margin={'dense'}
            fontSize="14px"
            textFieldProps={isAcme ? undefined : { size: 'small' }}
            allowBlank
            disableUnderline
            label="Category"
          >
          </CategoryField>
        </Paper>
        {showCheckFields &&
          <FormControlLabel
            classes={{ root: this.props.classes.formLabel, label: this.props.classes.checkboxLabel }}
            control={
              <Checkbox
                color="primary"
                className={this.props.classes.checkbox}
                checked={this.state.clearFields.has('category')}
                onChange={() => this.handleCheck('category')}
                value={String(this.state.clearFields.has('category'))}
              />
            }
            label="Uncategorize all (does not affect splits)"
          />}
        {/* TAGS */}
        <Paper
          className={this.props.classes.editSection}
          style={{ display: 'flex', height: '100%' }}
          elevation={0}
          id="bulk-edit-tags-field"
        >
          <img src={tag} alt="tag" className={this.props.classes.icon} />
          <TagsField
            label="Tags"
            placeholder={null}
            value={this.state.tags}
            onChange={this.tagsChange}
            margin={'dense'}
            textFieldVariant={'outlined'}
            textFieldProps={isAcme ? undefined : { size: 'small' }}
          >
          </TagsField>
        </Paper>
        {showCheckFields &&
          <FormControlLabel
            classes={{ root: this.props.classes.formLabel, label: this.props.classes.checkboxLabel }}
            control={
              <Checkbox
                color="primary"
                className={this.props.classes.checkbox}
                checked={this.state.clearFields.has('tags')}
                onChange={() => this.handleCheck('tags')}
                value={String(this.state.clearFields.has('tags'))}
              />
            }
            label="Clear tags fields"
          />}
        {/* -- MEMO or NOTES FIELD -- */}
        <Paper
          id="bulk-edit-notes-field"
          className={this.props.classes.editSection}
          style={{ display: 'flex' }}
          elevation={0}
        >
          <img src={note} alt="note" className={this.props.classes.icon} />
          <TextField
            onChange={(e) => this.handleChange(e, 'memo')}
            onKeyDown={this.handleKeyDown}
            value={this.state.memo}
            variant={'outlined'}
            margin={'dense'}
            fullWidth
            label="Memo"
            size="small"
          />
        </Paper>
        {showCheckFields &&
        <FormControlLabel
          classes={{ root: this.props.classes.formLabel, label: this.props.classes.checkboxLabel }}
          control={
            <Checkbox
              color="primary"
              className={this.props.classes.checkbox}
              checked={this.state.clearFields.has('memo')}
              onChange={() => this.handleCheck('memo')}
              value={String(this.state.clearFields.has('memo'))}
            />
          }
          label="Clear memo fields"
        />}
        {/* CLR */}
        <Paper
          className={this.props.classes.clrSection}
          elevation={0}
          id="bulk-edit-clr-field"
        >
          <Typography variant="subtitle2" style={{ paddingRight: 12 }}>Status</Typography>
          <FormControlLabel
            classes={{ root: this.props.classes.formLabel, label: this.props.classes.checkboxLabel }}
            control={
              <ClrField
                value={this.state.state}
                onChange={(e) => this.handleChange(e, 'state')}
                includeEmpty
                editable
              >
              </ClrField>
            }
            label=""
          />
          {/* This is currently broken */}
          {/* {isAcme &&  */}
          {/*  <FormControlLabel */}
          {/*    classes={{ */}
          {/*      root: this.props.classes.formLabel, */}
          {/*      label: this.props.classes.checkboxLabel, */}
          {/*    }} */}
          {/*    style={{ marginLeft: 15 }} */}
          {/*    id="bulk-edit-flag-field" */}
          {/*    control={ */}
          {/*      <FlagField */}
          {/*        value={this.state.userFlag} */}
          {/*        onChange={(e) => this.handleChange(e, 'userFlag')} */}
          {/*        includeEmpty */}
          {/*        editable */}
          {/*      > */}
          {/*      </FlagField> */}
          {/*    } */}
          {/*    label="Transaction Flag" */}
          {/*  /> */}
          {/* } */}
        </Paper>

      </Paper>
    );

  /* eslint-disable arrow-body-style */
  renderBulkEditPanel = (classes) => {

    return (
      <Paper
        className={classes.backgroundStyle}
        onKeyDown={this.handleKeyDown}
        elevation={0}
      >
        <Paper elevation={0} className={classes.splitsContainer}>
          {this.renderBulkEdit(false)}
        </Paper>

        <Paper elevation={0} className={classes.buttonHolder}>
          <QButton
            onClick={this.closeAndExit}
            color="primary"
            id="bulk-edit-cancel"
            variant="outlined"
            style={{ marginRight: 12 }}
          >
            Cancel
          </QButton>
          <QButton
            onClick={this.saveFn}
            name="bulk-edit-last-tab"
            color="primary"
            id="bulk-edit-save"
            variant="contained"
          >
            Update
          </QButton>
        </Paper>
      </Paper>
    );
  };

  renderTitle = (title) => {
    const { classes } = this.props;
    if (title) {
      return (
        <Typography
          className={classes.title}
          variant="h6"
          id="dialog-title"
        >
          {title}
        </Typography>
      );
    }
    return null;
  };

  render() {

    const { classes, inDialog } = this.props;
    const { txForm } = this.state;
    const numTxs = this.props.txnArray.size;

    const title = `Edit Multiple Transactions (${numTxs} selected)`;

    if (inDialog) {
      return (
        <Dialog
          classes={
            {
              paperWidthSm: this.props.classes.paperWidthMd,
              scrollPaper: (isTablet || isMobile) ? this.props.classes.scrollPaperTablet : null,
            }
          }
          open
          show="true"
          maxWidth="sm"
          disableEscapeKeyDown
        >
          <div className={this.props.classes.headerTitle}>
            {this.renderTitle(title)}
            <IconButton
              className={this.props.classes.closeButtonTop}
              aria-label="Close"
              id="dlg-close"
              onClick={this.closeAndExit}
              size="large"
            >
              <CloseIcon />
            </IconButton>
          </div>
          {this.renderBulkEditPanel(classes, txForm)}

        </Dialog>
      );
    }
    return this.renderBulkEditPanel(classes, txForm);
  }
}

BulkEditPanel.propTypes = {
  closeFn: PropTypes.func,
  classes: PropTypes.object,
  saveFn: PropTypes.func,
  inDialog: PropTypes.bool,
  longCats: PropTypes.bool,
  txnArray: PropTypes.object,  // immutable Set()
};

export default withStyles(BulkEditPanel, styles);
