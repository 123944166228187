import { createSelector } from 'reselect';

import { authSelectors } from 'companion-app-components/flux/auth';
import { datasetsSelectors } from 'companion-app-components/flux/datasets';
import type RootState from 'companion-app-components/utils/redux-store/rootState';

const getDatasetId = (state: RootState) => authSelectors.getDatasetId(state);
const getDatasets = (state: RootState) => datasetsSelectors.getActiveDatasets(state);
const getWebFirstDataset = (state: RootState) => authSelectors.getIsWebfirstDataset(state);

export const getIsCurrentDatasetOwner = createSelector(
  getDatasetId, getDatasets, (datasetId, datasets) => {
    const currentDataset = datasets?.get(datasetId!);
    if (!currentDataset) return false;
    const ownDatasets = datasets?.filter((dataset) => !dataset.shared);
    return ownDatasets?.has(currentDataset.id!);
  },
);

export enum AllFeatures {
  ADD_ACCOUNTS = 'ADD_ACCOUNTS',
  ADD_BUDGET = 'ADD_BUDGET',
  FIX_ACCOUNT = 'FIX_ACCOUNT',
}

export const getIsFeatureAllowed = createSelector(
  getIsCurrentDatasetOwner, getWebFirstDataset, // eslint-disable-line function-call-argument-newline
  (_state, type: AllFeatures) => type, // eslint-disable-line function-call-argument-newline
  (isCurrentDatasetOwner, isWebFirstDataset, type) => {
    const allowedFeatures: AllFeatures[] = [];
    if (isCurrentDatasetOwner || !isWebFirstDataset) {
      allowedFeatures.push(...Object.values(AllFeatures));
    }
    return allowedFeatures.includes(type);
  },
);
