export const styles = ({ shape, palette, spacing }) => ({
  divider: {
    margin: `${shape.padding[1]}px 0px`,
  },
  item: {
    cursor: 'pointer',
    justifyContent: 'space-between',
    borderRadius: shape.borderRadius * 1,
    '&:hover': {
      backgroundColor: palette.grey.level1,
    },
  },
  flexRow: {
    display: 'flex',
  },
  popper: {
    zIndex: 1500, // just needs to be >1300 to display on top of Modal
    backgroundColor: palette.white,
    borderRadius: shape.borderRadius * 2,
    boxShadow: '0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 5px 5px -3px rgba(0, 0, 0, 0.2)',
    maxHeight: '95%',
  },
  primaryMenu: {
    maxHeight: '100%',
    overflowY: 'auto',
    padding: shape.padding[2],
  },
  root: {
  },
  rootPopper: {
    minWidth: 280,
    maxWidth: '50%',
  },
  secondaryMenu: {
    maxHeight: 'calc(95vh)',
    overflowY: 'auto',
    padding: shape.padding[2],
  },
  subheader: {
    color: `${palette.text.secondary}`,
    textAlign: 'left',
    padding: shape.padding[2],
  },
  startIcon: {
    minWidth: 30,
    height: 'auto',
    marginRight: spacing(1),
    color: palette.text.primary,
  },
  rightArrowStyles: {
    padding: 0,
    width: 20,
    height: 20,
  },
  rightArrowButton: {
    padding: 0,
    width: 30,
    height: 30,
  },
});
