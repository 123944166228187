import { clrFieldOptions } from 'components/TransactionRegister/transactionsConfig';

export const styles = (theme: Record<string, any>) => ({
  clrFieldIcon: {
    border: 'solid',
    borderWidth: '2px',
    borderColor: theme.palette.grey[400],
    borderRadius: theme.shape.borderRadius * 6,
    width: '24px',
    height: '24px',
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingLeft: 1,
    ...clrFieldOptions.styles(theme),
  },
  clrFieldText: {
    borderRadius: theme.shape.borderRadius * 6,
    padding: `2px ${theme.shape.padding[4]}px`,
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    ...clrFieldOptions.styles(theme),
  },
  iconFont: {
    fontSize: '13px',
    borderRadius: theme.spacing.borderRadius * 6,
    lineHeight: '21px',
  },
  iconText: {
    fontSize: '14px',
    borderRadius: '23px',
    lineHeight: '21px',
    background: 'unset',
    ...clrFieldOptions.styles(theme),
  },
});

