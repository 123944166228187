import React, { useRef, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import classNames from 'classnames';

import ButtonBase from '@mui/material/ButtonBase';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import QTypography from 'components/MUIWrappers/QTypography';
import { clrFieldOptions } from 'components/TransactionRegister/transactionsConfig';
import { styles } from './styles';

const useStyles = makeStyles()(styles as Record<string, any>);

interface ClrFieldProps {
  value: string;
  autoFocus: boolean;
  onChange?: (event: { target: { value: string } }) => void;
  alternateMenu?: { label: string; value: string }[];
  editable: boolean;
  includeEmpty: boolean;
}

const ClrField: React.FC<ClrFieldProps> = ({ value, autoFocus, onChange, editable, includeEmpty, ...other }) => {
  const { classes } = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const btnRef = useRef<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };

  const handleClose = (item?: { state: string } | null) => {
    setAnchorEl(null);
    if (onChange && item) {
      onChange({ target: { value: item.state } });
    }
    setTimeout(() => btnRef.current?.focus(), 100);
  };

  const onKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Escape' || event.key === 'Enter') {
      event.stopPropagation();
      setAnchorEl(null);
    }
  };

  const makeReconcileIcon = (val) => (
    <div className={classNames(classes.clrFieldIcon, val)}>
      <QTypography className={classes.iconFont} thinFont>
        {`${(val || ' ').toUpperCase().charAt(0)}`}
      </QTypography>
    </div>
  );

  const makeMenuItem = (item, hasIcon: boolean | undefined = true) => {
    if (item.menu) {
      const text = item.label;
      return (
        <MenuItem key={`clrmenu:${item.label}`} onClick={() => handleClose(item)}>
          {hasIcon && <ListItemIcon>{makeReconcileIcon(item.label)}</ListItemIcon>}

          {<ListItemText>{text}</ListItemText>}
        </MenuItem>
      );
    }
    return null;
  };

  const menuItems = clrFieldOptions.options.map((item) => makeMenuItem(item));

  if (includeEmpty) {
    menuItems.push(makeMenuItem({ label: ' No Change', value: ' ' }));
  }

  const currItemRef = clrFieldOptions.options.find((x) => x.state === value);
  const noChangeText = ' ';

  if (editable) {
    return (
      <div {...other}>
        <ButtonBase
          {...other}
          autoFocus={autoFocus}
          aria-owns={anchorEl ? 'clrField-menu' : undefined}
          aria-haspopup="true"
          focusRipple
          onClick={handleClick}
          ref={btnRef}
          action={(actions) => {
            if (autoFocus && actions) {
              actions.focusVisible();
            }
          }}
        >
          {makeReconcileIcon(currItemRef ? currItemRef.label : noChangeText)}
        </ButtonBase>
        {editable && (
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => handleClose(null)}
            onKeyDown={onKeyDown}
          >
            {menuItems}
          </Menu>
        )}
      </div>
    );
  }
  return makeReconcileIcon(currItemRef ? currItemRef.label : ' ');
};

export default ClrField;
