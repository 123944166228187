import React, { FC, useState, MouseEvent } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useDispatch } from 'react-redux';
import cn from 'classnames';

import Chip from '@mui/material/Chip';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Popover from '@mui/material/Popover';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

import { removeDialog } from 'companion-app-components/flux/root-ui/rootUiActions';
import { datasetSharesTypes, datasetSharesActions } from 'companion-app-components/flux/dataset-shares';
import { tracker } from 'companion-app-components/utils/core';

import { checkIfInviteExpired } from 'containers/ProfilePage/utils';
import { stringAvatar, membersOptions, capitalizeString } from './utils';
import { userListStyles } from './styles';
import { showConfirmationDialog, DIALOG_TYPE_CONFIRMATION_NEW } from '../actions';

interface UserListProps {
  ownerName?: string;
  ownerEmail?: string;
  share?: datasetSharesTypes.DatasetShare;
  datasetName?: string;
  manager?: boolean;
}

const useStyles = makeStyles()(userListStyles as Record<string, any>);

const UserList: FC<UserListProps> = ({ ownerName, ownerEmail, share, datasetName = '', manager = false }) => {

  const { granteeFirstName, granteeLastName, granteeEmail, lastInviteSent, state = '' } = share || {};

  const name = ownerName || `${granteeFirstName?.trim()} ${granteeLastName?.trim()}`;

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const dispatch = useDispatch();

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRemove = () => {
    dispatch(datasetSharesActions.deleteDatasetShare(share!));
  };

  const handleRevoke = () => {
    dispatch(removeDialog(DIALOG_TYPE_CONFIRMATION_NEW));
    handleRemove();
  };

  const handleResend = () => {
    const shareUpdated = share?.set('state', datasetSharesTypes.DATASET_SHARING_STATES.INVITED);
    dispatch(datasetSharesActions.updateDatasetShare(shareUpdated!));
  };

  const getInviteState = (shareState: datasetSharesTypes.DatasetShareState, date?: string) => {
    let label = 'Full Access';
    let color = 'koalaGrey';
    if (shareState === datasetSharesTypes.DATASET_SHARING_STATES.INVITED && checkIfInviteExpired(date)) {
      label = 'Invitation Expired';
      color = 'number.negative';
    } else if (shareState === datasetSharesTypes.DATASET_SHARING_STATES.INVITED) {
      label = shareState;
    } else if (shareState === datasetSharesTypes.DATASET_SHARING_STATES.ABANDONED) {
      label = 'No longer shared';
    }

    return { label, color };
  };

  const handleMember = (action: string, eventName: string) => {
    handleClose();
    if (action === 'resend') {
      handleResend();
      tracker.track(tracker.events.datasetSharing, { property: 'manageAccess', action: eventName });
    } else if (action === 'revoke') {
      dispatch(showConfirmationDialog({
        heading: 'Revoke Invitation',
        subHead: `Revoking ${name}’s invitation?`,
        description: `Please note, this member will no longer be able to accept your invitation to “${datasetName}”`,
        buttons: [
          {
            label: 'CANCEL',
            variant: 'outlined',
            action: () => dispatch(removeDialog(DIALOG_TYPE_CONFIRMATION_NEW)),
          }, 
          {
            label: 'REVOKE',
            variant: 'outlined',
            action: () => {
              handleRevoke();
              dispatch(removeDialog(DIALOG_TYPE_CONFIRMATION_NEW));
              tracker.track(tracker.events.datasetSharing, { property: 'manageAccess', action: eventName });
            },
          },
        ],
      }));
    } else if (action === 'remove') {
      dispatch(showConfirmationDialog({
        heading: 'Remove',
        subHead: `Remove ${name}?`,
        description: `Please note, this member will no longer be able to access to “${datasetName}”`,
        buttons: [
          {
            label: 'CANCEL',
            variant: 'outlined',
            action: () => dispatch(removeDialog(DIALOG_TYPE_CONFIRMATION_NEW)),
          }, 
          {
            label: 'REMOVE',
            variant: 'outlined',
            action: () => {
              handleRemove();
              dispatch(removeDialog(DIALOG_TYPE_CONFIRMATION_NEW));
              tracker.track(tracker.events.datasetSharing, { property: 'manageAccess', action: eventName });
            },
          },
        ],
      }));
    }
  };
 
  const { classes } = useStyles();

  return (
    <div className={classes.wrapper}>
      <Avatar 
        {...stringAvatar(name)} 
      />
      <div className={classes.labelsWrapper}>
        <Typography variant="subtitle1" className={cn(classes.nameWrapper, classes.textEllipsis)}>
          {name}
        </Typography>
        <Typography variant="body2" className={cn(classes.textTertiary, classes.textEllipsis)}>
          {ownerEmail || granteeEmail}
        </Typography>
      </div>
      <div className={classes.rightBlock}>
        {
          manager && 
          <Typography variant="body2" className={cn(classes.textTertiary, classes.managerBlock)}>
            Owner
          </Typography>
        }
        {
          !manager && 
          <>
            <Chip
              label={capitalizeString(getInviteState(state, lastInviteSent).label)}
              className={classes.chip}
              sx={{ color: getInviteState(state, lastInviteSent).color }}
            />
            <IconButton onClick={handleClick}>
              <MoreVertIcon fontSize="small" />
            </IconButton>
            <Popover
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <List>
                {membersOptions.filter((option) => option.types.includes(state)).map((option) => (
                  <ListItem disablePadding onClick={() => handleMember(option.action, option.eventName)}>
                    <ListItemButton>
                      <ListItemText primary={option.label} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Popover>
          </>
        }
      </div>
    </div>
  );
};

export default UserList;
